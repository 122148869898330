@import 'variables';

.card {
  background-color: #fff;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: 0 2px 7px rgba(20, 20, 43, 0.06);

  &.link-card {
    color: #656769;
    transform-style: preserve-3d;
    text-decoration: none;
    transition: transform .3s, color .3s;
    display: block;

    &:hover {
      color: #656769;
      transform: translate3d(0, -8px, 0.01px);
    }

    &.slider-blog-post-item,
    &.blog-post-item {
      min-height: 100%;
      background-color: $color-neutral-800;
    }

    &.integration-item {
      padding: 58px 40px;
      box-shadow: 0 15px 20px rgba(20, 20, 43, 0.1);

      &.dark {
        background-color: $color-neutral-800;
      }
    }

    &.card-blog-post-featured {
      background-color: $color-primary;
      align-items: center;
      display: flex;
    }

    &.blog-post-item-v2 {
      min-height: 100%;
      background-color: $color-neutral-800;
    }

    &.social-media-card-link {
      grid-column-gap: 18px;
      background-color: $color-neutral-800;
      border-radius: 14px;
      align-items: center;
      padding: 18px;
      display: flex;
    }

    &.help-center-article {
      padding: 64px 75px;
      box-shadow: 0 6px 8px rgba(20, 20, 43, 0.08);
    }

    &.search-result {
      min-height: 100%;
      padding: 50px 40px;
    }
  }

  &.sticky-top.top-24px {
    top: 24px;

    &.static-mbl {
      top: 120px;
    }
  }

  &.checkout-block {
    margin-bottom: 28px;
    padding: 32px 32px 48px;

    &.order-summary {
      padding-bottom: 40px;
    }

    &.last {
      margin-bottom: 0;
    }
  }

  &.feature-featured {
    align-items: center;
    background-color: $color-primary;
    box-shadow: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
  }
}

.card {
  &.feature-dark {
    box-shadow: none;
    background-color: $color-neutral-800;
    min-height: 720px;
  }

  &.feature-light {
    box-shadow: none;
    background-color: $color-neutral-200;
    min-height: 720px;
  }

  &.integration-strip {
    max-width: 280px;
    grid-column-gap: 25px;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    display: flex;
    box-shadow: 0 15px 20px rgba(20, 20, 43, 0.1);
  }

  &.testimonial-slider {
    background-color: $color-neutral-800;
    border-radius: 24px;
    padding: 50px;
  }

  &.testimonial-strip {
    max-width: 482px;
    background-color: $color-neutral-800;
    padding: 50px 40px 60px;
  }

  &.join-team {
    width: 100%;
    height: 100%;
    max-width: 100%;
    text-align: center;
    transform-style: preserve-3d;
    background-color: $color-primary;
    background-image: url('/images/bg-accent-grid-techkit-template.svg');
    background-position: 50%;
    background-size: auto;
    background-attachment: fixed;
    border-radius: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: -100%;
    padding: 20px;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: auto;
    right: 0%;
    overflow: auto;
    transform: translate3d(42px, 0, 0.01px);
  }

  &.press-slider {
    background-color: $color-neutral-800;
    border-radius: 24px;
    padding: 51px 51px 64px;
  }

  &.office {
    box-shadow: 0 15px 20px rgba(20, 20, 43, 0.1);
  }

  &.open-position {
    grid-column-gap: 32px;
    cursor: pointer;
    justify-content: space-between;
    padding: 51px 44px 57px 46px;
    transition: box-shadow .3s, transform .3s;
    display: flex;
    box-shadow: 0 6px 8px rgba(20, 20, 43, 0.08);

    &:hover {
      transform: translate(0, -6px);
      box-shadow: 0 10px 15px rgba(20, 20, 43, 0.1);
    }
  }

  &.post-author {
    text-align: left;
    border-radius: 14px;
    padding: 12px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 0 15px 20px rgba(20, 20, 43, 0.1);
  }

  &.card-open-position-sidebar {
    background-color: $color-primary;
    border-radius: 14px;
    padding: 70px 40px;
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
    box-shadow: 0 15px 20px rgba(20, 20, 43, 0.08);
  }

  &.section-card-contact {
    grid-column-gap: 40px;
    box-shadow: none;
    background-color: $color-neutral-200;
    border-radius: 24px;
    justify-content: space-between;
    padding: 127px 72px;
    display: flex;

    &.dark {
      background-color: $color-neutral-800;
    }
  }

  &.product {
    box-shadow: 0 15px 20px rgba(20, 20, 43, 0.1);
    flex-direction: column;
    justify-content: space-between;
    max-width: 420px;
    padding: 50px 35px;
    transition: box-shadow .3s, transform .3s;

    &:hover {
      // transform: translate(0, -6px);
      box-shadow: 0 20px 25px rgba(20, 20, 43, 0.24);
    }

    &.popular {
      background-color: $color-neutral-800;
    }
  }

  &.add-cart {
    min-height: 100%;
    background-color: $color-neutral-800;
    border-radius: 24px;
    padding: 48px 48px 52px;
    max-width: 560px;
  }

  &.contact-links-v1 {
    border-radius: 14px;
    padding: 100px 39px;
    box-shadow: 0 15px 20px rgba(20, 20, 43, 0.1);

    &.featured {
      color: $color-neutral-200;
      background-color: $color-primary;
    }
  }

  &.form-v1 {
    z-index: 1;
    min-height: 797px;
    box-shadow: none;
    background-color: $color-neutral-200;
    border-radius: 24px 0 0 24px;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    padding-top: 87px;
    padding-bottom: 87px;
    padding-left: 81px;
    display: flex;
    position: relative;
  }

  &.contact-links-v2 {
    border-radius: 24px;
    padding: 61px 57px 58px;
    box-shadow: 0 15px 20px rgba(20, 20, 43, 0.1);

    &.featured {
      color: $color-neutral-200;
      background-color: $color-primary;
    }
  }

  &.form-v2 {
    min-height: 797px;
    box-shadow: none;
    background-color: $color-neutral-200;
    border-radius: 0 24px 24px 0;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    padding-top: 87px;
    padding-bottom: 87px;
    padding-right: 81px;
    display: flex;
  }

  &.contact-links-v3 {
    border-radius: 14px;
    padding: 72px 39px;
    box-shadow: 0 15px 20px rgba(20, 20, 43, 0.1);

    &.featured {
      color: $color-neutral-200;
      background-color: $color-primary;
    }
  }

  &.form-v3 {
    min-height: 837px;
    box-shadow: none;
    background-color: $color-neutral-800;
    border-radius: 0 24px 24px 0;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 0;
    padding-top: 87px;
    padding-bottom: 127px;
    padding-right: 98px;
    display: flex;
  }

  &.log-in-form-block {
    background-color: $color-neutral-200;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    margin-bottom: 0;
    margin-bottom: 48px;
    padding: 60px;
  }

  &.forgot-password-form-block {
    min-height: 765px;
    background-color: $color-neutral-200;
    border-radius: 24px;
    flex-direction: column;
    margin-bottom: 0;
    padding: 89px 83px 108px;
    display: flex;
  }

  &.reset-password-form-block {
    min-height: 941px;
    background-color: $color-neutral-200;
    border-radius: 24px;
    flex-direction: column;
    margin-bottom: 0;
    padding: 89px 72px 100px;
    display: flex;
  }

  &.email-verification {
    background-color: $color-neutral-200;
    border-radius: 24px;
    flex-direction: column;
    margin-bottom: 0;
    padding: 89px 83px;
    display: flex;
  }

  &.help-center-category {
    min-height: 100%;
    border-radius: 14px;
    flex-direction: column;
    padding: 88px 39px;
    display: flex;
    box-shadow: 0 15px 20px rgba(20, 20, 43, 0.1);

    &.popular {
      background-color: $color-primary;
    }
  }

  &.protected-page {
    width: 100%;
    background-color: $color-neutral-200;
    border-radius: 24px;
    padding: 92px 128px;
  }

  &.post-author-page {
    text-align: center;
    border-radius: 14px;
    padding: 55px 55px 67px;
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
    box-shadow: 0 15px 20px rgba(20, 20, 43, 0.1);
  }

  &.dropdown {
    margin-top: 25px;
    padding: 58px 40px;
    box-shadow: 0 15px 20px rgba(20, 20, 43, 0.1);

    &.dark {
      background-color: $color-secondary-1;
      border: 2px solid #1f1f1f;
      box-shadow: 0 25px 30px rgba(0, 0, 0, 0.25);
    }
  }

  &.figma-included {
    align-items: center;
    margin-bottom: 40px;
    display: flex;
  }

  &.home-sales {
    text-decoration: none;
    transition: transform .3s, color .3s;

    &:hover {
      transform: scale(0.97);
    }
  }
}

.card-request-demo-form-block {
  height: 100%;
  min-height: 674px;
  background-color: $color-neutral-800;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  padding: 80px 64px;
  display: flex;
}

.card-help-center-category-content {
  flex-direction: column;
  flex: 1;
  display: flex;
}



.card-help-center-article-details {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.card-help-center-article-author {
  grid-column-gap: 17px;
  align-items: center;
  display: flex;
}

.card-help-center-article-author-content {
  grid-row-gap: 4px;
  flex-direction: column;
  display: flex;
}

.card-join-team-content {
  max-width: 257px;
  margin-left: auto;
  margin-right: auto;
}


.card-office-content {
  padding: 40px 40px 50px;
}

.card-office-details-wrapper {
  grid-column-gap: 12px;
  align-items: flex-start;
  display: flex;

  &.link-content-block:hover {
    color: $color-primary;
  }
}



.card-feature-featured-content {
  max-width: 562px;
  flex: 1;
  padding: 100px 59px;

  &.v2 {
    padding-top: 83px;
    padding-bottom: 83px;
  }
}

.card-feature-featured-images {
  width: 100%;
  max-width: 623px;
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  margin-bottom: -31px;
  padding-top: 54px;
  display: flex;
  position: relative;
}

.card-feature-featured---image-01 {
  margin-right: -100px;
  margin-bottom: 0;
}

.card-feature-dark-content {
  padding: 51px 55px 58px;
}

.card-feature-dark-images {
  justify-content: flex-start;
  align-items: flex-end;
  margin-bottom: -17px;
  display: flex;
  position: relative;
}

.card-feature-dark-image-above-wrapper {
  grid-row-gap: 25px;
  flex-direction: column;
  display: flex;
}

.card-home-sales-content {
  text-align: center;
  padding: 34px 20px;
}

.card-feature-light-content {
  padding: 51px 55px 58px;
}

.card-feature-light-images {
  align-items: flex-end;
  display: flex;
  justify-content: flex-start;
  margin-bottom: -20px;
  margin-left: 40px;
  position: relative;
}

.card-blog-post-category {
  grid-column-gap: 4px;
  align-items: center;
  display: flex;
}


.card-slider-blog-post-content {
  padding: 44px 54px 60px;
}


.card-integration-strip-title {
  color: $color-neutral-800;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.357em;
}

.card-testimonial-slider-details {
  grid-row-gap: 7px;
  flex-direction: column;
  display: flex;
}


.card-blog-post-content {
  padding: 26px 30px 33px;
}


.card-testimonial-strip-paragraph {
  color: #919497;
  margin-bottom: 0;
  font-size: 22px;
  line-height: 1.682em;
}

.card-testimonial-strip-name {
  color: #fff;
  margin-bottom: 7px;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.136em;
}

.card-testimonial-strip-rol {
  color: $color-primary;
  font-size: 20px;
  line-height: 1.1em;
}

.card-integration-slide-content-top {
  grid-column-gap: 26px;
  align-items: center;
  display: flex;
}

.card-testimonial-strip-top {
  grid-column-gap: 12px;
  align-items: center;
  margin-bottom: 30px;
  display: flex;
}

.card-office-details-content,
.card-open-position-content {
  flex: 1;
}

.card-open-position-content-bottom {
  overflow: hidden;
}

.card-open-position-wrapper {
  width: 100%;
  grid-column-gap: 54px;
  justify-content: space-between;
  display: flex;
}

.card-open-position-details-main {
  grid-column-gap: 16px;
  flex-wrap: wrap;
  align-items: center;
  display: flex;
}

.card-open-position-details {
  grid-column-gap: 7px;
  align-items: center;
  display: flex;
}

.team-item-card-content {
  max-width: 276px;
  margin-left: auto;
  margin-right: auto;
}

.card-newsletter-grid {
  z-index: 1;
  min-height: 100%;
  background-color: $color-primary;
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  justify-content: flex-end;
  padding-top: 144px;
  padding-bottom: 144px;
  padding-left: 38px;
  display: flex;
  position: relative;
}

.card-blog-post-featured-content {
  max-width: 51%;
  padding: 60px 37px 60px 40px;
}

.card-blog-post-content-v2 {
  padding: 38px 40px 50px;
}

.card-blog-post-v2-title {
  color: #fff;
  margin-bottom: 0;
  font-size: 33px;
  line-height: 1.273em;
}


.card-product-title-wrapper {
  grid-column-gap: 20px;
  grid-row-gap: 5px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 44px;
  }
}

.card-product-price-display {
  grid-column-gap: 3px;
  flex-wrap: wrap;
  align-items: flex-end;
  display: flex;
}

.section-card-contact-main-content {
  max-width: 760px;
  grid-column-gap: 24px;
  flex: 1;
  display: flex;
}

.card-product-item {
  min-height: 100%;

  .card.product {
    min-height: 890px;
  }

  &:nth-child(2n) {
    .card.product {
      min-height: 890px;
    }
  }

  &:last-child {
    .card.product {
      min-height: 890px;
    }
  }
}

.pd---content-inside-card {
  padding: 40px 28px 48px;

  &.large {
    padding: 64px 56px;

    &.template-pages {
      padding-top: 50px;
    }
  }

  &.template-pages---sidebar {
    padding: 32px 16px 24px;
  }
}

.feature-card-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;


  .card {
    display: flex;
    flex-direction: column;
    height: 70%;
    border-radius: 18px;
    overflow: hidden;

    &.feature-dark {
      box-shadow: none;
      background-color: $color-neutral-800;
      min-height: 600px;
    }

    &.feature-light {
      box-shadow: none;
      background-color: $color-neutral-200;
      min-height: 600px;
    }
  }

  .card-feature-dark-content,
  .card-feature-light-content {
    padding: 40px 40px 0;
    flex-grow: 0.7;
    display: flex;
    flex-direction: column;
  }

  .card-feature-dark-images,
  .card-feature-light-images {
    position: relative;
    height: 300px;
    width: 100%;
    margin-top: auto;
    padding-top: 0;
  }

  .image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .card-feature-light-images .image-wrapper {
    width: 86%;
    margin: 0 auto;
  }

  .image {
    object-fit: contain;
    object-position: bottom;
  }
}