.image.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  flex: 1;

  &.video-image {
    filter: blur(1.5px);
  }

  &.icon-white {
    filter: brightness(0%) invert(#ffffff); // Provide the color to invert
  }
}

.image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -1px;
  overflow: hidden;
  transform: translate(0);

  &.order-item-image-wrapper {
    max-width: 100px;
  }

  &.border-radius-24px.team-item {
    align-items: center;
    position: relative;
  }

  &.image-above {
    box-shadow: 0 15px 20px rgba(20, 20, 43, 0.08);
  }

  &.border-radius-18px.hero-v2-image-01 {
    margin-right: -512px;
  }

  &.shadow {
    box-shadow: 0 5px 6px rgba(20, 20, 43, 0.06);
  }

  &.video-thumbnail {
    align-items: center;
    border-radius: 0;
    border: #ddd solid 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 1000px;
  }

  &.borders {
    border: 1px solid #d4e1e9;
  }

  &.circle-image {
    border-radius: 50%;

    &.card-open-position-category-icon {
      background-color: #eff4f8;
      padding: 22px;
      display: block;
    }
  }

  &.story-image {
    margin-left: -110px;
  }

  &.offices-map {
    margin-right: -699px;
  }

  &.card-blog-post-featured-image {
    max-width: 49%;
    align-self: stretch;
  }

  &.white-icon {
    filter: brightness(0%) invert(#ffffff); // Provide the color to invert
  }

  &.integration-page {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    margin-right: -8vw;
    box-shadow: 0 25px 20px rgba(20, 20, 43, 0.1);
  }
}

.image-home-link-wrapper {
  max-width: 400px;
  transform-style: preserve-3d;
  border-radius: 8px;
  flex: none;
  margin-left: 12px;
  margin-right: 12px;
  transition: transform .3s, color .3s;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

  &:hover {
    transform: scale3d(0.98, 0.98, 1.01);
  }
}

.image-home-link {
  width: 100%;
  object-fit: cover;
}