// Import variables
@import 'variables';

// Background colors
.bg-accent-1 {
  background-color: $accent-1;
}

.bg-secondary-1 {
  background-color: $secondary-1;
}

.bg-neutral-100 {
  background-color: $neutral-100;
}

.bg-neutral-200 {
  background-color: $neutral-200;
}

.bg-neutral-300 {
  background-color: $neutral-300;
}

.bg-neutral-400 {
  background-color: $neutral-400;
}

.bg-neutral-500 {
  background-color: $neutral-500;
}

.bg-neutral-600 {
  background-color: $neutral-600;
}

.bg-neutral-700 {
  background-color: $neutral-700;
}

.bg-neutral-800 {
  background-color: $neutral-800;
}

.bg-blue-100 {
  background-color: $blue-100;
}

.bg-blue-200 {
  background-color: $blue-200;
}

.bg-blue-300 {
  background-color: $blue-300;
}

.bg-blue-400 {
  background-color: $blue-400;
}

.bg-green-100 {
  background-color: $green-100;
}

.bg-green-200 {
  background-color: $green-200;
}

.bg-green-300 {
  background-color: $green-300;
}

.bg-green-400 {
  background-color: $green-400;
}

.bg-orange-100 {
  background-color: $orange-100;
}

.bg-orange-200 {
  background-color: $orange-200;
}

.bg-orange-300 {
  background-color: $orange-300;
}

.bg-orange-400 {
  background-color: $orange-400;
}

.bg-red-100 {
  background-color: $red-100;
}

.bg-red-200 {
  background-color: $red-200;
}

.bg-red-300 {
  background-color: $red-300;
}

.bg-red-400 {
  background-color: $red-400;
}

// Text colors
.color-accent-1 {
  color: $accent-1;
}

.color-secondary-1 {
  color: $secondary-1;
}

.color-neutral-100 {
  color: $neutral-100;
}

.color-neutral-200 {
  color: $neutral-200;
}

.color-neutral-300 {
  color: $neutral-300;
}

.color-neutral-400 {
  color: $neutral-400;
}

.color-neutral-500 {
  color: $neutral-500;
}

.color-neutral-600 {
  color: $neutral-600;
}

.color-neutral-700 {
  color: $neutral-700;
}

.color-neutral-800 {
  color: $neutral-800;
}

.color-blue-100 {
  color: $blue-100;
}

.color-blue-200 {
  color: $blue-200;
}

.color-blue-300 {
  color: $blue-300;
}

.color-blue-400 {
  color: $blue-400;
}

.color-green-100 {
  color: $green-100;
}

.color-green-200 {
  color: $green-200;
}

.color-green-300 {
  color: $green-300;
}

.color-green-400 {
  color: $green-400;
}

.color-orange-100 {
  color: $orange-100;
}

.color-orange-200 {
  color: $orange-200;
}

.color-orange-300 {
  color: $orange-300;
}

.color-orange-400 {
  color: $orange-400;
}

.color-red-100 {
  color: $red-100;
}

.color-red-200 {
  color: $red-200;
}

.color-red-300 {
  color: $red-300;
}

.color-red-400 {
  color: $red-400;
}

// Example color block text
.style---color-block-text-example {
  background-color: $neutral-100;
  border-radius: 40px;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 14px;
  position: absolute;
  right: 16px;
  top: 16px;

  &.badge-dark {
    background-color: $neutral-800;
  }
}