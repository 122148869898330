@import 'variables';

// Carousel Container
.carousel-container {
  overflow: hidden;
  width: 100%;
  position: relative;

  // Sample Slider Pagination Bullet
  .sample-slider .swiper-pagination-bullet {
    height: 20px;
    width: 20px;
  }

  // Swiper Bullet
  .swiper-bullet {
    max-height: 16px !important;
    max-width: 16px !important;
    padding: 0 !important;
  }

  .swiper-pagination {
    position: static;
    margin-top: 20px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #000;
  }

  .swiper-pagination-bullet {
    background: #000;
    opacity: 0.5;
  }

  .swiper-pagination-bullet-active {
    opacity: 1;
  }

  .swiper-free-mode > .swiper-wrapper {
    transition-timing-function: linear !important;
  }

  .swiper-wrapper {
    transition-timing-function: linear !important;
  }

  &:hover .swiper-wrapper {
    animation-play-state: paused !important;
  }

  /* .swiper-wrapper {
    animation: slide-left 10s linear infinite;
    margin-right: 0;
  }

  @keyframes slide-left {
    from {
      transform: translate3d(0, 0, 0);
    }

    to {
      transform: translate3d(-100%, 0, 0);
    }
  } */

  /* .swiper-wrapper {
    animation: slide-left 10s linear infinite;
    margin-right: 0;
  }
  &:hover {
    .swiper-wrapper {
      animation-play-state: paused;
    }
  } */


  .logos {
    height: 120px;
    width: 120px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      cursor: pointer;
      filter: grayscale(100%);
      opacity: 0.5;
      border-radius: 50%;
      object-fit: cover;
      transition: filter 0.2s ease-in-out, transform 0.2s ease-in-out, opacity 0.2s ease-in-out;

      &:hover {
        filter: none;
        opacity: 1;
        transform: scale(1.1);
      }
    }

    .icon {
      position: absolute;
      bottom: 5px;
      right: 0;
      width: 30px;
      height: 30px;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover .icon {
      opacity: 1;
    }
  }
}

// Media query for mobile devices
@media (max-width: 768px) {
  .carousel-container {
    .swiper-slide {
      width: auto !important;
    }

    .logos {
      height: 80px;
      width: 80px;

      img {
        height: 60px;
        width: 60px;
      }

      .icon {
        width: 20px;
        height: 20px;
      }
    }
  }
}