@import 'variables';

.style---navigation-sidebar {
  box-shadow: 1px 0 14px rgba(20, 20, 43, 0.06);
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
}

.style---main-wrapper {
  background-color: $neutral-200;
}

.style---color-block {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  min-height: 180px;
  position: relative;
}

.style---color-block-content {
  padding: 24px 24px 32px;
}

.style---color-block-name {
  color: $neutral-800;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 8px;
}

.style---color-block-hex {
  line-height: 20px;
}

.style---block-sub-heading {
  margin-bottom: 32px;

  &.border {
    border-bottom: 1px solid $neutral-300;
    margin-bottom: 64px;
    padding-bottom: 40px;
  }
}

.style---typography-block-grid {
  grid-column-gap: 40px;
  grid-template-columns: minmax(auto, 320px) 1fr;
  grid-template-rows: auto;
}

.style---content-block {
  margin-bottom: 100px;
}

.style---style-grid-wrapper {
  grid-row-gap: 180px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}

.style---shadow-card {
  align-items: center;
  background-color: $neutral-100;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 220px;
  padding: 32px 44px;
  text-align: center;
}

.style---heading {
  align-items: center;
  background-color: $neutral-100;
  box-shadow: 0 2px 6px rgba(20, 20, 43, 0.08);
  display: flex;
  margin-bottom: 30px;
  min-height: 70vh;
  padding: 72px 4vw;
}

.style---content-heading {
  align-items: center;
  background-color: $neutral-100;
  box-shadow: 0 2px 6px rgba(20, 20, 43, 0.08);
  display: flex;
  margin-bottom: 60px;
  padding: 48px;
  border-radius: 18px;
}

.style---heading-icon-wrapper {
  background-color: $accent-1;
  border-radius: 10000px;
  margin-right: 18px;
}

.style---content-wrapper {
  padding-left: 4vw;
  padding-right: 4vw;
  padding-top: 40px;
}

.style---card-wrapper {
  background-color: $neutral-100;
  border: 1px solid $neutral-300;
  border-radius: 18px;
  box-shadow: 0 2px 7px rgba(20, 20, 43, 0.06);
}

.style---components-wrapper {
  background-color: $neutral-100;
  border-radius: 18px;
}

.style---bg-white {
  padding: 48px;
}

.style-buttons-dark {
  background-color: $secondary-1;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 48px;
}

.style---icons-grid {
  grid-column-gap: 24px;
  grid-template-columns: repeat(auto-fit, 72px);
  grid-template-rows: auto;
}

.style---social-square-icons-grid {
  grid-column-gap: 24px;
  grid-template-columns: repeat(auto-fit, 36px);
  grid-template-rows: auto;
}

.style---line-icons-grid {
  grid-column-gap: 24px;
  grid-template-columns: repeat(auto-fit, 20px);
  grid-template-rows: auto;
}

.style---dropdown-wrapper-sidebar {
  overflow: hidden;
  width: 100%;
}

.style---dropdown-toggle {
  align-items: center;
  border-bottom: 1px solid $neutral-300;
  display: flex;
  font-size: 16px;
  font-weight: 700;
  justify-content: space-between;
  line-height: 18px;
  padding: 18px 20px;
  transition: color 0.3s;
  width: 100%;

  &:hover,
  &.w--open {
    color: $accent-1;
  }
}

.style---dropdown-list-sidebar {
  background-color: $neutral-100;
  border-bottom: 1px solid $neutral-300;
  padding-bottom: 24px;
  padding-top: 24px;
  position: relative;
}

.style---dropdown-link-sidebar {
  align-items: center;
  border-right: 4px solid rgba(0, 0, 0, 0);
  color: $neutral-600;
  display: flex;
  font-size: 16px;
  line-height: 19px;
  padding: 12px 16px 12px 20px;
  text-decoration: none;
  transition: border-color 0.3s, background-color 0.3s, color 0.3s;

  &:hover {
    background-color: $neutral-200;
    border-right-color: $accent-1;
    color: $accent-1;
  }

  &.w--current {
    background-color: $neutral-200;
    border-right-color: $accent-1;
    color: $accent-1;
    font-weight: 700;
  }
}

.style---dropdown-link-icon-sidebar {
  background-color: $accent-1;
  border-radius: 8px;
  margin-right: 10px;
  max-width: 38px;
  padding: 2px;
}

.style---sidebar-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: scroll;
}

.style---logo-wrapper {
  background-color: $neutral-200;
  border-bottom: 1px solid $neutral-300;
  color: $neutral-600;
  padding: 38px 40px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}

.style---button-wrapper-sidebar {
  margin-bottom: 24px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.style---logo-sidebar {
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.6;
  }
}