// Accordion Item Wrapper
.accordion-item-wrapper {
  background-color: $neutral-800;
  border-radius: 18px;
  box-shadow: 0 2px 12px rgba(20, 20, 43, 0.08);
  cursor: pointer;
  display: flex;
  grid-column-gap: 40px;
  justify-content: space-between;
  padding: 42px;
  transform-style: preserve-3d;
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%;

  &:hover {
    transform: translate3d(0, -6px, 0.01px);
    box-shadow: 0 4px 16px rgba(20, 20, 43, 0.15);
  }

  &.light {
    background-color: $neutral-200;
    box-shadow: none;
  }
}

// Accordion Header
.accordion-header {
  align-items: center;
  display: flex;
}

// Accordion Body
.acordion-body {
  overflow: hidden;
  width: 100%;
}

// Accordion Spacer
.accordion-spacer {
  min-height: 32px;

  &._46px {
    min-height: 46px;
  }
}

// Accordion Content Wrapper
.accordion-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.accordion-item-wrapper {
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  .accordion-content-wrapper {
    .acordion-body {
      overflow: hidden;
      transition: height 0.3s ease, opacity 0.3s ease;
    }
  }

  .accordion-btn {
    transition: transform 0.3s ease;

    .accordion-item-line-icon {
      transition: background-color 0.3s ease;
    }
  }

  &.open {
    .accordion-content-wrapper {
      .acordion-body {
        display: block;
        opacity: 1;
        height: auto;
      }
    }

    .accordion-btn {
      transform: rotate(90deg);

      .accordion-item-line-icon {
        background-color: rgb(255, 255, 255);
      }
    }
  }
}
