@import '/styles/variables';

.header-logo {
  width: 85%;
}

.header-logo-link {
  max-width: 250px;
  transform-style: preserve-3d;
  padding-left: 0;
  transition: transform 0.3s, color 0.3s;

  &:hover {
    transform: scale3d(0.98, 0.98, 1.01);
  }
}

// Header Right Side
.header-right-side {
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

// Header Middle
.header-middle {
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
}

// Header Small Wrapper
.header-small-wrapper {
  background-color: rgba(0, 0, 0, 0);
}

.header-small-content-wrapper {
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  display: flex;
  position: relative;
}

.header-small-logo-link {
  width: 100%;
  max-width: 240px;
  transform-style: preserve-3d;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  transition: transform 0.3s, color 0.3s;

  &:hover {
    transform: scale3d(0.98, 0.98, 1.01);
  }
}

.floating-dashboard-btn {
  align-items: center;
  background-color: $green-300 !important;
  border-radius: 0 0 8px 8px !important;
  bottom: 44%;
  display: flex;
  height: 32px;
  left: 0;
  padding: 12px 16px !important;
  position: fixed;
  transform-origin: right center;
  transform: translateY(-30%) translateX(-90%) rotate(-90deg);
  transition: all 0.3s ease;
  white-space: nowrap;
  z-index: 1000;

  &:hover {
    background-color: $green-400 !important;
    transform: translateY(-30%) translateX(-88%) rotate(-90deg);
  }
}

@media (max-width: 991px) {
  .floating-dashboard-btn {
    padding: 8px 12px !important;
    font-size: 12px !important;
    transform: translateY(-50%) translateX(-64%) rotate(-90deg);

    &:hover {
      transform: translateY(-50%) translateX(-44%) rotate(-90deg);
    }
  }
}

@media (max-width: 768px) {
  .floating-dashboard-btn {
    display: none;
  }
}

.user-menu-trigger {
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.ant-dropdown-menu {
  min-width: 200px;
}

.ant-dropdown-menu-item {
  padding: 8px 16px !important;

  a {
    color: inherit;
    text-decoration: none;
  }
}