@import 'variables';

.cart-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  transition: all 1s ease-in-out;
}

.cart-container {
  background-color: $color-neutral-800;
  border-radius: 0;
  float: right;
  height: 100vh;
  padding: 40px 10px;
  position: relative;
  width: 600px;
}

.cart-heading {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 500;
  gap: 8px;
  margin-left: 8px;

  .heading {
    margin-left: 10px;
  }
}

.cart-num-items {
  color: $red-400;
  margin-left: 10px;
}

.empty-cart {
  margin: 40px;
  text-align: center;

  h3 {
    font-size: 20px;
    font-weight: 600;
  }
}

.cart-button-wrapper {
  margin-left: 16px;
}

.cart-quantity {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  color: $accent-1;
  font-size: 18px;
  font-weight: 400;
  height: auto;
  line-height: 1.111em;
  margin-left: 0;
  min-width: auto;
  padding-left: 0;
  padding-right: 0;
}

.cart-header {
  border-bottom-color: $neutral-200;
  padding-bottom: 22px;
  padding-top: 22px;
}

.cart-list {
  padding-bottom: 24px;
  padding-top: 24px;
}

.cart-footer {
  padding-bottom: 40px;
  padding-top: 40px;
}

.cart-item-title {
  color: $neutral-800;
  font-weight: 700;
  margin-bottom: 10px;
  text-decoration: none;

  &:hover {
    color: $accent-1;
  }
}

.cart-item-price {
  color: $neutral-800;
  font-weight: 500;
  line-height: 1em;
}

.cart-item-wrapper {
  padding-bottom: 23px;
  padding-top: 23px;
}

.cart-subtotal-number {
  color: $neutral-800;
  font-size: 20px;
}

.pay-btn {
  border-radius: 8px;

  &.cart {
    height: 60px;
  }

  &.order-summary {
    height: 50px;
  }
}

.cart-line-item {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 20px;
  margin-bottom: 24px;
}

.cart-remove-link {
  line-height: 1em;
}

.cart-close-button {
  align-items: center;
  color: $neutral-800;
  display: flex;
  font-size: 24px;
  height: 24px;
  justify-content: center;
  text-decoration: none;
  width: 24px;

  &:hover {
    color: $accent-1;
  }
}

.checkout-form {
  background-color: rgba(0, 0, 0, 0);
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin-left: auto;
  margin-right: auto;
  min-height: auto;
  padding: 0;
  width: 100%;

  .two-fields {
    display: flex;
    grid-gap: 24px;

    >div {
      grid-area: span 1 / span 1 / span 1 / span 1;
    }
  }
}

.checkout-col-left {
  margin-right: 0;
}

.checkout-col-right {
  position: static;
}

.checkout-block-header {
  background-color: rgba(0, 0, 0, 0);
  border-bottom-color: $neutral-300;
  border-width: 0 0 1px;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 20px;
  padding: 0 0 15px;
}

.checkout-block-content {
  background-color: rgba(0, 0, 0, 0);
  border: 0 solid #000;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
}

.add-cart-price {
  color: $neutral-100;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.316em;
}

.add-cart-year {
  font-size: 28px;
  font-weight: 500;
  line-height: 1.2;
}

.add-cart-price-wrapper {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 3px;
}

.form-arrow-inside-btn {
  background-color: rgba(0, 0, 0, 0);
  padding: 24px;
  width: 100%;
}

.add-cart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 280px;
}

.add-cart-default {
  margin-bottom: 0;
}

.cart-icon {
  background-color: transparent;
  border: none;
  color: gray;
  cursor: pointer;
  font-size: 25px;
  position: relative;
  transition: transform 0.4s ease;

  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.cart-item-qty {
  background-color: $red-400;
  border-radius: 50%;
  color: #eee;
  font-size: 12px;
  font-weight: 600;
  height: 18px;
  position: absolute;
  right: -8px;
  text-align: center;
  width: 18px;
}

.cart-bottom {
  bottom: 12px;
  padding: 30px 65px;
  position: absolute;
  right: 5px;
  width: 100%;
}

.cart-option-item {
  padding-left: 0;
}

.cart-item-content {
  margin-left: 0;
}

.cart-item-medium {
  color: $neutral-800;
  font-weight: 500;
}

.shipping-list {
  border: 0 solid #000;
}

.shipping-method {
  border: 0 solid #000;
  border-bottom: 1px solid $neutral-300;
  padding: 31px 0;
}

.shipping-price {
  color: $neutral-800;
  font-weight: 700;
}

.order-item-list {
  margin-bottom: 0;
}

.order-item {
  border-bottom: 1px solid $neutral-300;
  margin-bottom: 18px;
  margin-top: 18px;
  padding-bottom: 18px;
  padding-top: 18px;
}

.order-list-price {
  color: $neutral-800;
  font-weight: 700;
}

.order-list-title {
  color: $neutral-800;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  text-decoration: none;

  &:hover {
    color: $accent-1;
  }
}

.order-option-item {
  padding-left: 0;
}

.order-item-medium {
  color: $neutral-800;
  font-weight: 500;
}

.order-summary-price {
  color: $neutral-800;
}

.discounts-wrapper {
  border-top-color: $neutral-300;
  border-width: 1px 0 0;
  margin-top: 32px;
  padding: 32px 0 0;
}

.order-item-content {
  margin-left: 0;
}

.text-underline {
  text-decoration: underline;
}

.z-index-1 {
  position: relative;
  z-index: 1;
  top: -1px;
}

.text-no-wrap {
  white-space: nowrap;
}

.pricing-tablet {
  display: grid;
  grid-column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;

  &.mobile {
    display: none;
  }
}

.checkout-button {
  display: inline-block;
  max-width: 180px;
  width: 100%;
}

.cart-item {
  display: flex;
  align-items: flex-start;
  padding: 16px;
}

.cart-item-image {
  flex-shrink: 0;
  margin-right: 16px;
}

.cart-item-details {
  flex-grow: 1;
}

.cart-item-details h3 {
  margin: 0 0 8px 0;
  font-size: 16px;
  color: #fff;
}

.cart-item-details p {
  margin: 4px 0;
  font-size: 14px;
  color: #aaa;
}

.product {
  display: flex;
  gap: 16px;
  padding: 20px;
}

.cart-product-image {
  flex-shrink: 0;
  object-fit: contain;
}

.item-desc {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top {
  margin-bottom: 10px;
}

.bottom {
  margin-top: auto;
}