// Media queries for responsive design
@media (max-width: 1200px) {
  .container {
    max-width: 100%;
    padding: 0 10px;
  }

  .section.blue-callout {
    .svg-logo-container {
      display: none;
    }
  }
}

@media (max-width: 992px) {
  .container {
    padding: 0 5px;
  }

  .row {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .header-small-logo-link {
    max-width: 180px;
  }
}

@media (max-width: 576px) {
  .header-small-logo-link {
    max-width: 150px;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 32px;
  }

  h3 {
    font-size: 20px;
  }

  h4 {
    font-size: 18px;
  }

  blockquote {
    padding: 48px 24px;
  }

  .grid-2-columns {
    &.changelog-item {
      grid-row-gap: 16px;
      grid-template-columns: 1fr;
    }

    &.pricing-tablet-mb {
      grid-column-gap: 16px;
      grid-row-gap: 15px;
      text-align: center;
      grid-template-columns: auto;
      justify-content: center;
      justify-items: center;
    }

    &.section-features-grid {
      grid-row-gap: 40px;
    }

    &.blog-featured-main-grid {
      grid-row-gap: 60px;
    }

    &.about-position-grid,
    &.request-demo-grid,
    &.request-demo-grid-v2 {
      grid-row-gap: 80px;
    }

    &.sign-up-grid {
      grid-row-gap: 60px;
    }

    &.team-member-grid {
      grid-row-gap: 70px;
    }
  }

  .card {
    &.link-card {
      &.integration-item {
        padding-left: 24px;
        padding-right: 24px;
      }

      &.social-media-card-link {
        justify-content: center;
      }

      &.help-center-article {
        padding-left: 24px;
        padding-right: 24px;
      }

      &.search-result {
        padding: 40px 24px;
      }
    }

    &.integration-strip {
      padding: 15px;
    }

    &.testimonial-slider {
      border-radius: 18px;
      padding: 43px 24px;
    }

    &.testimonial-strip {
      text-align: center;
      padding: 36px 24px 43px;
    }

    &.press-slider {
      border-radius: 18px;
      padding-left: 24px;
      padding-right: 24px;
    }

    &.open-position {
      grid-row-gap: 25px;
      flex-direction: column;
      padding-top: 36px;
      padding-left: 24px;
      padding-right: 24px;
    }

    &.post-author {
      padding: 42px 24px 48px;
    }

    &.card-open-position-sidebar {
      flex-direction: column;
      margin-top: -180px;
      padding: 50px 24px;
    }

    &.section-card-contact {
      border-radius: 18px;
      padding: 70px 24px;
    }

    &.product {
      padding-left: 24px;
      padding-right: 24px;
    }

    &.add-cart {
      padding: 50px 24px 42px;
    }

    &.contact-links-v1 {
      flex-direction: column;
      padding: 60px 24px;
    }

    &.form-v1 {
      min-height: 926px;
      padding: 50px 24px;
    }

    &.contact-links-v2 {
      flex-direction: column;
      padding: 51px 24px 48px;
    }

    &.form-v2 {
      min-height: 926px;
      padding: 50px 24px;
    }

    &.contact-links-v3 {
      flex-direction: column;
      padding-left: 24px;
      padding-right: 24px;
    }

    &.form-v3 {
      padding-top: 50px;
      padding-left: 24px;
      padding-right: 24px;
    }

    &.log-in-form-block {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      padding: 50px 24px;
    }

    &.forgot-password-form-block {
      min-height: 647px;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      padding: 51px 24px 63px;
    }

    &.reset-password-form-block {
      min-height: 828px;
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      padding: 51px 24px 58px;
    }

    &.email-verification {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      padding: 51px 24px;
    }

    &.help-center-category {
      padding: 51px 24px;
    }

    &.protected-page {
      border-top-left-radius: 18px;
      border-top-right-radius: 18px;
      padding: 50px 24px;
    }

    &.post-author-page {
      padding: 24px 24px 48px;
    }
  }

  .grid-4-columns {
    grid-template-columns: 1fr;

    &.values-grid {
      grid-row-gap: 54px;
      grid-template-columns: auto;
    }
  }

  .divider {
    margin-top: 60px;
    margin-bottom: 60px;

    &._64px {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    &._48px {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .mg-bottom-16px {
    margin-bottom: 10px;

    &.keep {
      margin-bottom: 16px;
    }
  }

  .mg-bottom-24px {
    margin-bottom: 16px;

    &.keep {
      margin-bottom: 24px;
    }
  }

  .mg-bottom-32px {
    margin-bottom: 20px;

    &.keep {
      margin-bottom: 32px;
    }
  }

  .mg-bottom-40px.responsive {
    margin-bottom: 25px;
  }

  .mg-bottom-64px {
    margin-bottom: 40px;
  }

  .mg-top-24px {
    margin-top: 16px;

    &.keep {
      margin-top: 24px;
    }
  }

  .mg-top-48px {
    margin-top: 32px;
  }

  .mg-top-64px {
    margin-top: 44px;
  }

  .display-1 {
    font-size: 35px;
  }

  .gap-row-32px {
    grid-row-gap: 20px;
  }

  .display-2 {
    font-size: 28px;
  }

  .display-3 {
    font-size: 20px;
  }

  .display-4 {
    font-size: 18px;
  }

  .grid-3-columns {
    grid-template-columns: 1fr;

    &.gap-row-80px._2-col-mbl {
      grid-template-columns: 1fr;
    }

    &.logo-strip-v4 {
      grid-column-gap: 15px;
      grid-row-gap: 25px;
    }
  }

  .style---heading {
    margin-bottom: 40px;
    padding-top: 66px;
    padding-bottom: 66px;
  }

  .inner-container {
    &.card-integration-strip-image {
      max-width: 50px;
      min-width: 50px;
    }

    &._100---mbp {
      max-width: 100%;
    }

    &._300px---mbp {
      max-width: 300px;
    }

    &.card-open-position-icon-category {
      margin-top: 0;
    }
  }

  .style---content-heading {
    padding-left: 22px;
    padding-right: 22px;
  }

  .style---heading-icon-wrapper {
    width: 58px;
    border-radius: 16px;
    margin-right: 14px;
  }

  .border-radius-24px {
    border-radius: 10px;
  }

  .border-radius-40px {
    border-radius: 20px;
  }

  .btn-primary {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    &.small,
    &.large {
      padding-left: 20px;
      padding-right: 20px;
    }

    &.inside-input {
      position: static;

      &.default-with-arrow {
        padding-right: 20px;

        &.dark-mbp {
          background-color: $color-secondary-1;
        }
      }

      &.default {
        padding: 22px 20px;
      }
    }
  }

  .btn-secondary {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;

    &.small,
    &.large {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .badge-primary,
  .badge-secondary {
    padding: 14px 22px;

    &.small {
      padding-left: 20px;
      padding-right: 20px;
    }

    &.large {
      padding: 20px 32px;
    }
  }

  .input {
    padding-left: 20px;
    padding-right: 20px;

    &::-ms-input-placeholder,
    &::placeholder {
      font-size: 16px;
      line-height: 16px;
    }

    &.large.button-inside {
      min-height: 64px;
    }

    &.button-inside {
      margin-bottom: 20px;
      padding-right: 20px;
    }
  }

  .position-relative {
    &.bg-grid-white {
      &.section-features {
        padding-top: 44px;
        padding-bottom: 44px;
      }

      &.small.integrations-hero {
        padding: 40px 0;
      }
    }

    &.hero-v2-images {
      padding-left: 24px;
      padding-right: 24px;
    }

    &.sign-up {
      padding-left: 20px;
    }
  }

  .text-area {
    padding-left: 20px;
    padding-right: 20px;
  }

  .checkbox-field-wrapper {
    font-size: 16px;
    line-height: 20px;

    &.large {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .checkbox {
    min-height: 22px;
    min-width: 22px;

    &.large {
      min-height: 26px;
      min-width: 26px;
    }
  }

  .radio-button-field-wrapper {
    font-size: 16px;
    line-height: 22px;

    &.large {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .radio-button {
    min-height: 22px;
    min-width: 22px;

    &.large {
      min-height: 26px;
      min-width: 26px;
    }
  }

  .social-icon-square {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
    border-radius: 6px;
    font-size: 14px;
  }

  .line-rounded-icon.success-message-check.large {
    font-size: 58px;
    line-height: 64px;
  }

  .rich-text {

    h2,
    h3 {
      margin-bottom: 15px;
    }

    figure {
      margin-top: 50px;
      margin-bottom: 45px;
    }

    ul,
    ol {
      padding-left: 20px;
    }

    h6,
    h5,
    h4 {
      margin-bottom: 15px;
    }
  }

  .buttons-row {
    width: 100%;
    flex-direction: column;
  }

  .container-default {
    padding-left: 20px;
    padding-right: 20px;
  }

  .section {
    padding-top: 116px;
    padding-bottom: 116px;

    &.hero {

      &.v1,
      &.v2 {
        background-size: 60px;
        padding-top: 150px;
      }

      &.v3 {
        padding-top: 150px;
      }

      &.v4 {
        padding-top: 150px;
        padding-bottom: 148px;
      }

      &.v5 {
        padding-top: 150px;
      }

      &.v6 {
        padding-top: 150px;
        padding-bottom: 100px;
      }

      &.v7 {
        padding-top: 150px;
        padding-bottom: 120px;
      }

      &.v8 {
        background-size: 60px;
        padding-top: 150px;
        padding-bottom: 150px;
      }

      &.v9 {
        background-size: 60px;
        padding-top: 150px;
        padding-bottom: 82px;
      }

      &.v10 {
        padding-top: 150px;
      }

      &.v11 {
        padding-top: 150px;
        padding-bottom: 60px;
      }

      &.v12 {
        padding-top: 150px;
        padding-bottom: 82px;
      }

      &.v13 {
        padding-top: 150px;
        padding-bottom: 138px;
      }

      &.v14 {
        padding-top: 70px;
      }

      &.v15 {
        padding-top: 150px;
        padding-bottom: 144px;
      }

      &.team-page {
        padding-top: 150px;
        padding-bottom: 82px;
      }

      &.template-pages {
        padding-top: 150px;
        padding-bottom: 116px;
      }
    }

    &.pd-188px {
      padding-top: 90px;
      padding-bottom: 90px;
    }

    &.pd-0px {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.pd-top-0px {
      padding-top: 0;
    }

    &.pd-bottom-53px {
      padding-bottom: 53px;
    }

    &.cta-v3 {
      padding-top: 70px;
      padding-bottom: 70px;
    }

    &.pd-290px {
      padding-top: 140px;
      padding-bottom: 140px;

      &.pd-top-0px {
        padding-top: 0;
      }
    }

    &.pd-120px {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &.pd-162px {
      padding-top: 78px;
      padding-bottom: 78px;
    }

    &.pd-bottom-0px {
      padding-bottom: 0;
    }

    &.pd-140px {
      padding-top: 68px;
      padding-bottom: 68px;

      &.pd-top-0px {
        padding-top: 0;
      }
    }

    &.pd-top-180px {
      padding-top: 88px;
    }

    &.pd-top-140px {
      padding-top: 68px;
    }

    &.pd-top-323px {
      padding-top: 156px;
    }

    &.pd-153px {
      padding-top: 74px;
      padding-bottom: 74px;
    }

    &.utility-page {
      margin-top: -70px;
      padding-top: 144px;
    }

    &.sign-up {
      padding-bottom: 65px;
    }

    &.pd-130px {
      padding-top: 63px;
      padding-bottom: 63px;

      &.pd-top-0px {
        padding-top: 0;
      }
    }

    &.not-found {
      padding-top: 150px;
      padding-bottom: 104px;
    }

    &.protected-page {
      padding-top: 150px;
    }

    &.coming-soon {
      padding-top: 150px;
      padding-bottom: 123px;
    }
  }

  .heading-h1-size {
    font-size: 32px;
  }

  .heading-h3-size {
    font-size: 20px;
  }

  .heading-h4-size {
    font-size: 18px;
  }

  .header-nav-link {
    font-size: 30px;

    &.login {
      grid-column-gap: 6px;
    }
  }

  .header-middle {
    margin-right: 16px;
  }

  .btn-square-secondary.small.accordion-btn {
    width: 44px;
    height: 44px;
    min-height: 44px;
    min-width: 44px;
  }

  .social-media-grid-top {
    grid-template-columns: repeat(auto-fit, 30px);

    &.center {
      grid-template-columns: repeat(auto-fit, 30px);
    }
  }

  .flex-horizontal.product-page-title {
    grid-column-gap: 10px;
  }

  .slider-wrapper.bottom-buttons.offices-slider {
    max-width: 87vw;
  }

  .slider-mask.width-100---mbp {
    max-width: 100%;
  }

  .accordion-item-wrapper {
    grid-column-gap: 15px;
    padding: 40px 24px;
  }

  .accordion-spacer._46px {
    min-height: 20px;
  }

  .pd---content-inside-card {
    &.large {
      padding: 40px 24px;

      &.template-pages {
        padding-left: 24px;
        padding-right: 24px;
      }
    }

    &.template-pages---sidebar {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }

  .pricing-content-wrapper {
    max-height: none;
    padding-top: 26px;
    padding-bottom: 26px;
  }

  .image-wrapper {
    &.video-thumbnail {
      border-radius: 20px;
    }

    &.integration-page {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }

  ._404-not-found {
    margin-bottom: -43px;
  }

  .cart-container {
    border-radius: 0;
  }

  .pay-btn.cart {
    height: 53px;
  }

  .order-item {
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .order-list-price {
    margin-top: 11px;
  }

  .order-list-title {
    margin-bottom: 4px;
  }

  .order-item-content {
    margin-left: 0;
  }

  .checkout-column {
    margin-bottom: 24px;
  }

  .position-absolute {
    &.hero-v1-image-above---01 {
      margin-top: 10%;
      margin-bottom: 0%;
      top: 0%;
      bottom: auto;
      left: 0%;
      right: auto;
    }

    &.hero-v1-image-above---02 {
      margin-top: 10%;
      margin-bottom: 0%;
      top: 0%;
      bottom: auto;
      left: auto;
      right: 0%;
    }

    &.card-feature-featured-image-above {
      margin-left: 24px;
    }

    &.card-bottom-bg---top {
      min-height: 65%;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &.section-card-top-bg {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }

    &.hero-v3-bg {
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &.cta-v5-grid-wrapper {
      width: 80%;
    }

    &.card-newsletter-grid-bg {
      margin-left: -20px;
    }

    &.card-top-bg---bottom {
      min-height: 65%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;

      &.hero-v12 {
        min-height: 18%;
      }
    }

    &.sign-up-image-above {
      margin-bottom: 23.5%;
    }
  }

  .mg-bottom-93px {
    margin-bottom: 54px;
  }

  .logo-strips-wrapper {
    grid-column-gap: 30px;
    grid-row-gap: 25px;
  }

  .cta-v1-wrapper {
    padding-top: 80px;
    padding-bottom: 80px;
    padding-right: 24px;
  }

  .form-button-inside-block {
    min-height: 136px;
  }

  .cta-v2-wrapper {
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 24px;
  }

  .bg-grid-white {
    background-size: 60px;
  }

  .card-feature-featured-content {
    padding-top: 56px;
    padding-left: 24px;
    padding-right: 24px;

    &.v2 {
      padding-top: 50px;
      padding-bottom: 50px;
    }
  }

  .card-feature-dark-content {
    padding-left: 24px;
    padding-right: 24px;
  }

  .card-feature-dark-image-above-wrapper {
    grid-row-gap: 10px;
  }

  .card-feature-light-content {
    padding-left: 24px;
    padding-right: 24px;
  }

  .integrations-top {
    padding-bottom: 40px;
  }

  .integrations-bottom {
    padding-top: 40px;
  }

  .section-integration-02 {
    margin-bottom: 60px;
  }

  .section-integration-03 {
    margin-bottom: 80px;
  }

  .section-integration-08 {
    margin-top: 60px;
  }

  .section-integration-09 {
    margin-top: 80px;
  }

  .nav-content {
    flex-wrap: wrap;
  }

  .menu-main-wrapper {
    flex-flow: column;
  }

  .card-slider-blog-post-content {
    padding-left: 24px;
    padding-right: 24px;
  }

  .details-wrapper {
    grid-column-gap: 10px;
    grid-row-gap: 8px;
    flex-wrap: wrap;
  }

  .hero-v2-grid {
    grid-row-gap: 60px;
  }

  .border-radius-40px---top {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  ._2-grid-wrapper {
    grid-row-gap: 120px;
  }

  .mg-bottom-28px {
    margin-bottom: 18px;

    &.keep {
      margin-bottom: 28px;
    }
  }

  .link-content-block {
    &.blog-post-featured {
      grid-row-gap: 25px;
    }

    &.contact-link {
      flex-wrap: wrap;
    }
  }

  .mg-bottom-38px {
    margin-bottom: 25px;
  }

  .mg-top-0px---tablet {
    margin-top: 0;
  }

  .gap-row-70px---mbl {
    grid-row-gap: 60px;
  }

  .section-features-main-wrapper {
    grid-row-gap: 120px;
  }

  .email-btn-form-inside-btn {
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 20px;
  }

  .card-blog-post-content {
    padding-left: 24px;
    padding-right: 24px;
  }

  .card-testimonial-strip-top {
    grid-row-gap: 15px;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  .hero-v4-images-above-wrapper---01 {
    grid-row-gap: 23px;
  }

  .hero-v4-images-above-wrapper---02 {
    grid-row-gap: 30px;
  }

  .card-office-content {
    padding: 33px 24px 42px;
  }

  .card-office-details-wrapper {
    grid-row-gap: 10px;
    flex-wrap: wrap;
  }

  .card-open-position-wrapper {
    grid-column-gap: 15px;
  }

  .mg-bottom-0px---tablet {
    margin-bottom: 0;
  }

  .mg-bottom-100px {
    margin-bottom: 60px;
  }

  .card-newsletter-grid {
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
    padding-top: 83px;
    padding-bottom: 83px;
  }

  .card-blog-post-featured-content {
    padding: 43px 24px 50px;
  }

  .category-sidebar-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .category-sidebar-item,
  .categories-sidebar {
    flex-direction: column;
  }

  .card-blog-post-content-v2 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .card-blog-post-v2-title {
    font-size: 23px;
  }

  .mg-top--88px {
    margin-top: -51px;
  }

  .mg-bottom-78px {
    margin-bottom: 50px;
  }

  .border-radius-40px---bottom {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .form-arrow-inside-btn {
    white-space: break-spaces;
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 20px;
  }

  .add-cart {
    min-height: 246px;

    .ant-select {
      max-width: 460px;
    }
  }

  .contact-links-wrapper {
    grid-row-gap: 50px;
  }

  .section-card-social-media-wrapper {
    grid-row-gap: 30px;
    flex-direction: column;
  }

  .footer-small {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .header-small-content-wrapper {
    padding-top: 30px;
  }

  .sidebar-logo-strips-wrapper {
    grid-row-gap: 30px;
  }

  .card-request-demo-form-block {
    min-height: 990px;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    padding: 50px 24px;
  }

  .sign-up-form-block {
    min-height: 621px;
  }

  .checkbox-white {
    min-height: 22px;
    min-width: 22px;
  }

  .sign-up-image-wrapper {
    padding: 50px 24px;
  }

  .mg-top---118px {
    margin-top: -68px;
  }

  .category-title-wrapper {
    grid-column-gap: 10px;
  }

  .cart-item-content {
    margin-left: 0;
  }

  .home-pages-top-wrapper {
    margin-bottom: 16px;
  }

  .image-home-link-wrapper {
    max-width: 200px;
    margin-left: 8px;
    margin-right: 8px;
  }
}

@media screen and (min-width: 1440px) {
  .grid-2-columns {
    &.style---styleguide-wrapper {
      grid-template-columns: .3fr 1fr;
    }

    &.form {
      grid-column-gap: 32px;
      grid-row-gap: 32px;
    }
  }

  .style---typography-block-grid {
    grid-template-columns: minmax(auto, 380px) 0.8fr;
  }

  .grid-3-columns.logo-strip-v4 {
    grid-column-gap: 64px;
  }

  .style---dropdown-toggle {
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 20px;
    line-height: 20px;
  }

  .style---dropdown-link-sidebar {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 20px;
  }

  .style---dropdown-link-icon-sidebar {
    max-width: 46px;
    border-radius: 12px;
    margin-right: 14px;
  }

  .style---logo-wrapper {
    justify-content: center;
    display: flex;
  }

  .style---logo-sidebar {
    max-width: 260px;
  }

  .section {
    padding-top: 180px;
    padding-bottom: 180px;

    &.pd-100px {
      padding-top: 100px;
      padding-bottom: 100px;
    }

    &.pd-188px {
      padding-top: 188px;
      padding-bottom: 188px;
    }

    &.pd-0px {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.pd-top-0px {
      padding-top: 0;
    }

    &.pd-bottom-53px {
      padding-bottom: 53px;
    }

    &.cta-v3 {
      padding-top: 138px;
      padding-bottom: 138px;
    }

    &.pd-290px {
      padding-top: 290px;
      padding-bottom: 290px;

      &.pd-top-0px {
        padding-top: 0;
      }
    }

    &.pd-120px {
      padding-top: 120px;
      padding-bottom: 120px;
    }

    &.pd-162px {
      padding-top: 162px;
      padding-bottom: 162px;
    }

    &.pd-bottom-0px {
      padding-bottom: 0;
    }

    &.pd-140px {
      padding-top: 140px;
      padding-bottom: 140px;

      &.pd-top-0px {
        padding-top: 0;
      }
    }

    &.pd-top-180px {
      padding-top: 180px;
    }

    &.pd-top-140px {
      padding-top: 140px;
    }

    &.pd-top-323px {
      padding-top: 323px;
    }

    &.pd-153px {
      padding-top: 153px;
      padding-bottom: 153px;
    }

    &.pd-130px {
      padding-top: 130px;
      padding-bottom: 130px;

      &.pd-top-0px {
        padding-top: 0;
      }
    }
  }

  .grid-footer-2-column---logo-and-form {
    grid-column-gap: 250px;
    grid-template-columns: 1.2fr 2fr;
  }

  .image-wrapper.integration-page {
    border-radius: 40px;
    margin-right: -10vw;
  }
}

@media screen and (min-width: 1920px) {
  .style---heading {
    padding-left: 6vw;
    padding-right: 6vw;
  }

  .style---content-wrapper {
    padding-left: 8vw;
    padding-right: 8vw;
  }

  .section {
    &.pd-0px {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.pd-top-0px {
      padding-top: 0;
    }

    &.pd-bottom-53px {
      padding-bottom: 53px;
    }

    &.pd-290px.pd-top-0px {
      padding-top: 0;
    }

    &.pd-bottom-0px {
      padding-bottom: 0;
    }

    &.pd-140px.pd-top-0px,
    &.pd-130px.pd-top-0px {
      padding-top: 0;
    }
  }

  .image-wrapper.integration-page {
    max-height: 1428px;
  }

  .checkout-col-right {
    position: -webkit-sticky;
    position: sticky;
    top: 24px;
  }

  .image-home-link-wrapper {
    max-width: 560px;
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 45px;
  }

  h2 {
    margin-bottom: 12px;
    font-size: 32px;
  }

  blockquote {
    font-size: 22px;
  }

  .grid-2-columns {
    &.style---styleguide-wrapper {
      grid-row-gap: 0px;
      grid-template-columns: 1fr;
    }

    &._1-col-tablet {
      grid-template-columns: 1fr;
    }

    &.contact-v1-grid {
      grid-row-gap: 70px;
      grid-template-columns: 1fr;
      justify-items: center;
    }

    &.logo-strip-grid {
      grid-row-gap: 40px;
      grid-template-columns: 1fr;
      justify-items: center;
    }

    &.title-and-paragraph {
      grid-column-gap: 50px;
      grid-row-gap: 20px;
      text-align: center;
      grid-template-columns: 1fr;
      justify-content: center;
      justify-items: center;
    }

    &.text-left-default {
      grid-row-gap: 70px;
      grid-template-columns: 1fr;
    }

    &.text-right-default {
      grid-row-gap: 70px;
      grid-template-columns: 1fr;

      &.v2 {
        grid-row-gap: 70px;
        grid-template-columns: 1fr;
      }
    }

    &.gap-40px {
      grid-template-columns: 1fr;
    }

    &.template-page-sidebar {
      grid-template-columns: .5fr 1fr;
    }

    &.product-page {
      grid-row-gap: 60px;
      grid-template-columns: 1fr;
    }

    &.checkout-page {
      grid-template-columns: 1fr;
    }

    &.section-features-grid {
      grid-row-gap: 60px;
      grid-template-columns: 1fr;
    }

    &.title-and-buttons._1-col-tablet {
      grid-row-gap: 30px;
      grid-template-columns: 1fr;
    }

    &.blog-featured-main-grid {
      grid-row-gap: 80px;
      grid-template-columns: 1fr;
    }

    &.cta-v5 {
      grid-row-gap: 40px;
      grid-template-columns: 1fr;
    }

    &.offices-grid {
      grid-row-gap: 60px;
      grid-template-columns: 1fr;
      justify-items: center;
    }

    &.hero-v5-grid,
    &.blog-sidebar-grid {
      grid-row-gap: 70px;
      grid-template-columns: 1fr;
    }

    &.post-body-grid {
      grid-row-gap: 60px;
      grid-template-columns: 1fr;
    }

    &.about-position-grid {
      grid-row-gap: 100px;
      grid-template-columns: 1fr;
    }

    &.accordions-grid {
      grid-row-gap: 32px;
      grid-template-columns: 1fr;
    }

    &.integrations-grid {
      grid-row-gap: 70px;
      grid-template-columns: 1fr;
    }

    &.integrations-hero-grid {
      grid-template-columns: 1fr;
    }

    &.contact-v2-grid {
      grid-row-gap: 70px;
      grid-template-columns: 1fr;
      justify-items: center;
    }

    &.card-contact-links-v2-grid {
      grid-template-columns: 1fr;
    }

    &.contact-v3-grid {
      grid-row-gap: 70px;
      grid-template-columns: 1fr;
      justify-items: center;
    }

    &.request-demo-grid,
    &.request-demo-grid-v2 {
      grid-row-gap: 100px;
      grid-template-columns: 1fr;
    }

    &.sign-up-grid {
      grid-row-gap: 80px;
      grid-template-columns: 1fr;
      justify-items: center;
    }

    &.help-center-post-body-grid {
      grid-row-gap: 60px;
      grid-template-columns: 1fr;
    }

    &.team-member-grid {
      grid-row-gap: 100px;
      grid-template-columns: 1fr;
    }
  }

  .style---navigation-sidebar {
    max-height: 100%;
    min-height: auto;
    box-shadow: none;
    position: static;
  }

  .card {
    &.link-card {
      &.card-blog-post-featured {
        flex-direction: column;
      }

      &.help-center-article {
        padding: 53px 63px;
      }
    }

    &.checkout-block {
      padding-left: 24px;
      padding-right: 24px;
    }

    &.feature-featured {
      flex-direction: column;
      align-items: stretch;
    }

    &.integration-strip {
      grid-column-gap: 20px;
    }

    &.join-team {
      border-radius: 20px;
    }

    &.post-author {
      position: static;
    }

    &.card-open-position-sidebar {
      grid-column-gap: 40px;
      justify-content: center;
      margin-top: -240px;
      display: flex;
      position: static;
    }

    &.section-card-contact {
      grid-row-gap: 40px;
      flex-direction: column;
      padding: 100px 60px;
    }

    &.product {
      max-width: 700px;
      margin-left: auto;
      margin-right: auto;
    }

    &.add-cart {
      padding-left: 64px;
      padding-right: 64px;
    }

    &.contact-links-v1 {
      grid-column-gap: 40px;
      padding: 80px 60px;
      display: flex;
    }

    &.form-v1 {
      min-height: 769px;
      padding: 73px 60px;
    }

    &.contact-links-v2 {
      grid-column-gap: 40px;
      display: flex;
    }

    &.form-v2 {
      min-height: 759px;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      padding: 68px 60px;
    }

    &.contact-links-v3 {
      grid-column-gap: 40px;
      padding-top: 60px;
      padding-bottom: 60px;
      display: flex;
    }

    &.form-v3 {
      min-height: 823px;
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
      padding-top: 73px;
      padding-left: 60px;
      padding-right: 60px;
    }

    &.log-in-form-block {
      padding: 70px;
    }

    &.forgot-password-form-block {
      min-height: 732px;
      padding: 74px 70px 90px;
    }

    &.reset-password-form-block {
      min-height: 909px;
      padding: 74px 60px 83px;
    }

    &.email-verification {
      padding: 74px 70px;
    }

    &.help-center-category {
      padding-top: 73px;
      padding-bottom: 73px;
    }

    &.protected-page {
      padding: 74px 100px;
    }

    &.post-author-page {
      position: static;
    }

    &.dropdown {
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0);
      border-radius: 0;
      padding: 20px 0 48px;

      &.dark {
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0);
        border-width: 0;
      }
    }

    &.figma-included {
      flex-direction: column;
    }
  }

  .grid-4-columns {
    grid-template-columns: 1fr 1fr;

    &.features {
      grid-row-gap: 40px;
    }

    &.values-grid {
      grid-template-columns: auto auto;
    }
  }

  .style---block-sub-heading {
    margin-bottom: 24px;
  }

  .divider {
    margin-top: 70px;
    margin-bottom: 70px;

    &._64px {
      margin-top: 48px;
      margin-bottom: 48px;
    }

    &.card-product {
      align-self: stretch;
    }

    &.contact-links-v2-divider {
      width: 1px;
      height: auto;
      margin: 0 40px;
    }
  }

  .style---typography-block-grid {
    grid-template-columns: minmax(auto, 250px) 1fr;
  }

  .mg-bottom-56px {
    margin-bottom: 48px;
  }

  .mg-bottom-80px {
    margin-bottom: 70px;
  }

  .mg-top-80px {
    margin-top: 70px;
  }

  .display-1 {
    font-size: 60px;
  }

  .gap-row-56px {
    grid-row-gap: 40px;
  }

  .gap-row-80px {
    grid-row-gap: 56px;
  }

  .style---style-grid-wrapper {
    grid-row-gap: 140px;
  }

  .paragraph-large,
  .text-400 {
    font-size: 22px;
  }

  .grid-3-columns {
    grid-template-columns: 1fr 1fr;

    &.gap-row-80px {
      grid-row-gap: 64px;
    }

    &.style---buttons-grid.app-btn-grid {
      grid-template-columns: auto auto;
    }

    &._1-col-tablet {
      grid-template-columns: 1fr;
    }

    &.logo-strip-v4 {
      grid-row-gap: 40px;
      grid-template-columns: auto auto auto;
    }

    &._3-col-tablet {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.section-blog,
    &.blog-v3,
    &.gap-24px._1-col-tablet {
      grid-template-columns: 1fr;
    }

    &.mg-bottom-28px._2-col-t {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .style---heading {
    min-height: auto;
    margin-bottom: 60px;
    padding-top: 116px;
    padding-bottom: 116px;
  }

  .inner-container {
    &._60---tablet {
      max-width: 60%;
    }

    &._100---tablet {
      max-width: 100%;
    }

    &._700px---tablet {
      max-width: 700px;
    }

    &._600px---tablet {
      max-width: 600px;
    }

    &._500px---tablet {
      max-width: 500px;
    }

    &._71---tablet {
      max-width: 71%;
    }

    &._550px---tablet {
      max-width: 550px;
    }

    &.center---tablet {
      margin-left: auto;
      margin-right: auto;
    }

    &._70---tablet {
      max-width: 70%;
    }

    &.blog-categories-sidebar {
      max-width: 100%;
      text-align: center;
      position: static;
    }

    &._90---tablet {
      max-width: 90%;
    }

    &.card-open-position-sidebar-wrapper {
      height: 100%;
      max-width: 100%;
    }

    &._610px.integrations-hero {
      width: auto;
      max-width: 100%;
      margin-right: 0;
    }
  }

  .style---content-heading {
    margin-bottom: 60px;
    padding: 32px;
  }

  .border-radius-24px {
    border-radius: 20px;
  }

  .btn-primary {
    &.small {
      &.header-btn-hidde-on-tablet {
        display: none;
      }

      &.pricing-table-btn {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    &.large {
      padding-left: 52px;
      padding-right: 52px;
    }
  }

  .btn-secondary.large {
    padding-left: 52px;
    padding-right: 52px;
  }

  .position-relative {
    &.bg-grid-white {
      &.section-features {
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 53px;
        padding-bottom: 53px;
      }

      &.small.integrations-hero {
        padding: 60px 40px;
      }
    }

    &.hero-v2-images {
      padding-bottom: 0;
      padding-left: 53px;
      padding-right: 53px;
    }

    &.sign-up {
      padding-left: 70px;
    }
  }

  .avatar-circle {
    &._06 {
      width: 140px;
      height: 140px;
      max-height: 140px;
      max-width: 140px;
    }

    &._07 {
      width: 160px;
      height: 160px;
      max-height: 160px;
      max-width: 160px;
    }

    &._08 {
      width: 280px;
      height: 280px;
      max-height: 280px;
      max-width: 280px;
    }
  }

  .style---dropdown-wrapper-sidebar {
    display: none;
  }

  .buttons-row.center---tablet {
    justify-content: center;
  }

  .style---sidebar-wrapper {
    overflow: hidden;
  }

  .style---logo-wrapper {
    margin-bottom: 0;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .style---button-wrapper-sidebar {
    display: none;
  }

  .section {
    padding-top: 167px;
    padding-bottom: 167px;

    &.hero {
      &.v1 {
        background-size: 80px;
        padding-top: 200px;
      }

      &.v2 {
        background-size: 80px;
        padding-top: 190px;
        padding-bottom: 91px;
      }

      &.v3 {
        padding-top: 182px;
      }

      &.v4 {
        padding-top: 175px;
        padding-bottom: 213px;
      }

      &.v5 {
        padding-top: 183px;
      }

      &.v6,
      &.v7 {
        padding-top: 183px;
        padding-bottom: 150px;
      }

      &.v8 {
        background-size: 80px;
        padding-top: 180px;
        padding-bottom: 180px;
      }

      &.v9 {
        background-size: 80px;
        padding-top: 183px;
        padding-bottom: 118px;
      }

      &.v10 {
        padding-top: 180px;
      }

      &.v11 {
        padding-bottom: 78px;
      }

      &.v12 {
        padding-top: 180px;
        padding-bottom: 118px;
      }

      &.v13 {
        padding-top: 183px;
        padding-bottom: 200px;
      }

      &.v14 {
        padding-top: 100px;
      }

      &.v15 {
        padding-top: 186px;
        padding-bottom: 208px;
      }

      &.team-page {
        padding-top: 180px;
        padding-bottom: 118px;
      }

      &.template-pages {
        padding-top: 200px;
        padding-bottom: 168px;
      }
    }

    &.pd-100px {
      padding-top: 70px;
      padding-bottom: 70px;
    }

    &.pd-188px {
      padding-top: 130px;
      padding-bottom: 130px;
    }

    &.pd-0px {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.pd-top-0px {
      padding-top: 0;
    }

    &.pd-bottom-53px {
      padding-bottom: 53px;
    }

    &.cta-v3 {
      padding-top: 96px;
      padding-bottom: 96px;
    }

    &.pd-290px {
      padding-top: 200px;
      padding-bottom: 200px;

      &.pd-top-0px {
        padding-top: 0;
      }
    }

    &.pd-120px {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    &.pd-162px {
      padding-top: 113px;
      padding-bottom: 113px;
    }

    &.pd-bottom-0px {
      padding-bottom: 0;
    }

    &.pd-140px {
      padding-top: 98px;
      padding-bottom: 98px;

      &.pd-top-0px {
        padding-top: 0;
      }
    }

    &.pd-top-180px {
      padding-top: 125px;
    }

    &.pd-top-140px {
      padding-top: 98px;
    }

    &.pd-top-323px {
      padding-top: 224px;
    }

    &.pd-153px {
      padding-top: 106px;
      padding-bottom: 106px;
    }

    &.utility-page {
      margin-top: -95px;
      padding-top: 207px;
    }

    &.sign-up {
      padding-bottom: 94px;
    }

    &.pd-130px {
      padding-top: 90px;
      padding-bottom: 90px;

      &.pd-top-0px {
        padding-top: 0;
      }
    }

    &.not-found {
      padding-top: 219px;
      padding-bottom: 162px;
    }

    &.protected-page {
      padding-top: 230px;
    }

    &.coming-soon {
      padding-top: 186px;
      padding-bottom: 192px;
    }
  }

  .heading-h1-size {
    font-size: 45px;
  }

  .heading-h2-size {
    font-size: 32px;
  }

  .header-nav-menu-wrapper {
    background-color: #fff;
    bottom: 0%;
    height: 100vh;
    left: 0%;
    max-height: 100vh;
    min-height: 100vh;
    overflow: auto;
    padding: 128px 24px 24px;
    position: fixed;
    right: 0%;
    top: 0%;

    &.dark {
      background-color: $color-secondary-1;
    }
  }

  .header-nav-menu-list {
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .header-nav-link {
    font-size: 35px;

    &.cart-btn {
      font-size: 18px;
    }

    &.login {
      grid-column-gap: 8px;

      &.hidden-on-tablet {
        display: none;
      }
    }
  }

  .header-nav-list-item {
    margin-bottom: 25px;
    padding-left: 0;
    padding-right: 0;

    &.show-in-tablet {
      display: block;

      &.header-nav-list-btn-mobile {
        width: 100%;
      }

      &.header-nav-list-login-mobile {
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
      }
    }

    &.middle {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .dropdown-column-wrapper {
    width: 100%;
    transform: none;

    &.w--open {
      position: static;
    }
  }

  .header-logo-link {
    z-index: 1;
  }

  .hamburger-menu-wrapper {
    width: 56px;
    height: 56px;
    min-height: 56px;
    min-width: 56px;
    background-color: $color-primary;
    border-radius: 8px;
    padding: 19px;

    &.w--open {
      background-color: $color-primary;
    }
  }

  .hamburger-menu-bar {
    width: 23px;
    height: 3px;
    background-color: #fff;
    border-radius: 10000000px;
    padding: 0;
  }

  .header-right-side {
    z-index: 1;
    position: relative;
  }

  .header-middle {
    margin-left: auto;
    margin-right: 24px;
  }

  .btn-square-primary.pagination-btn,
  .btn-square-secondary.white.pagination-btn {
    margin-top: 70px;
  }

  .social-media-grid-top {
    &.footer-social-icon-links {
      justify-content: center;
    }

    &.center---tablet {
      justify-content: center;
      justify-items: center;
    }
  }

  .flex-horizontal.product-page-title {
    grid-column-gap: 15px;
  }

  .hidden-on-tablet {
    display: none;
  }

  .footer-top,
  .footer-middle {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .grid-footer-2-column---logo-and-form {
    grid-row-gap: 40px;
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .error-message.password {
    margin-left: 32px;
    margin-right: 32px;
  }

  .grid-footer-paragrah---social-media {
    grid-row-gap: 14px;
    text-align: center;
    grid-template-columns: 1fr;
    justify-content: center;
    justify-items: center;
  }

  .slider-mask {
    position: static;
  }

  .accordion-spacer {
    min-height: 26px;

    &._46px {
      min-height: 40px;
    }
  }

  .accordion-content-wrapper {
    max-width: 520px;
  }

  .image-wrapper {
    &.border-radius-18px.hero-v2-image-01 {
      margin-right: 0;
    }

    &.story-image {
      margin-left: 0;
    }

    &.offices-map {
      margin-right: 0;
    }

    &.card-blog-post-featured-image {
      max-width: 100%;
    }

    &.integration-page {
      margin-right: -24px;
    }
  }

  .template-pages---phography-wrapper {
    border-radius: 10px;
  }

  .cart-button-wrapper {
    z-index: 2;
  }

  .checkout-form {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  .position-absolute {
    &.hero-v1-image-above---01 {
      margin-bottom: 15%;
    }

    &.hero-v1-image-above---02 {
      margin-bottom: 20%;
    }

    &.section-features-image-above {
      max-width: 53%;
      margin-bottom: 10%;
      margin-right: 0%;
    }

    &.card-feature-featured-image-above {
      margin-left: 59px;
    }

    &.card-feature-dark-image-above {
      margin-right: 8%;
    }

    &.hero-v2-image-above---01 {
      max-width: 32%;
      margin-bottom: 10%;
    }

    &.hero-v2-image-above---02 {
      max-width: 28%;
      margin-top: 10%;
      margin-right: 0%;
      top: 0%;
      bottom: auto;
      left: auto;
      right: 0%;
    }

    &.feature-grid-v2-image-above---01,
    &.feature-grid-v2-image-above---02 {
      margin-bottom: 15%;
    }

    &.back-shadow {
      box-shadow: 0 23px 40px rgba(20, 20, 43, 0.14);
    }

    &.card-top-bg---bottom {
      &.hero-v12 {
        min-height: 30%;
      }

      &.hero-v13 {
        min-height: 40%;
      }

      &.contact-v2 {
        min-height: 30%;
      }
    }

    &.sign-up-image-above {
      max-width: 25%;
      margin-bottom: 18.5%;
    }

    &.card-help-center-bg---bottom {
      min-height: 65%;
    }

    &.help-center-top {
      min-height: 410px;
    }
  }

  .mg-bottom-93px {
    margin-bottom: 78px;
  }

  .logo-strips-wrapper.center---tablet {
    justify-content: center;
  }

  .cta-v1-wrapper {
    padding-top: 123px;
    padding-bottom: 123px;
  }

  .cta-v2-wrapper {
    padding-top: 115px;
    padding-bottom: 115px;
    padding-left: 64px;
  }

  .bg-grid-white {
    background-size: 80px;
  }

  .card-feature-featured-content {
    max-width: 100%;
    padding-top: 80px;
    padding-bottom: 51px;

    &.v2 {
      padding-top: 70px;
      padding-bottom: 70px;
    }
  }

  .card-feature-featured-images {
    max-width: 100%;
    padding-top: 0;
  }

  .card-feature-light-content {
    padding-bottom: 51px;
  }

  .section-integration-02 {
    margin-bottom: 103px;
  }

  .section-integration-03 {
    margin-bottom: 124px;
  }

  .section-integration-05,
  .section-integration-06 {
    display: none;
  }

  .section-integration-08 {
    margin-top: 125px;
  }

  .section-integration-09 {
    margin-top: 145px;
  }

  .video-btn {
    width: 19%;
    height: 36%;
    min-height: 36%;
    min-width: 19%;
  }

  .menu-wrapper.contact {
    max-width: 100%;
    flex: 0 auto;
  }

  .menu-main-wrapper {
    max-width: 800px;
    grid-row-gap: 60px;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
  }

  .card-slider-blog-post-content {
    padding-left: 45px;
    padding-right: 45px;
  }

  .hero-v2-grid {
    grid-row-gap: 80px;
    grid-template-columns: 1fr;
  }

  ._2-grid-wrapper {
    grid-row-gap: 155px;
  }

  .link-content-block {
    &.blog-post-featured-last {
      max-width: 100%;
    }

    &.blog-post-featured {
      min-width: auto;
      justify-content: flex-start;
    }
  }

  .blog-post-featured-last-content {
    max-width: 600px;
  }

  .blog-post-featured-content {
    max-width: 400px;
  }

  .integrations-strip-wrapper {
    grid-row-gap: 32px;
  }

  .integrations-strip {
    grid-column-gap: 25px;
    justify-content: center;

    &.top {
      margin-left: -10%;
    }

    &.bottom {
      margin-right: -30%;
    }
  }

  .card-integration-strip-title {
    font-size: 23px;
  }

  .mg-top-0px---tablet {
    margin-top: 0;
  }

  .card-blog-post-content {
    max-width: 450px;
  }

  .testimonials-strip-wrapper {
    width: 1260px;
  }

  .card-testimonial-strip-paragraph {
    font-size: 20px;
  }

  .hero-v4-images-above-wrapper---01 {
    grid-row-gap: 63px;
  }

  .hero-v4-images-above-wrapper---02 {
    grid-row-gap: 71px;
  }

  .mg-bottom-0px---tablet {
    margin-bottom: 0;
  }

  .mg-bottom-100px {
    margin-bottom: 80px;
  }

  .categories-wrapper.center---tablet,
  .category-item.center---tablet {
    justify-content: center;
  }

  .card-newsletter-grid {
    justify-content: flex-start;
    padding-top: 120px;
    padding-bottom: 120px;
    padding-left: 80px;
  }

  .card-blog-post-featured-content {
    max-width: 100%;
    padding-top: 50px;
  }

  .category-sidebar-wrapper {
    padding: 15px 20px;

    &.all {
      border-bottom-width: 0;
    }
  }

  .category-sidebar-item {
    display: flex;

    &:last-child {
      border-bottom-width: 1px;
    }
  }

  .categories-sidebar {
    justify-content: center;
    display: flex;
  }

  .card-blog-post-content-v2 {
    max-width: 630px;
  }

  .card-blog-post-v2-title {
    font-size: 30px;
  }

  .card-post-author-content {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  .mg-top--88px {
    margin-top: -73px;
  }

  .open-position-details-main {
    justify-content: center;
  }

  .card-open-position-sidebar-content {
    max-width: 480px;
    flex: 1;
  }

  .mg-bottom-78px {
    margin-bottom: 65px;
  }

  .pricing-wrapper {
    grid-row-gap: 50px;
    flex-direction: column;
    align-items: stretch;
  }

  .card-product-top,
  .card-product-bottom {
    flex: 1;
  }

  .card-product-item {

    &:nth-child(2n),
    &:last-child {
      margin-bottom: 0;
    }
  }

  .add-cart {
    min-height: 288px;
  }

  .integration-main-content {
    grid-row-gap: 38px;
    text-align: center;
    flex-direction: column;
  }

  .tabs-menu-wrapper {
    margin-bottom: 70px;
  }

  .card-contact-links-v1-content {
    max-width: 450px;
  }

  .card-contact-links-v2-content {
    flex: 1;
  }

  .contact-links-wrapper {
    grid-row-gap: 73px;
  }

  .contact-link-wrapper {
    text-align: center;
  }

  .contact-link-title-wrapper {
    grid-row-gap: 15px;
    flex-direction: column;
  }

  .contact-links-v2-wrapper {
    flex-direction: row;
  }

  .card-contact-links-v3-content {
    max-width: 450px;
    flex: 1;
  }

  .footer-small {
    grid-row-gap: 25px;
    text-align: center;
    flex-direction: column;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .header-small-content-wrapper {
    padding-top: 50px;
  }

  .header-small-logo-link {
    max-width: 200px;
  }

  .card-request-demo-form-block {
    min-height: 654px;
    padding: 70px 50px;
  }

  .sign-up-image-wrapper {
    margin-right: 0%;
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .mg-top---118px {
    margin-top: -98px;
  }

  .category-title-wrapper {
    grid-column-gap: 16px;

    &.center---tablet {
      justify-content: center;
    }
  }

  .h2-title-wrapper {
    grid-column-gap: 8px;

    &.center---tablet {
      justify-content: center;
    }
  }

  .card-post-author-page-content {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  .hamburger-menu-icon-wrapper {
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .image-home-link-wrapper {
    max-width: 260px;
  }

  .figma-included-text {
    padding: 40px 40px 44px;
  }

  .figma-included-image {
    max-width: 100%;
  }

  .desktop-nav {
    display: none;
  }

  .mobile-nav {
    .card.dropdown {
      padding-bottom: 0;
    }

    .list-nav {
      margin-bottom: 12px;
    }

    .menu-title-wrapper {
      margin-bottom: 12px;
      padding-bottom: 16px;
    }

    .list-nav-item {
      margin-bottom: 12px;
    }
  }

  .feature-card-wrapper {
    .card {
      &.feature-dark {
        min-height: 650px;
      }

      &.feature-light {
        min-height: 650px;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .mobile-nav {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 16px;
  }

  h1 {
    margin-bottom: 10px;
    font-size: 38px;
  }

  h2 {
    margin-bottom: 10px;
    font-size: 26px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
  }

  blockquote {
    padding: 58px 34px;
    font-size: 20px;
  }

  .grid-2-columns {
    grid-template-columns: 1fr;

    &.style---styleguide-wrapper {
      grid-template-columns: auto;
    }

    &.form {
      grid-row-gap: 20px;
    }

    &.contact-v1-grid {
      grid-row-gap: 60px;
    }

    &.title-and-paragraph {
      grid-row-gap: 12px;
      grid-template-columns: 1.1fr;
    }

    &.text-left-default {
      grid-row-gap: 60px;
    }

    &.text-right-default {
      grid-row-gap: 60px;

      &.v2 {
        grid-row-gap: 60px;
      }
    }

    &.template-page-sidebar {
      grid-template-columns: 1fr;
    }

    &.changelog-item.first {
      grid-column-gap: 30px;
    }

    &.product-page,
    &.section-features-grid {
      grid-row-gap: 50px;
    }

    &.title-and-buttons {
      grid-row-gap: 30px;
      grid-template-columns: 1fr;
    }

    &.blog-featured-main-grid {
      grid-row-gap: 70px;
    }

    &.hero-v5-grid,
    &.blog-sidebar-grid {
      grid-row-gap: 60px;
    }

    &.about-position-grid {
      grid-row-gap: 90px;
    }

    &.integrations-grid,
    &.contact-v2-grid {
      grid-row-gap: 60px;
    }

    &.office-v2 {
      grid-template-columns: 1fr;
    }

    &.contact-v3-grid {
      grid-row-gap: 60px;
    }

    &.request-demo-grid,
    &.request-demo-grid-v2 {
      grid-row-gap: 90px;
    }

    &.sign-up-grid {
      grid-row-gap: 70px;
    }

    &.team-member-grid {
      grid-row-gap: 80px;
    }
  }

  .card {
    &.link-card {
      &.integration-item {
        padding: 48px 34px;
      }

      &.help-center-article {
        padding: 45px 34px;
      }

      &.search-result {
        padding: 43px 34px;
      }
    }

    &.sticky-top.top-24px.static-mbl {
      position: static;
    }

    &.integration-strip {
      grid-column-gap: 15px;
      padding: 20px;
    }

    &.testimonial-slider {
      border-radius: 20px;
      padding-left: 40px;
      padding-right: 40px;
    }

    &.testimonial-strip {
      max-width: 100%;
      padding: 43px 34px 50px;
    }

    &.join-team {
      height: auto;
      border-radius: 15px;
      margin-top: 42px;
      margin-right: 0%;
      padding-top: 120px;
      padding-bottom: 120px;
      position: relative;
      top: auto;
      bottom: auto;
      right: auto;
      transform: none;
    }

    &.press-slider {
      border-radius: 20px;
      padding: 43px 34px 53px;
    }

    &.open-position {
      grid-column-gap: 25px;
      padding: 43px 34px 48px;
    }

    &.post-author {
      padding: 50px 34px 57px;
    }

    &.card-open-position-sidebar {
      grid-column-gap: 30px;
      margin-top: -210px;
      padding: 60px 34px;
    }

    &.section-card-contact {
      text-align: center;
      border-radius: 20px;
      padding: 83px 34px;
    }

    &.product {
      max-width: 600px;
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &.add-cart {
      padding-left: 34px;
      padding-right: 34px;
    }

    &.contact-links-v1 {
      grid-column-gap: 30px;
      padding: 70px 34px;
    }

    &.form-v1 {
      min-height: 954px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 60px 34px;
    }

    &.contact-links-v2 {
      grid-column-gap: 30px;
      padding-left: 34px;
      padding-right: 34px;
    }

    &.form-v2 {
      min-height: 954px;
      padding: 60px 34px;
    }

    &.contact-links-v3 {
      grid-column-gap: 30px;
      padding: 50px 34px;
    }

    &.form-v3 {
      min-height: 1004px;
      padding: 60px 34px 110px;
    }

    &.log-in-form-block {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 60px 34px;
    }

    &.forgot-password-form-block {
      min-height: 694px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 62px 34px 75px;
    }

    &.reset-password-form-block {
      min-height: 842px;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 62px 34px 69px;
    }

    &.email-verification {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 62px 34px;
    }

    &.help-center-category {
      padding: 61px 34px;
    }

    &.protected-page {
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 60px 34px;
    }

    &.post-author-page {
      padding: 34px 34px 57px;
    }

    &.dropdown {
      margin-top: 20px;
      padding-top: 15px;
      padding-bottom: 40px;
    }
  }

  .style---color-block {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
  }

  .grid-4-columns.values-grid {
    grid-row-gap: 65px;
  }

  .style---block-sub-heading.border {
    margin-bottom: 48px;
    padding-bottom: 24px;
  }

  .divider {
    margin-top: 60px;
    margin-bottom: 60px;

    &.contact-links-v2-divider {
      width: 100%;
      height: 1px;
      margin: 60px 0;
    }
  }

  .style---typography-block-grid {
    grid-row-gap: 28px;
    grid-template-columns: 1fr;
  }

  .style---content-block {
    margin-bottom: 64px;
  }

  .mg-bottom-24px {
    margin-bottom: 20px;

    &.keep {
      margin-bottom: 24px;
    }
  }

  .mg-bottom-32px {
    margin-bottom: 24px;

    &.keep {
      margin-bottom: 32px;
    }
  }

  .mg-bottom-40px.responsive {
    margin-bottom: 30px;
  }

  .mg-bottom-48px,
  .mg-bottom-56px {
    margin-bottom: 40px;
  }

  .mg-bottom-64px {
    margin-bottom: 48px;
  }

  .mg-bottom-72px,
  .mg-bottom-80px {
    margin-bottom: 60px;
  }

  .mg-top-24px {
    margin-top: 20px;

    &.keep {
      margin-top: 24px;
    }
  }

  .mg-top-32px {
    margin-top: 24px;
  }

  .mg-top-48px {
    margin-top: 40px;
  }

  .mg-top-64px {
    margin-top: 53px;
  }

  .mg-top-80px {
    margin-top: 60px;
  }

  .text-200 {
    font-size: 16px;
  }

  .display-1 {
    font-size: 42px;
  }

  .grid-1-column.gap-column-24px,
  .gap-row-24px {
    grid-row-gap: 20px;
  }

  .gap-row-32px {
    grid-row-gap: 26px;
  }

  .gap-row-56px {
    grid-row-gap: 32px;
  }

  .display-2 {
    font-size: 32px;
  }

  .display-3 {
    font-size: 23px;
  }

  .display-4 {
    font-size: 20px;
  }

  .style---style-grid-wrapper {
    grid-row-gap: 120px;
  }

  .paragraph-large,
  .text-400 {
    font-size: 20px;
  }

  .text-300 {
    font-size: 18px;
  }

  .text-100 {
    font-size: 14px;
  }

  .text-link.category {
    font-size: 18px;
  }

  .grid-3-columns {
    grid-template-columns: 1fr;

    &.gap-row-80px {
      grid-row-gap: 40px;

      &._2-col-mbl {
        grid-template-columns: 1fr 1fr;
      }
    }

    &.style---buttons-grid {
      grid-template-columns: auto;

      &.app-btn-grid {
        grid-template-columns: auto;
      }
    }

    &.logo-strip-v4 {
      grid-column-gap: 20px;
      grid-row-gap: 30px;
    }

    &._3-col-tablet,
    &.blog-v2,
    &.blog-v3 {
      grid-template-columns: 1fr;
    }

    &.mg-bottom-28px {
      margin-bottom: 28px;

      &._2-col-t {
        grid-template-columns: 1fr;
      }
    }
  }

  .style---heading {
    padding: 104px 24px;
  }

  .inner-container {
    &._600px---mbl {
      max-width: 600px;
    }

    &._500px---mbl {
      max-width: 500px;
    }

    &._400px---mbl {
      max-width: 400px;
    }

    &._100---mbl {
      max-width: 100%;
    }

    &.card-integration-strip-image {
      max-width: 60px;
      min-width: 60px;
    }

    &.card-testimonial-slider-logo {
      max-height: 40px;
      min-height: 40px;
    }

    &._450px---mbl {
      max-width: 450px;
    }

    &.card-press-slider-logo {
      max-height: 40px;
      min-height: 40px;
    }

    &._60px---mbl {
      max-width: 60px;
    }

    &.card-open-position-icon-category {
      max-width: 60px;
      margin-top: 10px;
    }

    &._550px---mbl {
      max-width: 550px;
    }

    &._350px---mbl {
      max-width: 350px;
    }
  }

  .style---content-heading {
    margin-bottom: 36px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .style---heading-icon-wrapper {
    max-width: 58px;
    border-radius: 16px;
  }

  .style---content-wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }

  .border-radius-24px {
    border-radius: 15px;
  }

  .border-radius-40px {
    border-radius: 30px;
  }

  .style---card-wrapper {
    border-radius: 18px;
  }

  .style---bg-white {
    padding: 34px 24px;
  }

  .style-buttons-dark {
    padding-left: 24px;
    padding-right: 24px;
  }

  .btn-primary,
  .btn-secondary {
    padding: 22px 32px;
    font-size: 16px;

    &.small {
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: 14px;
    }

    &.large {
      padding: 26px 43px;
      font-size: 18px;
    }
  }

  .badge-primary,
  .badge-secondary {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 16px;

    &.small {
      padding-top: 12px;
      padding-bottom: 12px;
      font-size: 14px;
    }

    &.large {
      font-size: 18px;
    }
  }

  .input {
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 16px;

    &.large.button-inside {
      min-height: 68px;
    }
  }

  .position-relative {
    &.bg-grid-white.small.integrations-hero {
      padding: 50px 34px;
    }

    &.sign-up {
      padding-left: 40px;
    }
  }

  .text-area {
    font-size: 16px;
  }

  .style---avatars-grid {
    grid-template-columns: repeat(auto-fit, 180px);
  }

  .avatar-circle {
    &._02 {
      width: 40px;
      height: 40px;
      max-height: 40px;
      max-width: 40px;
    }

    &._03 {
      width: 56px;
      height: 56px;
      max-height: 56px;
      max-width: 56px;
    }

    &._04 {
      width: 64px;
      height: 64px;
      max-height: 64px;
      max-width: 64px;
    }

    &._05 {
      width: 80px;
      height: 80px;
      max-height: 80px;
      max-width: 80px;
    }

    &._06 {
      width: 120px;
      height: 120px;
      max-height: 120px;
      max-width: 120px;
    }

    &._07 {
      width: 140px;
      height: 140px;
      max-height: 140px;
      max-width: 140px;
    }

    &._08 {
      width: 220px;
      height: 220px;
      max-height: 220px;
      max-width: 220px;
    }
  }

  .style---icons-grid {
    grid-template-columns: repeat(auto-fit, 56px);
  }

  .line-rounded-icon.success-message-check.large {
    font-size: 68px;
    line-height: 73px;
  }

  .style---line-icons-grid {
    grid-template-columns: repeat(auto-fit, 18px);
  }

  .rich-text {

    h2,
    h3 {
      margin-bottom: 20px;
    }

    figure {
      margin-top: 60px;
      margin-bottom: 54px;
    }

    ul,
    ol {
      margin-bottom: 40px;
    }

    h6,
    h5,
    h4 {
      margin-bottom: 20px;
    }

    blockquote {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .style---logo-sidebar {
    max-width: 210px;
  }

  .section {
    padding-top: 140px;
    padding-bottom: 140px;

    &.hero {
      &.v1 {
        background-size: 70px;
        padding-top: 168px;
      }

      &.v2 {
        background-size: 70px;
        padding-top: 168px;
        padding-bottom: 76px;
      }

      &.v3 {
        padding-top: 168px;
      }

      &.v4 {
        padding-top: 168px;
        padding-bottom: 178px;
      }

      &.v5 {
        padding-top: 168px;
      }

      &.v6 {
        padding-top: 168px;
        padding-bottom: 125px;
      }

      &.v7 {
        padding-top: 168px;
        padding-bottom: 130px;
      }

      &.v8 {
        background-size: 70px;
        padding-top: 168px;
        padding-bottom: 168px;
      }

      &.v9 {
        background-size: 70px;
        padding-top: 168px;
        padding-bottom: 98px;
      }

      &.v10 {
        padding-top: 168px;
      }

      &.v11 {
        padding-top: 168px;
        padding-bottom: 64px;
      }

      &.v12 {
        padding-top: 168px;
        padding-bottom: 98px;
      }

      &.v13 {
        padding-top: 168px;
        padding-bottom: 168px;
      }

      &.v14 {
        padding-top: 83px;
      }

      &.v15 {
        padding-top: 168px;
        padding-bottom: 173px;
      }

      &.team-page {
        padding-top: 168px;
        padding-bottom: 98px;
      }

      &.template-pages {
        padding-top: 168px;
        padding-bottom: 140px;
      }
    }

    &.pd-100px {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &.pd-188px {
      padding-top: 108px;
      padding-bottom: 108px;
    }

    &.pd-0px {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.pd-top-0px {
      padding-top: 0;
    }

    &.pd-bottom-53px {
      padding-bottom: 53px;
    }

    &.cta-v3 {
      background-position: 50%;
      background-size: 100%;
      padding-top: 80px;
      padding-bottom: 80px;
    }

    &.pd-290px {
      padding-top: 168px;
      padding-bottom: 168px;

      &.pd-top-0px {
        padding-top: 0;
      }
    }

    &.pd-120px {
      padding-top: 70px;
      padding-bottom: 70px;
    }

    &.pd-162px {
      padding-top: 94px;
      padding-bottom: 94px;
    }

    &.pd-bottom-0px {
      padding-bottom: 0;
    }

    &.pd-140px {
      padding-top: 81px;
      padding-bottom: 81px;

      &.pd-top-0px {
        padding-top: 0;
      }
    }

    &.pd-top-180px {
      padding-top: 100px;
    }

    &.pd-top-140px {
      padding-top: 81px;
    }

    &.pd-top-323px {
      padding-top: 187px;
    }

    &.pd-153px {
      padding-top: 88px;
      padding-bottom: 88px;
    }

    &.utility-page {
      margin-top: -80px;
      padding-top: 173px;
    }

    &.sign-up {
      padding-bottom: 78px;
    }

    &.pd-130px {
      padding-top: 75px;
      padding-bottom: 75px;

      &.pd-top-0px {
        padding-top: 0;
      }
    }

    &.not-found {
      padding-top: 175px;
      padding-bottom: 130px;
    }

    &.protected-page {
      padding-top: 184px;
    }

    &.coming-soon {
      padding-top: 175px;
      padding-bottom: 154px;
    }
  }

  .heading-h1-size {
    font-size: 38px;
  }

  .heading-h2-size {
    font-size: 26px;
  }

  .heading-h3-size {
    font-size: 22px;
  }

  .heading-h4-size {
    font-size: 20px;
  }

  .heading-h5-size {
    font-size: 16px;
  }

  .heading-h6-size {
    font-size: 14px;
  }

  .header-nav-link {
    font-size: 32px;

    &.cart-btn {
      font-size: 16px;
    }

    &.login {
      grid-column-gap: 7px;
    }
  }

  .hamburger-menu-wrapper {
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
    padding: 16px;
  }

  .header-middle {
    margin-right: 20px;
  }

  .btn-square-primary {
    width: 56px;
    height: 56px;
    min-height: 56px;
    min-width: 56px;
    font-size: 20px;

    &.small {
      width: 42px;
      height: 42px;
      min-height: 42px;
      min-width: 42px;
      font-size: 18px;
    }

    &.large {
      width: 72px;
      height: 72px;
      min-height: 72px;
      min-width: 72px;
      font-size: 24px;
    }

    &.slider-arrow.right---top-right {
      top: auto;
      bottom: 0%;
      left: 0%;
      right: 0%;
      transform: translate(65%);

      &:hover {
        transform: scale3d(0.98, 0.98, 1.01) translate(65%);
      }
    }

    &.pagination-btn {
      margin-top: 60px;
    }
  }

  .btn-square-secondary {
    width: 56px;
    height: 56px;
    min-height: 56px;
    min-width: 56px;
    box-shadow: none;
    font-size: 20px;

    &.small {
      width: 42px;
      height: 42px;
      min-height: 42px;
      min-width: 42px;
      font-size: 18px;
    }

    &.large {
      width: 72px;
      height: 72px;
      min-height: 72px;
      min-width: 72px;
      font-size: 24px;
    }

    &.white.pagination-btn {
      margin-top: 60px;
    }

    &.slider-arrow.left---top-right {
      top: auto;
      bottom: 0%;
      left: 0%;
      right: 0%;
      transform: translate(-65%);

      &:hover {
        transform: scale3d(0.98, 0.98, 1.01) translate(-65%);
      }
    }
  }

  .not-found-content {
    max-width: 600px;
  }

  .flex-horizontal.product-page-title {
    grid-column-gap: 13px;
  }

  .footer-top {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .error-message.password {
    margin-left: 24px;
    margin-right: 24px;
  }

  .grid-footer-paragrah---social-media {
    grid-row-gap: 10px;
  }

  .slider-wrapper.top-buttons {
    padding-bottom: 120px;
  }

  .slider-mask.width-400px---mbl {
    max-width: 400px;
    overflow: visible;
  }

  .accordion-item-wrapper {
    grid-column-gap: 30px;
    padding-left: 34px;
    padding-right: 34px;
  }

  .accordion-spacer {
    min-height: 22px;

    &._46px {
      min-height: 30px;
    }
  }

  .pd---content-inside-card {
    padding: 32px 24px 40px;

    &.large {
      padding: 56px 40px;

      &.template-pages {
        padding-left: 32px;
        padding-right: 32px;
      }
    }
  }

  .pricing-column.first {
    display: none;
  }

  .pricing-table-top {
    min-height: auto;
    padding: 40px 18px;
    position: static;

    &.first-mobile {
      padding-top: 0;
    }
  }

  .pricing-content-wrapper {
    padding-left: 18px;
    padding-right: 18px;

    &.empty {
      display: none;
    }
  }

  .image-wrapper {
    &.video-thumbnail {
      border-radius: 28px;
    }

    &.circle-image.card-open-position-category-icon {
      padding: 18px;
    }

    &.integration-page {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
    }
  }

  ._404-not-found {
    margin-bottom: -68px;
    font-size: 35vw;
  }

  .cart-container {
    border-radius: 20px;
  }

  .cart-subtotal-number {
    font-size: 18px;
  }

  .checkout-form {
    max-width: 600px;
  }

  .pricing-tablet {
    display: none;

    &.mobile {
      grid-template-rows: auto;
      grid-template-columns: 1fr;
      grid-auto-columns: 1fr;
      display: grid;
    }
  }

  .position-absolute {
    &.card-feature-featured-image-above {
      margin-left: 34px;
    }

    &.cta-grid-wrapper {
      width: 88%;
      background-size: 100%;
    }

    &.cta-v2-grid-wrapper {
      width: 60%;
      background-size: 100%;
    }

    &.card-bottom-bg---top {
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
    }

    &.section-card-top-bg {
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }

    &.cta-v4-grid-wrapper {
      width: 80%;
      background-size: 100%;
    }

    &.hero-v3-bg {
      min-height: 90%;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
    }

    &.cta-v5-grid-wrapper {
      width: 60%;
      background-size: 100%;
    }

    &.card-newsletter-grid-bg {
      width: 100vw;
      margin-left: -24px;
      margin-right: 0;
      top: 0%;
      bottom: 0%;
      left: 0%;
      right: 0%;
    }

    &.card-top-bg---bottom {
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;

      &.hero-v12 {
        min-height: 25%;
      }

      &.hero-v13 {
        min-height: 45%;
      }
    }

    &.cta-v6-grid-wrapper {
      width: 80%;
      background-size: 100%;
    }

    &.sign-up-image-above {
      max-width: 31%;
    }

    &.card-help-center-bg---bottom {
      min-height: 80%;
    }

    &.help-center-top {
      min-height: 380px;
    }
  }

  .mg-bottom-93px {
    margin-bottom: 65px;
  }

  .logo-strips-wrapper {
    max-width: 500px;
    grid-row-gap: 30px;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }

  .logo-23-mobile {
    max-width: 23%;

    img {
      filter: grayscale(100%);
      transition: filter 0.3s ease-in-out, transform 0.3s ease-in-out;

      &:hover {
        filter: none;
        transform: scale(1.1);
      }
    }
  }

  .cta-v1-wrapper {
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .cta-v2-wrapper {
    border-top-left-radius: 18px;
    border-bottom-left-radius: 18px;
    padding-top: 96px;
    padding-bottom: 96px;
    padding-left: 34px;
  }

  .border-radius-18px---top {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .bg-grid-white {
    background-size: 70px;
  }

  .border-radius-18px {
    border-radius: 10px;
  }

  .card-feature-featured-content {
    padding: 67px 34px 43px;

    &.v2 {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }

  .card-feature-dark-content {
    padding-bottom: 43px;
    padding-left: 34px;
    padding-right: 34px;
  }

  .card-feature-dark-image-above-wrapper {
    grid-row-gap: 15px;
  }

  .card-feature-light-content {
    padding-bottom: 43px;
    padding-left: 34px;
    padding-right: 34px;
  }

  .card-feature-light-images {
    padding-left: 34px;
  }

  .integrations-top,
  .integrations-bottom {
    grid-column-gap: 30px;
  }

  .section-integration-02 {
    margin-bottom: 80px;
  }

  .section-integration-03 {
    margin-bottom: 100px;
  }

  .section-integration-08 {
    margin-top: 80px;
  }

  .section-integration-09 {
    margin-top: 100px;
  }

  .column-contact-link-wrapper {
    padding-bottom: 30px;
  }

  .menu-main-wrapper {
    justify-content: flex-start;
  }

  .card-slider-blog-post-content {
    padding: 40px 34px 50px;
  }

  .hero-v2-grid {
    grid-row-gap: 70px;
    grid-template-columns: 1fr;
  }

  .border-radius-40px---top {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  ._2-grid-wrapper {
    grid-row-gap: 130px;
  }

  .mg-bottom-28px {
    margin-bottom: 23px;
  }

  .link-content-block.blog-post-featured {
    grid-row-gap: 32px;
    flex-direction: column;
    align-items: stretch;
  }

  .mg-bottom-38px {
    margin-bottom: 32px;
  }

  .blog-post-featured-last-content {
    max-width: 500px;
  }

  .blog-post-featured-content {
    max-width: 350px;
  }

  .blog-post-featured-image {
    max-height: none;
    max-width: 100%;
    min-height: auto;
    min-width: 100%;
  }

  .integrations-strip-wrapper {
    grid-row-gap: 20px;
  }

  .integrations-strip {
    grid-column-gap: 20px;
  }

  .card-integration-strip-title {
    font-size: 20px;
  }

  .mg-top-0px---tablet {
    margin-top: 0;
  }

  .gap-row-70px---mbl {
    grid-row-gap: 70px;
  }

  .text-center---mbl {
    text-align: center;
  }

  .section-features-main-wrapper {
    grid-row-gap: 130px;
  }

  .card-blog-post-content {
    max-width: 400px;
  }

  .testimonials-strip-main-wrapper {
    max-width: 600px;
    grid-row-gap: 30px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .testimonials-strip-wrapper {
    width: 100%;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    flex-direction: column;

    &.top {
      margin-left: 0%;
    }

    &.bottom {
      margin-right: 0%;
    }
  }

  .card-testimonial-strip-paragraph {
    font-size: 18px;
  }

  .card-integration-slide-content-top {
    grid-column-gap: 20px;
  }

  .card-testimonial-strip-top {
    margin-bottom: 25px;
  }

  .hero-v4-images-above-wrapper---01 {
    grid-row-gap: 43px;
  }

  .hero-v4-images-above-wrapper---02 {
    grid-row-gap: 50px;
  }

  .mg-bottom-34px {
    margin-bottom: 28px;
  }

  .mg-bottom-12px {
    margin-bottom: 10px;
  }

  .card-office-content {
    padding-left: 34px;
    padding-right: 34px;
  }

  .card-open-position-wrapper {
    grid-column-gap: 20px;
  }

  .card-open-position-details-main {
    grid-column-gap: 10px;
  }

  .mg-bottom-0px---tablet {
    margin-bottom: 0;
  }

  .mg-bottom-100px {
    margin-bottom: 70px;
  }

  .card-newsletter-grid {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 0;
  }

  .card-blog-post-featured-content {
    padding-left: 34px;
    padding-right: 34px;
  }

  .category-sidebar-wrapper {
    padding-left: 5px;
    padding-right: 10px;

    &.all {
      border-bottom-width: 1px;
    }
  }

  .category-sidebar-item,
  .categories-sidebar {
    flex-direction: column;
  }

  .card-blog-post-content-v2 {
    padding-left: 34px;
    padding-right: 34px;
  }

  .card-blog-post-v2-title {
    font-size: 25px;
  }

  .card-post-author-content {
    max-width: 400px;
  }

  .mg-top--88px {
    margin-top: -61px;
  }

  .open-position-details-main {
    grid-column-gap: 10px;
  }

  .card-open-position-sidebar-content {
    max-width: 380px;
  }

  .mg-bottom-78px {
    margin-bottom: 54px;
  }

  .pricing-wrapper {
    grid-row-gap: 40px;
  }

  .section-card-contact-main-content {
    grid-row-gap: 25px;
    flex-direction: column;
    align-items: center;
  }

  .border-radius-40px---bottom {
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
  }

  .add-cart-price {
    font-size: 32px;
  }

  .add-cart {
    min-height: 262px;
  }

  .integration-main-content {
    grid-row-gap: 32px;
  }

  .tabs-menu-wrapper {
    margin-bottom: 60px;
  }

  .card-contact-links-v1-content {
    max-width: 400px;
  }

  .contact-links-wrapper {
    grid-row-gap: 60px;
  }

  .section-card-social-media-wrapper {
    grid-column-gap: 40px;
    grid-row-gap: 40px;
  }

  .contact-link-title-wrapper {
    grid-row-gap: 10px;
  }

  .contact-links-v2-wrapper {
    flex-direction: column;
  }

  .card-contact-links-v3-content {
    max-width: 400px;
  }

  .footer-small {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .header-small-content-wrapper {
    padding-top: 40px;
  }

  .header-small-logo-link {
    max-width: 180px;
  }

  .sidebar-logo-strips-wrapper {
    grid-row-gap: 40px;
  }

  .request-demo-form {
    grid-template-columns: 1fr;
  }

  .card-request-demo-form-block {
    min-height: 1018px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 60px 34px;
  }

  .mg-bottom-45px {
    margin-bottom: 40px;
  }

  .sign-up-form-block {
    min-height: 629px;
  }

  .sign-up-form {
    grid-template-columns: 1fr;
  }

  .sign-up-image-wrapper {
    border-radius: 30px;
    padding: 60px 34px;
  }

  .mg-top---118px {
    margin-top: -82px;
  }

  .protected-page-content {
    max-width: 600px;
  }

  .category-title-wrapper {
    grid-column-gap: 13px;
  }

  .h2-title-wrapper {
    grid-column-gap: 7px;
  }

  .card-post-author-page-content {
    max-width: 400px;
  }

  .flex-vertical---mbl {
    flex-direction: column;
    display: flex;
  }

  .image-home-link-wrapper {
    max-width: 280px;
  }

  .figma-included-text {
    padding-left: 24px;
    padding-right: 24px;
  }

  .feature-card-wrapper {
    .card {
      &.feature-dark {
        min-height: 530px;
      }

      &.feature-light {
        min-height: 530px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .hero-banner-container {
    height: 560px;
  }

  .hero-banner-image {
    width: 77%;
    height: 62%;
    top: -2%;
    right: -6%;
  }

  .footer-banner-container {
    height: 560px;
    margin-top: 80px;
  }

  .footer-banner-image {
    width: 77%;
    left: 30%;
    top: 6%;
    height: 56%;
  }

  .banner-desc {
    .left {
      h3 {
        font-weight: 900;
        font-size: 50px;
        margin-left: 5px;
      }

      p {
        margin: 18px;
      }
    }

    .right {
      h3 {
        font-size: 45px;
      }

      p {
        font-size: 18px;
      }

      .company-desc {
        font-size: 14px;
      }
    }

    flex-wrap: wrap;
    gap: 20px;
  }

  .hero-banner-container {
    line-height: 1.3;

    h1 {
      font-size: 50px;
    }

    h3 {
      font-size: 40px;
    }

    button {
      margin-top: 90px;
      z-index: 10000;
    }
  }

  .desc {
    bottom: 60px;
  }

  .product-detail-container {
    flex-wrap: wrap;

    .product-detail-image {
      width: 350px;
      height: 350px;
    }
  }

  .cart-container {
    width: 415px;
    padding: 4px;
  }

  .cart-heading {
    margin-top: 35px;
  }

  .product-container {
    margin-top: 10px;
  }

  .product {
    padding: 20px 5px;

    .cart-product-image {
      width: 25%;
      height: 25%;
    }
  }

  .buttons {

    .add-to-cart,
    .buy-now {
      width: 150px;
    }
  }

  .product-detail-container {
    margin: 20px;
  }

  .item-desc .flex {
    width: 200px;
  }

  .top {
    flex-wrap: wrap;
    gap: 10px;
  }

  .item-desc .bottom {
    margin-top: 30px;
  }

  .flex {
    h5 {
      font-size: 16px;
      color: #324d67;
    }

    h4 {
      font-size: 16px;
      color: black;
    }
  }

  .cart-bottom {
    padding: 30px;
  }

  .total h3 {
    font-size: 20px;
  }

  .track {
    animation: marquee 10s linear infinite;
    width: 550%;
  }

  .success-wrapper,
  .cancel-wrapper {
    min-height: 69vh;
  }

  .success,
  .cancel {
    width: 370px;
    margin-top: 100px;
    padding: 20px;
  }

  .success {
    height: 350px;

    h2 {
      font-size: 17px;
    }
  }

  .btn-container {
    width: 300px;
    margin: auto;
  }
}