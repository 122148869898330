// Importing variables
@import 'variables';

// Success Message
.success-message {
  background-color: transparent;
  color: $secondary-1;
  font-weight: 700;
  padding: 0;
}

// Error Message
.error-message {
  background-color: transparent;
  color: $red-300;
  margin-top: 20px;
  padding: 0;

  &.badge {
    background-color: $secondary-1;
    border-radius: 10px;
    padding: 15px 10px;
    text-align: center;
  }

  &.cart-error {
    margin-top: 0;
  }
}

// Success and Cancel Wrapper
.success-wrapper,
.cancel-wrapper {
  background-color: $neutral-100;
  min-height: 60vh;
}

// Success and Cancel
.success,
.cancel {
  align-items: center;
  background-color: $neutral-400;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 160px auto 0;
  padding: 50px;
  width: 1000px;
}

// Success
.success {
  .icon {
    color: $green-300;
    font-size: 40px;
  }

  h2 {
    color: $neutral-800;
    font-size: 40px;
    font-weight: 900;
    margin: 15px 0;
    text-transform: capitalize;
  }

  .email-msg {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
  }

  .description {
    font-size: 16px;
    font-weight: 600;
    margin: 30px 10px 10px;
    text-align: center;

    .email {
      color: $red-400;
      margin-left: 5px;
    }
  }
}

// Cancel
.cancel p {
  font-size: 20px;
  font-weight: 600;
}

// Canceled
.canceled {
  display: grid;
  height: calc(100vh - 180px);
  place-items: center;
}

// Toast Container
.toast-container {
  left: 50%;
  position: fixed;
  top: 10%;
  transform: translate(-50%, -50%);
  width: 380px;
  z-index: 9999;
}