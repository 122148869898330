.logo-28 {
  width: 28%;
}

.logo-80 {
  width: 80%;
}

.logo-90 {
  width: 90%;
}

.logo-70 {
  width: 70%;
}

.logo-23 {
  width: 23%;
}

.logo-30 {
  width: 30%;
}

.logo-25 {
  width: 25%;
}

.logo-21 {
  width: 21%;
}

.logo {
  color: gray;
  font-size: 22px;
  text-transform: uppercase;
}

.logo-strips-wrapper {
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}