// Importing variables
@import 'variables';

// Dropdown Column Wrapper
.dropdown-column-wrapper {
  background-color: transparent;
  transform: translate(-50%);
}

// Hamburger Menu Wrapper
.hamburger-menu-wrapper.w--open {
  background-color: transparent;
}

// Not Found Content
.not-found-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 640px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;
}

// Hidden on Desktop
.hidden-on-desktop {
  display: none;
}

// List Navigation
.list-nav {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}

// List Navigation Item
.list-nav-item {
  line-height: 1.111em;
  margin-bottom: 18px;
  padding-left: 0;
}

// Navigation Link
.nav-link {
  color: $neutral-600;
  display: inline-block;
  line-height: 1.111em;
  text-decoration: none;
  transform-style: preserve-3d;
  transition: transform .3s, color .3s;

  &:hover {
    color: $accent-1;
    transform: translate3d(8px, 0, 0.01px);
  }

  &.accent {
    color: $accent-1;
    font-weight: 600;
  }
}

// Empty State
.empty-state {
  background-color: $neutral-100;
  border: 1px solid $neutral-300;
  border-radius: 11px;
  box-shadow: 0 2px 12px rgba(20, 20, 43, 0.08);
  color: $secondary-1;
  padding: 26px 24px;
  text-align: center;

  &.small {
    padding: 20px;
  }

  &.pricing {
    padding-bottom: 130px;
    padding-top: 130px;
  }

  &.card-dark {
    background-color: transparent;
    border-bottom-color: $neutral-700;
    border-left-width: 0;
    border-radius: 0;
    border-right-width: 0;
    border-top-color: $neutral-700;
    color: $neutral-100;
    padding-left: 0;
    padding-right: 0;
  }

  &.cart-empty {
    background-color: transparent;
    border-radius: 0;
    border-width: 0;
    box-shadow: none;
    display: flex;
    flex-direction: column;
    padding-bottom: 100px;
    padding-top: 100px;
  }
}

// 404 Not Found
._404-not-found {
  color: $neutral-800;
  font-size: 300px;
  font-weight: 700;
  line-height: 1em;
  margin-bottom: -102px;
}

// Template Pages Nav Item Wrapper
.template-pages---nav-item-wrapper {
  padding-left: 0;
}

// Template Pages Sidebar Navigation
.template-pages---sidebar-navigation {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
}

// Template Pages Nav Item Link
.template-pages---nav-item-link {
  background-color: transparent;
  border-radius: 8px;
  color: $neutral-600;
  display: flex;
  margin-bottom: 4px;
  margin-top: 4px;
  padding: 8px 16px;
  text-decoration: none;
  transition: background-color .3s, color .3s;

  &:hover {
    background-color: $neutral-200;
    color: $secondary-1;
  }

  &.w--current {
    background-color: $secondary-1;
    color: $neutral-100;
    font-weight: 700;
  }
}

// Template Pages Photography Wrapper
.template-pages---phography-wrapper {
  border-radius: 16px;
  overflow: hidden;
  transform-style: preserve-3d;
  transition: transform .3s, color .3s;

  &:hover {
    transform: scale3d(0.98, 0.98, 1.01);
  }
}

// Icon Left Content
.icon-left-content {
  display: flex;
  grid-column-gap: 16px;
}

// CTA v1 Wrapper
.cta-v1-wrapper {
  background-color: $accent-1;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  overflow: hidden;
  padding-bottom: 148px;
  padding-right: 34px;
  padding-top: 148px;
  position: relative;
  z-index: 2;
}

// Form Button Inside Block
.form-button-inside-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  min-height: 68px;
}

// CTA v2 Wrapper
.cta-v2-wrapper {
  background-color: $accent-1;
  border-bottom-left-radius: 40px;
  border-top-left-radius: 40px;
  padding-bottom: 138px;
  padding-left: 77px;
  padding-top: 148px;
}

// Integrations Top
.integrations-top {
  align-items: flex-end;
  display: flex;
  grid-column-gap: 40px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  padding-bottom: 50px;
}

// Integrations Center
.integrations-center {
  align-items: center;
  display: flex;
  grid-column-gap: 40px;
}

// Integrations Bottom
.integrations-bottom {
  align-items: flex-start;
  display: flex;
  grid-column-gap: 40px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  padding-top: 50px;
}

// Section Integration
.section-integration-02 {
  margin-bottom: 124px;
}

.section-integration-03 {
  margin-bottom: 149px;
}

.section-integration-08 {
  margin-top: 150px;
}

.section-integration-09 {
  margin-top: 176px;
}

// Video Filter
.video-filter {
  background-color: $neutral-200;
  bottom: 0%;
  left: 0%;
  opacity: .6;
  position: absolute;
  right: 0%;
  top: 0%;
}

// Menu Wrapper Contact
.menu-wrapper.contact {
  flex: 1;
  max-width: 356px;
  width: 100%;
}

// Nav Content
.nav-content {
  display: flex;
  grid-column-gap: 4px;
}

// Success Message Aside Icon Wrapper
.success-message-aside-icon-wrapper {
  align-items: center;
  display: flex;
  grid-column-gap: 25px;

  &.center {
    justify-content: center;
  }
}

// Link Content Block
.link-content-block {
  color: $neutral-700;
  display: block;
  text-decoration: none;

  &:hover {
    color: $neutral-700;
  }

  &.blog-post-featured-last {
    max-width: 678px;
  }

  &.blog-post-featured {
    align-items: center;
    display: flex;
    grid-column-gap: 34px;
    grid-row-gap: 25px;
    justify-content: space-between;
    min-height: 100%;
    min-width: 462px;
    width: 100%;
  }

  &.contact-link {
    color: $neutral-800;
    display: flex;
    grid-column-gap: 12px;

    &:hover {
      color: $accent-1;
    }

    &.white {
      color: $neutral-100;
      transition-property: opacity;

      &:hover {
        opacity: .7;
      }
    }

    &.accent {
      color: $accent-1;
      transition-property: opacity;

      &:hover {
        opacity: .7;
      }
    }
  }
}

// Blog Post Featured Content
.blog-post-featured-content {
  flex: 1;
  max-width: 278px;
}

// Blog Post Featured Image
.blog-post-featured-image {
  align-self: stretch;
  height: 100%;
  max-height: 205px;
  max-width: 208px;
  min-height: 205px;
  min-width: 187px;
  width: 100%;
}

// Integrations Strip Wrapper
.integrations-strip-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-row-gap: 37px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1571px;
}

// Integrations Strip
.integrations-strip {
  display: flex;
  grid-column-gap: 35px;
  justify-content: space-between;
  width: 1538px;

  &.bottom {
    margin-right: -13%;
  }
}

// Section Features Main Wrapper
.section-features-main-wrapper {
  display: flex;
  flex-direction: column;
  grid-row-gap: 140px;
}

// Email Button Form Inside Button
.email-btn-form-inside-btn {
  background-color: transparent;
  padding: 18px 0 18px 24px;
}

// Testimonials Strip Main Wrapper
.testimonials-strip-main-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-row-gap: 35px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1688px;
  width: 100%;
}

// Testimonials Strip Wrapper
.testimonials-strip-wrapper {
  display: flex;
  grid-column-gap: 35px;
  width: 1514px;

  &.top {
    margin-left: -11%;
  }

  &.bottom {
    margin-right: -11%;
  }
}

// Team Item Main
.team-item-main {
  position: relative;
}

// Categories Wrapper
.categories-wrapper,
.category-item {
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 24px;
  grid-row-gap: 24px;

  &.center {
    justify-content: center;
  }
}

// Category Sidebar Wrapper
.category-sidebar-wrapper {
  padding-bottom: 20px;
  padding-top: 20px;

  &.all {
    border-bottom: 1px solid $neutral-800;
  }
}

// Category Sidebar Item
.category-sidebar-item {
  border-bottom: 1px solid $neutral-800;

  &:last-child {
    border-bottom-width: 0;
  }
}

// Open Position Details Main
.open-position-details-main {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: 16px;
}

// Open Position Details
.open-position-details {
  align-items: center;
  display: flex;
  grid-column-gap: 7px;
}

// Pricing Wrapper
.pricing-wrapper {
  align-items: flex-end;
  display: flex;
  grid-column-gap: 21px;
  justify-content: center;
}

// Accordion Item Line Icon
.accordion-item-line-icon {
  background-color: $accent-1;
  border-radius: 100000000px;
  height: 2px;
  max-height: 2px;
  max-width: 13px;
  min-height: 2px;
  min-width: 13px;
  position: absolute;
  width: 13px;

  &.vertical {
    transform: rotate(90deg);
  }
}

// Integration Main Content
.integration-main-content {
  display: flex;
  grid-column-gap: 38px;
}

// Protected Page Content
.protected-page-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 789px;
  overflow: visible;
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 1;
}

// Loader Wrap
.loader-wrap {
  align-items: center;
  background-color: $secondary-1;
  display: none;
  justify-content: center;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  z-index: 2147483647;
}

// Loader Glow
.loader-glow {
  background-color: $neutral-100;
  border-radius: 50%;
  filter: blur(100px);
  height: 400px;
  opacity: .06;
  width: 400px;
}

// Loader Grid Background
.loader-grid-bg {
  background-attachment: scroll;
  background-color: $secondary-1;
  background-image: url('/images/bg-grid-techkit-template.svg');
  background-position: 50%;
  background-size: auto;
  mix-blend-mode: darken;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

// Accordion Paragraph Body
.acordion-paragraph-body {
  margin-bottom: 0;
}

// Home Pages Container
.home-pages-cointainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 90px;
  position: relative;
  width: 100%;
  z-index: 2;
}

// Home Pages Top Wrapper
.home-pages-top-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  position: static;
}

// Home Pages Bottom Wrapper
.home-pages-bottom-wrapper {
  display: flex;
  justify-content: center;
  position: static;
  top: 50%;
}

// Figma Included Text
.figma-included-text {
  padding-left: 64px;
  padding-right: 64px;
}

// Figma Included Image
.figma-included-image {
  flex: none;
  max-width: 656px;
  width: 100%;
}

// Home Sales Card Image
.home-sales-card-image {
  border-bottom: 1px solid $neutral-300;
  position: relative;
}

// Home Sales Card Text
.home-sales-card-text {
  padding: 40px 26px;
  text-align: center;
}

// W Nav Link Current
.w-nav-link.w--current {
  color: #0082f3 !important;
}

// Home Feature Card
#home-feature-card {
  grid-area: span 1 / span 2 / span 1 / span 2;
}

// Custom List Item
.custom-list-item {
  list-style: none;
  position: relative;
}

// List Item Bullet
.list-item-bullet {
  background-color: #045aff;
  border-radius: 50%;
  color: $neutral-100;
  font-size: 24px;
  font-weight: bold;
  height: 51px;
  line-height: 51px;
  position: absolute;
  left: 0;
  text-align: center;
  top: 16px;
  width: 51px;

  &::before {
    content: attr(data-number);
  }
}

// Custom List Item Paragraph
.custom-list-item p {
  margin-left: 65px;
}

// Banner Description
.banner-desc {
  display: flex;
  justify-content: space-between;

  button {
    background-color: $neutral-100;
    border: none;
    border-radius: 15px;
    color: red;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    margin-top: 40px;
    padding: 10px 16px;
  }

  .left {
    h3 {
      font-size: 80px;
      font-weight: 900;
      margin-left: 25px;
    }

    p {
      margin: 18px;
    }
  }
}

// Banner Description Right
.banner-desc .right {
  line-height: 1.4;

  h3 {
    font-size: 60px;
    font-weight: 800;
  }

  p {
    font-size: 18px;
  }

  .company-desc {
    font-size: 14px;
    font-weight: 300;
  }
}

// Max Quantity
.max-qty {
  color: $red-400;
  font-weight: 500;
}

.feature-wrapper {
  align-items: baseline;
  display: flex;
  font-size: $font-size-lg;
}

.menu-main-wrapper {
  grid-column-gap: 40px;
  justify-content: flex-start;
  /* justify-content: space-between; TODO: put back when third column in nav is reintroduced */
  display: flex;
}

.menu-title-wrapper {
  border-bottom: 1px solid $color-neutral-300;
  margin-bottom: 24px;
  padding-bottom: 24px;

  &.dark-border {
    border-bottom-color: $color-neutral-700;
  }
}

.column-contact-link-wrapper {
  border-bottom: 1px solid $color-neutral-700;
  margin-bottom: 32px;
  padding-bottom: 40px;
  display: flex;

  &.last {
    border-bottom-width: 0;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.column-contact-link {
  grid-column-gap: 12px;
  color: $color-neutral-600;
  align-items: flex-start;
  text-decoration: none;
  display: flex;

  &:hover {
    color: $color-primary;
  }
}

.tooltip {
  cursor: pointer;
}

// Awards
.awards {
  background: #ffffff;
  padding: 60px 0;

  .grid-2-columns {
    display: grid;
    gap: 80px;
    grid-template-columns: 1fr 2fr;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
    }
  }

  .left-column {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
  }

  .digicert-logo {
    margin-bottom: 0;
  }

  .right-column {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .grid-4-columns {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(4, 1fr);

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .award-item {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 20px;
  }

  .award-image {
    height: 120px;
    object-fit: contain;
    width: auto;
  }
}