// _typography.scss
@import 'variables';

@font-face {
  font-family: 'Social Icons Font';
  src: url('/fonts/social-icon-font.woff2') format("woff2"),
    url('/fonts/social-icon-font.eot') format("embedded-opentype"),
    url('/fonts/social-icon-font.woff') format("woff"),
    url('/fonts/social-icon-font.ttf') format("truetype"),
    url('/fonts/social-icon-font.svg') format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Line Square Icons';
  src: url('/fonts/line-square-icons.woff2') format("woff2"),
    url('/fonts/line-square-icons.eot') format("embedded-opentype"),
    url('/fonts/line-square-icons.woff') format("woff"),
    url('/fonts/line-square-icons.ttf') format("truetype"),
    url('/fonts/line-square-icons.svg') format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Filled Icons';
  src: url('/fonts/filled-icon-font.woff2') format("woff2"),
    url('/fonts/filled-icon-font.eot') format("embedded-opentype"),
    url('/fonts/filled-icon-font.woff') format("woff"),
    url('/fonts/filled-icon-font.ttf') format("truetype"),
    url('/fonts/filled-icon-font.svg') format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Line Rounded Icons';
  src: url('/fonts/line-rounded-icons.woff2') format("woff2"),
    url('/fonts/line-rounded-icons.eot') format("embedded-opentype"),
    url('/fonts/line-rounded-icons.woff') format("woff"),
    url('/fonts/line-rounded-icons.ttf') format("truetype"),
    url('/fonts/line-rounded-icons.svg') format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('/fonts/ClarityCity-Regular.otf') format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('/fonts/ClarityCity-Medium.otf') format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('/fonts/ClarityCity-Bold.otf') format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Clarity City';
  src: url('/fonts/ClarityCity-SemiBold.otf') format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

body,
.ant-row>div,
.ant-col,
.ant-list-item,
.ant-collapse,
.ant-typography,
.ant-checkbox-wrapper,
.ant-radio-wrapper,
.ant-card,
.ant-card-head-title,
.ant-tabs,
.ant-tabs-tab,
.ant-tabs-content,
.ant-tabs-nav,
.ant-tabs-nav-list {
  font-family: $font-primary !important;
  font-size: $font-size-base;
  font-weight: 400;
  line-height: $line-height-base;
  color: $color-neutral-700;
}

// Table and breadcrumb specific typography
.ant-table,
.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table-cell,
.ant-table-content,
.ant-breadcrumb,
.ant-breadcrumb-link,
.ant-breadcrumb-separator,
.ant-breadcrumb>span:last-child {
  font-family: $font-primary !important;
  font-size: $font-size-sm !important;
  font-weight: 400;
  line-height: $line-height-base;
  color: $color-neutral-700;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $color-neutral-800;
  margin-top: 0;
}

h1 {
  font-size: 54px;
  font-weight: 700;
  line-height: 1.222em;
  margin-bottom: 20px;
}

h2 {
  font-size: 38px;
  font-weight: 700;
  line-height: 1.316em;
  margin-bottom: 16px;
}

h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.417em;
  margin-bottom: 10px;
}

.paragraph-large {
  font-size: 1.375rem;
  line-height: 1.5;
}

.paragraph-medium {
  font-size: 1.125rem;
}

.paragraph-small {
  font-size: 14px;
  line-height: 1.714em;
}

.text-400 {
  font-size: 24px;
  line-height: 1.083em;

  &.medium {
    font-weight: 500;
  }

  &.bold {
    font-weight: 700;
  }
}

.text-300 {
  font-size: 20px;
  line-height: 1.1em;

  &.medium {
    font-weight: 500;
  }

  &.bold {
    font-weight: 700;
  }
}

.text-100 {
  font-size: 16px;
  line-height: 1.125em;

  &.medium {
    font-weight: 500;
  }

  &.bold {
    font-weight: 700;
  }
}

.text-bold {
  color: $color-neutral-800;
  font-weight: 700;
}

.text-link {
  color: $color-primary;
  text-decoration: underline;
  transition: color 0.3s;

  &:hover {
    color: $color-neutral-800;
  }

  &.color-neutral-600 {
    color: #919497;

    &:hover {
      color: $color-primary;
    }
  }

  &.category {
    color: #96a0aa;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.1em;
    text-decoration: none;

    &:hover,
    &.w--current {
      color: $color-primary;
    }
  }
}

.text-uppercase {
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

.text-strikethrough {
  text-decoration: line-through;
}

.link-wrapper {
  color: $color-neutral-800;
  display: inline-block;

  &.color-primary {
    color: $color-primary;
    transition-property: opacity;
    transition-duration: 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }

  &.white {
    color: #fff;
    transition-property: opacity;

    &:hover {
      opacity: 0.7;
    }
  }
}

.link-text {
  display: inline-block;
}

.heading-h1-size {
  color: $color-neutral-800;
  font-size: 54px;
  font-weight: 700;
  line-height: 1.222em;
}

.heading-h2-size {
  color: $color-neutral-800;
  font-size: 38px;
  font-weight: 700;
  line-height: 1.316em;

  &.color-neutral-100 {
    color: #fff;
  }
}

.heading-h3-size {
  color: $color-neutral-800;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.417em;

  &.color-neutral-100 {
    color: #fff;
  }
}

.heading-h4-size {
  color: $color-neutral-800;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.273em;

  &.color-neutral-100 {
    color: #fff;
  }
}

.heading-h5-size {
  color: $color-neutral-800;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.333em;
}

.heading-h6-size {
  color: $color-neutral-800;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.375em;
}

.header-wrapper {
  height: 0;
  background-color: rgba(0, 0, 0, 0);
  margin-top: 17px;
  margin-bottom: -17px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.header-content-wrapper {
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  position: relative;
}

.header-nav-menu-list {
  z-index: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
}

.header-nav-link {
  color: $color-secondary-1;
  font-size: $font-size-lg;
  padding: 0;
  line-height: 1.111em;
  text-decoration: none;

  &:hover {
    color: $color-primary;
  }

  &.dropdown {
    align-items: center;
    display: flex;
  }

  &.cart-btn {
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0);
  }

  &.login {
    grid-column-gap: 6px;
    align-items: center;
    display: flex;
  }

  &.white {
    color: $color-neutral-100;

    .chevron {
      color: $color-neutral-100;
    }

    &:hover {
      color: $color-primary;
    }
  }

  &.on-white {
    color: $color-secondary-1;

    .chevron {
      color: $color-secondary-1;
    }
  }
}

.header-nav-list-item {
  margin-bottom: 0;
  padding-left: 32px;
  line-height: 1.111em;

  &.show-in-tablet {
    display: none;
  }

  &.middle {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.text-semi-bold {
  font-weight: 600;
}

.rich-text {
  img {
    border-radius: 24px;
  }

  p {
    margin-bottom: 31px;
  }

  h2,
  h3 {
    margin-bottom: 24px;
  }

  figure {
    margin-top: 71px;
    margin-bottom: 64px;
  }

  ul,
  ol {
    margin-bottom: 48px;
  }

  h6,
  h5,
  h4 {
    margin-bottom: 24px;
  }

  blockquote {
    margin-top: 48px;
    margin-bottom: 48px;
  }
}

h4 {
  color: $color-neutral-800;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  line-height: 1.273em;
}

h5 {
  color: $color-neutral-800;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.333em;
}

h6 {
  color: $color-neutral-800;
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.375em;
}

p {
  margin-bottom: 16px;
}

a {
  color: $color-primary;
  text-decoration: none;
  transition: color 0.3s;

  &:hover {
    color: $color-neutral-500;
  }
}

ul,
ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

li {
  margin-bottom: 8px;
  padding-left: 8px;
}

img {
  max-width: 100%;
  display: inline-block;
}

label {
  color: $color-neutral-800;
  margin-bottom: 16px;
  font-weight: 700;
  line-height: 1.111em;
  display: block;
}

.white {
  color: #fff;
}

strong {
  color: #656769;
  font-weight: 700;
}

blockquote {
  color: #fff;
  text-align: center;
  background-color: $color-secondary-1;
  border: 0 solid #000;
  border-radius: 18px;
  margin-top: 14px;
  margin-bottom: 32px;
  padding: 68px 58px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.417em;
}

figure {
  margin-top: 24px;
  margin-bottom: 48px;
}

figcaption {
  text-align: center;
  margin-top: 16px;
}

.h2-title-wrapper {
  grid-column-gap: 10px;
  flex-wrap: wrap;
  display: flex;
}

.text-medium {
  font-weight: 500;
}