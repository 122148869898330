@import 'variables';

.footer-wrapper {
  background-color: $secondary-1;
}

.footer-logo {
  transform-style: preserve-3d;
  transition: transform 0.3s;
  width: 100%;

  &:hover {
    transform: scale3d(0.98, 0.98, 1.01);
  }
}

.footer-logo-wrapper {
  margin-bottom: 18px;
  max-width: 191px;
  width: 100%;

  &.mg-bottom-0 {
    margin-bottom: 0;
  }

  &.small {
    max-width: 174px;
  }
}

.footer-top {
  border-bottom: 1px solid $neutral-700;
  padding-bottom: 91px;
  padding-top: 91px;

  input.input {
    background-color: #1f1f1f00;
    border: 1px solid #656769 !important;
    color: $neutral-100;

    &.input-charcoal {
      background-color: #1f1f1f00;
      color: $neutral-100;
    }
  }

}

.footer-middle {
  padding-bottom: 91px;
  padding-top: 91px;
}

.footer-bottom {
  border-top: 1px solid rgba($neutral-700, 0.3);
  padding-bottom: 38px;
  padding-top: 38px;
}

.grid-footer-2-column---logo-and-form {
  align-items: center;
  grid-column-gap: 40px;
  grid-template-columns: 1fr 1.5fr;
  grid-template-rows: auto;
}

.grid-footer-paragrah---social-media {
  align-items: center;
  grid-template-columns: 1fr 0.4fr;
  grid-template-rows: auto;
}

.footer-small-wrapper {
  background-color: $neutral-100;
  margin-top: auto;
}

.footer-small {
  align-items: center;
  display: flex;
  grid-column-gap: 40px;
  justify-content: space-between;
  padding-bottom: 66px;
  padding-top: 66px;
}

.footer-banner-container {
  background-color: $red-400;
  border-radius: 15px;
  color: $neutral-100;
  height: 400px;
  line-height: 1;
  margin-top: 120px;
  padding: 100px 40px;
  position: relative;
  width: 100%;
}

.footer-banner-image {
  left: 25%;
  position: absolute;
  top: -25%;
}

.footer-container {
  align-items: center;
  color: $neutral-800;
  display: flex;
  flex-direction: column;
  font-weight: 700;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
  padding: 30px 10px;
  text-align: center;

  .icons {
    display: flex;
    font-size: 30px;
    gap: 10px;
  }
}