// Colors
$color-primary: #247bfe;
$color-secondary-1: #171717;
$color-neutral-800: #28292b;
$color-neutral-700: #656769;
$color-neutral-600: #919497;
$color-neutral-500: #96a0aa;
$color-neutral-400: #c1cacf;
$color-neutral-300: #d4e1e9;
$color-neutral-200: #eff4f8;
$color-neutral-100: #fff;

$green-400: #11845b;
$green-300: #05c168;
$green-200: #7fdca4;
$green-100: #def2e6;

$blue-400: #086cd9;
$blue-300: #1d88fe;
$blue-200: #8fc3ff;
$blue-100: #eaf4ff;

$red-400: #dc2b2b;
$red-300: #ff5a65;
$red-200: #ffbec2;
$red-100: #ffeff0;

$orange-400: #d5691b;
$orange-300: #ff9e2c;
$orange-200: #ffd19b;
$orange-100: #fff3e4;

// Fonts
$font-primary: 'Clarity City', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Noto Color Emoji';
$font-size-base: 16px;
$line-height-base: 1.5;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xl: 20px;

// Alias Variables for Convenience
$accent-1: $color-primary;
$secondary-1: $color-secondary-1;
$neutral-800: $color-neutral-800;
$neutral-700: $color-neutral-700;
$neutral-600: $color-neutral-600;
$neutral-500: $color-neutral-500;
$neutral-400: $color-neutral-400;
$neutral-300: $color-neutral-300;
$neutral-200: $color-neutral-200;
$neutral-100: $color-neutral-100;