@import 'variables';

// General Styles
body,
.ant-row>div,
.ant-col,
.ant-list-item,
.ant-collapse,
.ant-typography,
.ant-checkbox-wrapper,
.ant-radio-wrapper,
.ant-card,
.ant-card-head-title {
  color: $neutral-700;
  font-family: $font-primary !important;
  font-size: $font-size-base;
  font-weight: 400;
  line-height: $line-height-base;
}

// Alert Styles
.ant-alert {
  align-items: flex-start;

  svg {
    margin-top: 3px;
  }

  &.ant-alert-with-description {
    padding: 8px 12px;
  }

  .ant-alert-content,
  .ant-form-item .ant-form-item-label>label {
    font-family: $font-primary !important;
    font-size: 14px;
  }
}

// Form Styles
.ant-form {
  .ant-form-item {
    margin-bottom: 16px;

    .ant-form-item-label {
      padding-bottom: 0;
    }
  }
}

// Modal Styles
.ant-modal {
  .ant-modal-title {
    font-size: 18px;
    margin-bottom: 16px;
  }
}

// Card Styles
.ant-card {
  font-size: 16px;

  .ant-card-head-title {
    font-weight: bold;
  }
}

// Input Number Styles
.ant-input-number {
  font-size: 16px;
  height: 60px;
  line-height: 54px;
  padding-left: 8px;
  width: 100%;

  &.ant-input-number.ant-input {
    background-color: $neutral-100;
    border: 1px solid $neutral-300;

    &:disabled {
      background-color: rgba(0, 0, 0, .4);
      color: rgba(0, 0, 0, 0.65);
    }
  }
}

// Collapse Styles
.ant-collapse {
  >.ant-collapse-item {
    >.ant-collapse-header {
      background-color: $neutral-200;
      font-weight: bold;

      .ant-collapse-arrow svg {
        color: $accent-1;
      }
    }
  }

  .ant-collapse-content {
    >.ant-collapse-content-box {
      background-color: $neutral-100;
      border-top: 1px solid $neutral-300;
      padding: 16px;
    }
  }
}

// Checkbox and Radio Styles
.ant-checkbox-wrapper {
  .ant-checkbox-inner {
    border: 1px solid $neutral-700;
    height: 24px;
    width: 24px;

    &:after {
      height: 12px;
      inset-inline-start: 24%;
      top: 45%;
      width: 8px;
    }
  }

  &:hover .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: $accent-1;
  }
}

.ant-radio-wrapper {
  .ant-radio-inner {
    border: 1px solid $neutral-700;
    height: 24px;
    width: 24px;

    &:after {
      height: 24px;
      inset-inline-start: 30%;
      top: 30%;
      width: 24px;
    }
  }

  &:hover .ant-radio-inner,
  .ant-radio-checked .ant-radio-inner {
    border-color: $accent-1;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-radio-wrapper:hover .ant-radio-inner,
.ant-checkbox-checked .ant-checkbox-inner,
.ant-radio-checked .ant-radio-inner {
  border-color: $accent-1;
}

// Custom Steps Styles
.myCustomSteps {
  .ant-steps-item-wait {
    .ant-steps-item-icon {
      background-color: $accent-1;
      border-color: $accent-1;

      .ant-steps-icon {
        color: $neutral-100;
      }
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background-color: $green-300;

      span {
        color: $neutral-100;
      }
    }
  }
}

// Layout Styles
.ant-layout {
  font-family: $font-primary !important;

  &-content {
    padding: 16px !important;
    background: $color-neutral-200;
  }

  &-sider {
    font-family: $font-primary !important;
  }
}

// Menu Styles
.ant-menu {
  .ant-menu-item {
    font-weight: bold;
    height: 60px;

    a {
      font-size: 16px;
      text-decoration: none;
      transition: color 0.1s;

      &:hover {
        color: $blue-400;
        text-decoration: none;
      }
    }

    &:not(.ant-menu-item-selected):hover {
      background: $neutral-200 !important;
    }
  }

  .ant-menu-submenu {
    padding-left: 0;

    &-title {
      font-size: 16px;
      font-weight: bold;
      height: 60px;
      line-height: 60px;
    }

    .ant-menu-item {
      background: $neutral-200;
      border-radius: 8px;
      padding-left: 48px !important; // Indent sub-menu items
      height: 40px;
      line-height: 40px;

      a {
        font-size: 14px;
        font-weight: normal !important;
      }
    }

    .ant-menu-submenu-title {
      height: 60px;
    }

    &-open .ant-menu-submenu-title {
      height: 60px;
      color: $blue-300; // Adjust color for open submenu
    }
  }
}

// Pagination Styles
.ant-pagination {

  // Style for all pagination items
  .ant-pagination-item {
    align-items: center;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    padding-left: 0;

    a {
      padding: 0;
      margin: 0;
      text-decoration: none;
    }

    // Remove outline on focus
    &:focus,
    &:focus-visible {
      outline: none;
    }
  }

  // Style for active page
  .ant-pagination-item-active {
    border-color: $accent-1;

    a {
      color: $accent-1;
    }

    &:focus,
    &:hover {
      border-color: $accent-1;

      a {
        color: $accent-1;
      }
    }
  }

  // Style for pagination navigation buttons (prev/next)
  .ant-pagination-prev,
  .ant-pagination-next {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      &:focus,
      &:focus-visible {
        outline: none;
      }
    }
  }

  // Style for the items per page selector
  .ant-pagination-options {
    .ant-select-selector {
      display: flex;
      align-items: center;

      &:focus,
      &:focus-visible {
        outline: none;
      }
    }
  }
}

.ant-select-selection-placeholder,
.ant-select-selection-item,
.ant-input::placeholder,
.ant-input {
  font-family: $font-primary !important;
}

// Button Styles
.ant-btn {

  &:disabled,
  &.ant-btn-disabled {
    background: rgba(255, 255, 255, 0.2) !important;
    border-color: #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed;

    // Specific styles for circle buttons (used in Counter)
    &.ant-btn-circle {
      .anticon {
        color: rgba(0, 0, 0, 0.25) !important;
      }
    }
  }

  // Specific styles for circle buttons in Counter component
  &.ant-btn-circle {

    &:disabled,
    &.ant-btn-disabled {
      opacity: 0.65;
      visibility: visible;
    }
  }
}

.hosting-radio-button {
  height: 40px !important;
  line-height: 38px !important;
}

.ant-radio-button-wrapper-checked.hosting-radio-button {
  background: $color-primary !important;
  border-color: $color-primary !important;
  color: $neutral-100 !important;
  z-index: 1;
}

.ant-radio-button-wrapper.hosting-radio-button:hover {
  color: $color-primary !important;
  z-index: 1;
}

.ant-radio-button-wrapper-checked.hosting-radio-button:hover {
  color: $neutral-100 !important;
}