// _forms.scss
@import 'variables';
@import 'mixins';

// Checkbox Styles
.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;

  &:before,
  &:after {
    content: " ";
    display: table;
    grid-area: 1 / 1 / 2 / 2;
  }

  &:after {
    clear: both;
  }
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 2px;

  &.w--redirected-checked {
    background-color: #3898ec;
    background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-color: #3898ec;
  }

  &.w--redirected-focus {
    box-shadow: 0 0 3px 1px #3898ec;
  }
}

.w-form-formradioinput--inputType-custom {
  width: 12px;
  height: 12px;
  border: 1px solid #ccc;
  border-radius: 50%;

  &.w--redirected-focus {
    box-shadow: 0 0 3px 1px #3898ec;
  }

  &.w--redirected-checked {
    border-width: 4px;
    border-color: #3898ec;
  }
}

// Input Styles
.input {
  @include input-style;
  min-height: 68px;
  color: $color-secondary-1;
  background-color: $color-neutral-200;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  margin-bottom: 0;
  padding: 25px 23px;
  font-size: 18px;
  transition: color 0.3s, border-color 0.3s;

  &:hover {
    border-color: #c1cacf;
  }

  &:focus,
  &.-wfp-focus {
    color: $color-secondary-1;
    border-color: #c1cacf;
  }

  &::placeholder {
    color: #919497;
  }

  &.small {
    min-height: 46px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
      line-height: 18px;
    }

    &.mg-bottom-8px {
      margin-bottom: 8px;
    }
  }

  &.large {
    min-height: 76px;
  }

  &.button-inside {
    padding-right: 180px;
    font-size: 16px;
  }

  &.cart-quantity-input {
    width: 70px;
    min-height: 40px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &.quntity {
    width: 110px;
  }

  &.medium {
    min-height: 56px;
    margin-bottom: 24px;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
      line-height: 18px;
    }

    &.last {
      margin-bottom: 0;
    }
  }

  &.dark {
    @include input-style($color-neutral-700, 1px solid $color-neutral-700, $color-neutral-100);

    &:hover {
      border-color: #c1cacf;
    }

    &:focus {
      color: #fff;
      border-color: #c1cacf;
    }

    &::placeholder {
      color: #fff;
    }
  }

  &.white {
    @include input-style($color-neutral-100, 1px solid $color-neutral-100, $color-neutral-800);

    &:hover {
      border-color: #c1cacf;
    }

    &:focus {
      color: $color-secondary-1;
      border-color: #c1cacf;
    }

    &::placeholder {
      color: #656769;
    }
  }

  &.black {
    color: #fff;
    background-color: $color-neutral-800;

    &:hover {
      border-color: #656769;
    }

    &:focus {
      color: #fff;
      border-color: #656769;
    }

    &::placeholder {
      color: #919497;
    }
  }

  &.select-wrapper {
    flex-direction: column;
    justify-content: center;
    display: flex;
  }
}

// Text Area Styles
.text-area {
  max-height: 300px;
  max-width: 100%;
  min-height: 211px;
  min-width: 100%;
  color: $color-secondary-1;
  background-color: $color-neutral-200;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 8px;
  padding: 24px;
  font-size: 18px;
  transition: color 0.3s, border-color 0.3s;

  &:hover {
    background-color: $color-neutral-300;
  }

  &:focus {
    color: $color-secondary-1;
    background-color: $color-neutral-300;
  }

  &::placeholder {
    color: #656769;
  }

  &.small {
    min-height: 102px;
    margin-bottom: 24px;
    font-size: 16px;

    &::placeholder {
      font-size: 16px;
      line-height: 18px;
    }
  }

  &.white {
    color: $color-secondary-1;
    background-color: #fff;

    &:hover {
      border-color: #c1cacf;
    }

    &:focus {
      color: $color-secondary-1;
      border-color: #c1cacf;
    }

    &::placeholder {
      color: #656769;
    }
  }

  &.dark {
    color: #fff;
    background-color: #656769;

    &:hover {
      border-color: #c1cacf;
    }

    &:focus {
      color: #fff;
      border-color: #c1cacf;
    }

    &::placeholder {
      color: #fff;
    }
  }
}

// Checkbox Field Wrapper
.checkbox-field-wrapper {
  align-items: center;
  margin-bottom: 24px;
  padding-left: 0;
  font-size: 18px;
  line-height: 22px;
  display: flex;

  &.large {
    font-size: 20px;
    line-height: 24px;
  }

  &.small {
    font-size: 16px;
    line-height: 20px;
  }
}

// Checkbox Styles
.checkbox {
  width: auto;
  height: auto;
  min-height: 20px;
  min-width: 20px;
  background-color: $color-neutral-200;
  border-width: 0;
  border-radius: 4px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: $color-neutral-300;
  }

  &.w--redirected-checked {
    background-color: $color-primary;
    background-image: url('/images/check-form-brix-templates-webflow.svg');
    background-size: auto;
    border-width: 0;
    border-radius: 4px;
    margin-top: 0;
    margin-left: 0;
  }

  &.w--redirected-focus {
    box-shadow: none;
    background-color: $color-neutral-300;
    border-width: 0;
    border-radius: 4px;
  }

  &.large {
    min-height: 28px;
    min-width: 28px;
  }

  &.small {
    min-height: 18px;
    min-width: 18px;
    border-radius: 5px;
  }
}

// Radio Button Field Wrapper
.radio-button-field-wrapper {
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;
  padding-left: 0;
  font-size: 18px;
  line-height: 24px;
  display: flex;

  &.large {
    font-size: 20px;
    line-height: 26px;
  }

  &.small {
    font-size: 16px;
    line-height: 22px;
  }
}

// Radio Button Styles
.radio-button {
  width: auto;
  height: auto;
  min-height: 20px;
  min-width: 20px;
  background-color: $color-neutral-200;
  border-width: 0;
  margin-top: 0;
  margin-left: 0;
  margin-right: 10px;
  transition: border-color 0.3s, border-width 0.3s, background-color 0.3;

  &:hover {
    background-color: $color-neutral-300;
  }

  &.w--redirected-checked {
    background-color: $color-neutral-300;
    border-width: 6px;
    border-color: $color-primary;
  }

  &.w--redirected-focus {
    box-shadow: none;
    background-color: $color-neutral-300;
  }

  &.large {
    min-height: 28px;
    min-width: 28px;

    &.w--redirected-checked {
      border-width: 8px;
    }
  }

  &.small {
    min-height: 18px;
    min-width: 18px;
  }
}

// Form Layouts
.log-in-form,
.forgot-password-form,
.reset-password-form {
  width: 100%;
  max-width: 426px;
  grid-column-gap: 16px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.sign-up-form-block {
  min-height: 510px;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  display: flex;
}

.sign-up-form {
  grid-column-gap: 33px;
  grid-row-gap: 40px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.request-demo-form-block {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  display: flex;
}

.request-demo-form {
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

// Checkbox & Radio Field
.checkbox-label {
  margin-bottom: 0;
  line-height: 1em;

  &.v2 {
    line-height: 1.556em;
  }
}

.checkbox-field {
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;

  &.v2 {
    align-items: flex-start;
  }
}

.checkbox-white {
  width: auto;
  height: auto;
  min-height: 20px;
  min-width: 20px;
  background-color: #fff;
  border-width: 0;
  border-radius: 4px;
  margin-top: 0;
  margin-left: 0;
  margin-right: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: $color-neutral-300;
  }

  &.w--redirected-checked {
    background-color: $color-primary;
    background-image: url('/images/check-form-brix-templates-webflow.svg');
    background-size: auto;
    border-width: 0;
    border-radius: 4px;
    margin-top: 0;
    margin-left: 0;
  }

  &.w--redirected-focus {
    box-shadow: none;
    background-color: $color-neutral-300;
    border-width: 0;
    border-radius: 4px;
  }
}

// Sign-up Form Styles
.sign-up-image-wrapper {
  min-height: 100%;
  background-color: $color-secondary-1;
  background-image: url('/images/bg-grid-techkit-template.svg');
  background-position: 50%;
  background-size: 124px;
  background-attachment: fixed;
  border-radius: 40px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin-right: -113%;
  padding: 118px 54px;
  display: flex;
  position: relative;
  overflow: hidden;
}

// Form Buttons and Selects
.select {
  height: 60px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  border: 0 solid #000;
  margin-bottom: 0;
  padding: 0;
  font-size: 18px;
}

.form-button-inside {
  position: relative;
}

.log-in-form .react-tel-input .form-control {
  border-radius: 8px;
  color: #28292b;
  font-size: 18px;
  width: 100% !important;
}

// Ant Design Select Overrides
.ant-select {
  .ant-select-selector {
    align-items: center;
    display: flex;
  }

  .ant-select-selection-item {
    line-height: normal;
  }

  .ant-select-arrow {
    right: 11px;
    top: 54%;
    color: rgba(0, 0, 0, 0.72);
  }

  // Only apply the larger styles to the organization selector
  &.organization-selector {
    .ant-select-selector {
      padding: 25px 23px;
      background-color: $color-neutral-200;
      border: 1px solid transparent;
      border-radius: 8px;
      font-size: 18px;

      &:hover {
        border-color: #c1cacf;
      }
    }
  }
}

.message-wrapper {
  grid-column: 1 / -1;
}

/* Full width textarea */
.text-area.full-width {
  width: 100%;
}