@import 'variables';
@import 'mixins';

.social-icon-font {
  font-family: Social Icons Font, sans-serif;
  line-height: 21px;

  &.button-left {
    margin-right: 6px;
  }
}

.social-icon-square {
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  background-color: $color-neutral-200;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  display: flex;

  &:hover {
    color: $color-neutral-100;
    background-color: $color-primary;
  }

  &.size-10px {
    width: 10px;
  }
}

.social-media-grid-top {
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: repeat(auto-fit, 36px);
  justify-content: flex-start;
  justify-items: flex-start;

  &.center {
    justify-content: center;
    justify-items: center;
  }

  &.footer-social-icon-links {
    width: 100%;
    grid-template-columns: repeat(auto-fit, 36px);
    justify-content: end;
  }
}

.social-icon-link-wrapper {
  width: 36px;
  height: 36px;
  min-height: 36px;
  min-width: 36px;
  color: $color-secondary-1;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-decoration: none;
  display: flex;
  overflow: hidden;

  &:hover {
    color: $color-primary;
  }

  &.accent {
    color: $color-primary;
    transition-property: opacity;

    &:hover {
      opacity: 0.7;
    }
  }

  &.white {
    color: $color-neutral-100;
    transition-property: opacity;

    &:hover {
      opacity: 0.7;
      color: $color-neutral-100;
    }

    &.accent {
      transition-property: color;

      &:hover {
        opacity: 1;
        color: $color-primary;
      }
    }
  }

  &.large {
    font-size: 24px;
  }
}

.section-card-social-media-wrapper {
  grid-column-gap: 45px;
  grid-row-gap: 45px;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.social-media-card-link-title {
  color: $color-neutral-100;
  margin-bottom: 0;
  font-size: 20px;
  line-height: 1.5em;
}