// Importing variables
@import 'variables';

// Hero Banner Container
.hero-banner-container {
  background-color: $neutral-400;
  border-radius: 15px;
  height: 500px;
  line-height: 0.9;
  padding: 100px 40px;
  position: relative;
  width: 100%;

  .beats-solo {
    font-size: 20px;
    margin-left: 5px;
  }

  button {
    background-color: $red-400;
    border: none;
    border-radius: 15px;
    color: $neutral-100;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    margin-top: 40px;
    padding: 10px 16px;
    z-index: 10000 !important;
  }

  h1 {
    color: $neutral-100;
    font-size: 8em;
    text-transform: uppercase;
  }

  h3 {
    font-size: 4rem;
    margin-top: 4px;
  }
}

// Hero Banner Image
.hero-banner-image {
  height: 450px;
  position: absolute;
  right: 20%;
  top: 0%;
  width: 450px;
}

// Description
.desc {
  bottom: 5%;
  color: $neutral-800;
  display: flex;
  flex-direction: column;
  line-height: 1.3;
  position: absolute;
  right: 10%;
  width: 300px;

  p {
    color: $neutral-700;
    font-weight: 100;
    text-align: end;
  }

  h5 {
    align-self: flex-end;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px;
  }
}

// Hero V2 Grid
.hero-v2-grid {
  align-items: center;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 28px;
  grid-template-columns: 1fr 0.7fr;
  grid-template-rows: auto;
  position: relative;
  z-index: 1;
}

// Hero V4 Images Above Wrapper 01
.hero-v4-images-above-wrapper---01 {
  display: flex;
  flex-direction: column;
  grid-row-gap: 79px;
}

// Hero V4 Images Above Wrapper 02
.hero-v4-images-above-wrapper---02 {
  display: flex;
  flex-direction: column;
  grid-row-gap: 89px;
}