@import 'variables';

.style---avatars-grid {
  grid-column-gap: 24px;
  grid-template-columns: repeat(auto-fit, 290px);
  grid-template-rows: auto;
}

.avatar-circle {
  border-radius: 50%;
  overflow: hidden;
  transform: translate(0);
  width: 100%;

  &._01 {
    height: 32px;
    max-height: 32px;
    max-width: 32px;
    width: 32px;
  }

  &._02 {
    height: 48px;
    max-height: 48px;
    max-width: 48px;
    width: 48px;
  }

  &._03 {
    height: 64px;
    max-height: 64px;
    max-width: 64px;
    width: 64px;
  }

  &._04 {
    height: 80px;
    max-height: 80px;
    max-width: 80px;
    width: 80px;
  }

  &._05 {
    height: 120px;
    max-height: 120px;
    max-width: 120px;
    width: 120px;
  }

  &._06 {
    height: 160px;
    max-height: 160px;
    max-width: 160px;
    width: 160px;
  }

  &._07 {
    height: 240px;
    max-height: 240px;
    max-width: 240px;
    width: 240px;
  }

  &._08 {
    height: 380px;
    max-height: 380px;
    max-width: 380px;
    width: 380px;
  }
}