* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  background-color: #fff;
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  min-height: 100%;
}

img {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
}

html.w-mod-touch * {
  background-attachment: scroll !important;
}

.w-block {
  display: block;
}

.w-inline-block {
  display: inline-block;
  max-width: 100%;
}

.w-clearfix:before,
.w-clearfix:after {
  content: " ";
  display: table;
  grid-column-end: 2;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-row-start: 1;
}

.w-clearfix:after {
  clear: both;
}

.w-hidden {
  display: none;
}

.w-button {
  background-color: #3898EC;
  border: 0;
  border-radius: 0;
  color: white;
  cursor: pointer;
  display: inline-block;
  line-height: inherit;
  padding: 9px 15px;
  text-decoration: none;
}

input.w-button {
  appearance: button;
  -webkit-appearance: button;
}

html[data-w-dynpage] [data-w-cloak] {
  color: transparent !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  margin-bottom: 10px;
}

h1 {
  font-size: 38px;
  line-height: 44px;
  margin-top: 20px;
}

h2 {
  font-size: 32px;
  line-height: 36px;
  margin-top: 20px;
}

h3 {
  font-size: 24px;
  line-height: 30px;
  margin-top: 20px;
}

h4 {
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
}

h5 {
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
}

h6 {
  font-size: 12px;
  line-height: 18px;
  margin-top: 10px;
}

p {
  margin-bottom: 10px;
  margin-top: 0;
}

blockquote {
  border-left: 5px solid #E2E2E2;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 10px 0;
  padding: 10px 20px;
}

figure {
  margin: 0;
  margin-bottom: 10px;
}

figcaption {
  margin-top: 5px;
  text-align: center;
}

ul,
ol {
  margin-bottom: 10px;
  margin-top: 0;
  padding-left: 40px;
}

.w-list-unstyled {
  list-style: none;
  padding-left: 0;
}

.w-embed:before,
.w-embed:after {
  content: " ";
  display: table;
  grid-column-end: 2;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-row-start: 1;
}

.w-embed:after {
  clear: both;
}

.w-video {
  padding: 0;
  position: relative;
  width: 100%;
}

.w-video iframe,
.w-video object,
.w-video embed {
  border: none;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

button,
[type='button'],
[type='reset'] {
  appearance: button;
  border: 0;
  cursor: pointer;
  -webkit-appearance: button;
}

.w-form {
  margin: 0 0 15px;
}

.w-form-done {
  background-color: #dddddd;
  display: none;
  padding: 20px;
  text-align: center;
}

.w-form-fail {
  background-color: #ffdede;
  display: none;
  margin-top: 10px;
  padding: 10px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.w-input,
.w-select {
  background-color: #ffffff;
  border: 1px solid #cccccc !important;
  color: #333333;
  display: block;
  font-size: 14px;
  height: 38px;
  line-height: 1.42857143;
  margin-bottom: 10px;
  padding: 8px 12px;
  vertical-align: middle;
  width: 100%;
}

.w-input:-moz-placeholder,
.w-select:-moz-placeholder {
  color: #999;
}

.w-input::-moz-placeholder,
.w-select::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.w-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
  color: #999;
}

.w-input:focus,
.w-select:focus {
  border-color: #3898EC;
  outline: 0;
}

.w-input[disabled],
.w-select[disabled],
.w-input[readonly],
.w-select[readonly],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
  cursor: not-allowed;
}

.w-input[disabled]:not(.w-input-disabled),
.w-select[disabled]:not(.w-input-disabled),
.w-input[readonly],
.w-select[readonly],
fieldset[disabled]:not(.w-input-disabled) .w-input,
fieldset[disabled]:not(.w-input-disabled) .w-select {
  background-color: #eeeeee;
}

textarea.w-input,
textarea.w-select {
  height: auto;
}

.w-select {
  background-color: #f3f3f3;
}

.w-select[multiple] {
  height: auto;
}

.w-form-label {
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  margin-bottom: 0;
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.w-container:before,
.w-container:after {
  content: " ";
  display: table;
  grid-column-end: 2;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-row-start: 1;
}

.w-container:after {
  clear: both;
}

.w-container .w-row {
  margin-left: -10px;
  margin-right: -10px;
}

.w-hidden-main {
  display: none !important;
}

@media screen and (max-width: 991px) {
  .w-container {
    max-width: 728px;
  }

  .w-hidden-main {
    display: inherit !important;
  }

  .w-hidden-medium {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {

  .w-row,
  .w-container .w-row {
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 479px) {
  .w-container {
    max-width: none;
  }
}

.w-slider-nav {
  height: 40px;
  margin: auto;
  padding-top: 10px;
  position: absolute;
  right: 0;
  text-align: center;
  top: auto;
  z-index: 2;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.w-slider-nav.w-round>div {
  border-radius: 100%;
}

.w-slider-nav.w-num>div {
  font-size: inherit;
  height: auto;
  line-height: inherit;
  padding: 0.2em 0.5em;
  width: auto;
}

.w-slider-nav.w-shadow>div {
  box-shadow: 0 0 3px rgba(51, 51, 51, 0.4);
}

.w-slider-nav-invert {
  color: #fff;
}

.w-slider-nav-invert>div {
  background-color: rgba(34, 34, 34, 0.4);
}

.w-slider-nav-invert>div.w-active {
  background-color: #222;
}

.w-slider-dot {
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  display: inline-block;
  height: 1em;
  margin: 0 3px 0.5em;
  position: relative;
  transition: background-color 100ms, color 100ms;
  width: 1em;
}

.w-slider-dot.w-active {
  background-color: #fff;
}

.w-slider-dot:focus {
  box-shadow: 0px 0px 0px 2px #fff;
  outline: none;
}

.w-slider-dot:focus.w-active {
  box-shadow: none;
}

.w-slider-arrow-left,
.w-slider-arrow-right {
  color: white;
  cursor: pointer;
  font-size: 40px;
  height: 80px;
  left: 0;
  margin: auto;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
  z-index: 2;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.w-slider-arrow-left [class^='w-icon-'],
.w-slider-arrow-right [class^='w-icon-'],
.w-slider-arrow-left [class*=' w-icon-'],
.w-slider-arrow-right [class*=' w-icon-'] {
  position: absolute;
}

.w-slider-arrow-left:focus,
.w-slider-arrow-right:focus {
  outline: 0;
}

.w-slider-arrow-left {
  right: auto;
  z-index: 3;
}

.w-slider-arrow-right {
  left: auto;
  z-index: 4;
}

.w-icon-slider-left,
.w-icon-slider-right {
  height: 1em;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 1em;
}

.w-slider-aria-label {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.w-slider-force-show {
  display: block !important;
}

.w-dropdown {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  text-align: left;
  z-index: 99;
}

.w-dropdown-btn,
.w-dropdown-toggle,
.w-dropdown-link {
  color: #222222;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  position: relative;
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
}

.w-dropdown-toggle {
  cursor: pointer;
  display: inline-block;
  padding-right: 40px;
  position: relative;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.w-dropdown-toggle:focus {
  outline: 0;
}

.w-icon-dropdown-toggle {
  height: 1em;
  margin-right: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 1em;
}

.w-dropdown-list {
  background: #dddddd;
  display: none;
  min-width: 100%;
  position: absolute;
}

.w-dropdown-list.w--open {
  display: block;
}

.w-dropdown-link {
  color: #222222;
  display: block;
  padding: 10px 20px;
}

.w-dropdown-link.w--current {
  color: #0082f3;
}

.w-dropdown-link:focus {
  outline: 0;
}

@media screen and (max-width: 767px) {
  .w-nav-brand {
    padding-left: 10px;
  }
}

.w-nav {
  background: #dddddd;
  position: relative;
  z-index: 1000;
}

.w-nav:before,
.w-nav:after {
  content: " ";
  display: table;
  grid-column-end: 2;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-row-start: 1;
}

.w-nav:after {
  clear: both;
}

.w-nav-brand {
  color: #333333;
  float: left;
  position: relative;
  text-decoration: none;
}

.w-nav-link {
  color: #222222;
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  position: relative;
  text-align: left;
  text-decoration: none;
  vertical-align: top;
}

.w-nav-link.w--current {
  color: #0082f3;
}

.w-nav-menu {
  float: right;
  position: relative;
}

[data-nav-menu-open] {
  background: #C8C8C8;
  display: block !important;
  left: 0;
  margin: auto;
  min-width: 200px;
  overflow: visible;
  position: absolute;
  right: 0;
  text-align: center;
  top: 100%;
}

.w--nav-link-open {
  display: block;
  position: relative;
}

.w-nav-overlay {
  display: none;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
  width: 100%;
}

.w-nav-overlay [data-nav-menu-open] {
  top: 0;
}

.w-nav[data-animation="over-left"] .w-nav-overlay {
  width: auto;
}

.w-nav[data-animation="over-left"] .w-nav-overlay,
.w-nav[data-animation="over-left"] [data-nav-menu-open] {
  right: auto;
  top: 0;
  z-index: 1;
}

.w-nav[data-animation="over-right"] .w-nav-overlay {
  width: auto;
}

.w-nav[data-animation="over-right"] .w-nav-overlay,
.w-nav[data-animation="over-right"] [data-nav-menu-open] {
  left: auto;
  top: 0;
  z-index: 1;
}

.w-nav-button {
  cursor: pointer;
  float: right;
  font-size: 24px;
  margin: auto;
  padding: 18px;
  position: relative;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  user-select: none;
}

.w-nav-button:focus {
  outline: 0;
}

.w-nav-button.w--open {
  background-color: #C8C8C8;
  color: white;
}

.w-nav[data-collapse="all"] .w-nav-menu {
  display: none;
}

.w-nav[data-collapse="all"] .w-nav-button {
  display: block;
}

.w--nav-dropdown-open {
  display: block;
}

.w--nav-dropdown-toggle-open {
  display: block;
}

.w--nav-dropdown-list-open {
  position: static;
}

@media screen and (max-width: 991px) {
  .w-nav[data-collapse="medium"] .w-nav-menu {
    display: none;
  }

  .w-nav[data-collapse="medium"] .w-nav-button {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .w-nav[data-collapse="small"] .w-nav-menu {
    display: none;
  }

  .w-nav[data-collapse="small"] .w-nav-button {
    display: block;
  }

  .w-nav-brand {
    padding-left: 10px;
  }
}

@media screen and (max-width: 479px) {
  .w-nav[data-collapse="tiny"] .w-nav-menu {
    display: none;
  }

  .w-nav[data-collapse="tiny"] .w-nav-button {
    display: block;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
