.badge-primary {
  color: #fff;
  background-color: $color-primary;
  border-radius: 8px;
  padding: 18px 28px;
  font-size: 18px;
  line-height: 1.111em;
  display: inline-block;

  &.white {
    color: $color-primary;
    background-color: #fff;
  }

  &.light {
    color: $color-primary;
    background-color: $color-neutral-200;
  }

  &.small {
    padding: 14px 22px;
    font-size: 16px;
    line-height: 1.125em;
  }

  &.large {
    padding: 22px 38px;
    font-size: 20px;
    line-height: 1.1em;
  }
}

.badge-secondary {
  color: #fff;
  text-align: center;
  background-color: $color-neutral-800;
  border: 1px solid $color-neutral-800;
  border-radius: 8px;
  padding: 18px 28px;
  font-size: 18px;
  line-height: 1.111em;
  text-decoration: none;
  display: inline-block;
  box-shadow: 0 2px 12px rgba(20, 20, 43, 0.06);

  &.white {
    box-shadow: none;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border-color: #fff;
  }

  &.light {
    box-shadow: none;
    color: $color-secondary-1;
    background-color: $color-neutral-200;
    border-width: 0;

    &.tab-btn {
      transition: background-color .3s, color .3s;

      &:hover {
        color: #fff;
        background-color: $color-primary;
      }

      &.w--current {
        color: #fff;
        background-color: $color-primary;
        font-weight: 500;
      }
    }
  }

  &.small {
    padding: 14px 22px;
    font-size: 16px;
    line-height: 1.125em;
  }

  &.large {
    padding: 22px 38px;
    font-size: 20px;
    line-height: 1.1em;
  }

  &.category-item {
    transition: background-color .3s, color .3s;

    &:hover {
      color: #fff;
      background-color: $color-primary;
    }

    &.w--current {
      background-color: $color-primary;
      border-color: $color-primary;
    }
  }
}