@import 'variables';

// General layout styles
.w-layout-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
}

.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;
}

.container-default {
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;

  &.order-confirmation {
    display: block;
  }
}

.container-wide {
  max-width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}

// Row and column styles
.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
}

.col {
  flex: 1;
  padding-left: 15px;
  padding-right: 15px;
}

// Header small logo link
.header-small-logo-link {
  margin-left: 24px;
  margin-right: 0;
  max-width: 280px;
}

// Grid styles
.grid-1-column {
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.grid-2-columns,
.grid-3-columns,
.grid-4-columns {
  align-items: flex-start;
  display: grid;
  grid-auto-columns: 1fr;
  grid-row-gap: 28px;
  grid-template-rows: auto;
}

.grid-2-columns {
  grid-column-gap: 28px;
  grid-template-columns: 1fr 1fr;

  &.style---styleguide-wrapper {
    align-items: flex-start;
    grid-column-gap: 0px;
    grid-template-columns: .25fr 1fr;
  }

  &.contact-v1-grid,
  &.logo-strip-grid,
  &.title-and-paragraph,
  &.text-left-default,
  &.text-right-default,
  &.template-page-sidebar,
  &.changelog-item,
  &.product-page,
  &.checkout-page,
  &.pricing-tablet-mb,
  &.section-features-grid,
  &.title-and-buttons,
  &.blog-featured-main-grid,
  &.cta-v5,
  &.offices-grid,
  &.hero-v5-grid,
  &.blog-sidebar-grid,
  &.post-body-grid,
  &.about-position-grid,
  &.accordions-grid,
  &.integrations-grid,
  &.integrations-hero-grid,
  &.contact-v2-grid,
  &.office-v2,
  &.card-contact-links-v2-grid,
  &.contact-v3-grid,
  &.request-demo-grid,
  &.request-demo-grid-v2,
  &.sign-up-grid,
  &.help-center-post-body-grid,
  &.team-member-grid {
    grid-column-gap: 40px;
  }

  &.text-right-default.v2 {
    grid-template-columns: 1fr .95fr;
  }

  &.logo-strip-grid {
    grid-template-columns: .8fr .8fr;
  }

  &.title-and-paragraph {
    align-items: end;
    grid-template-columns: 1.1fr 1fr;
    margin-bottom: 56px;
  }

  &.changelog-item {
    align-items: flex-start;
    border-bottom: 1px solid $neutral-300;
    padding-bottom: 64px;
    padding-top: 64px;

    &.first {
      border-bottom-width: 0;
      padding-bottom: 0;
      padding-top: 0;
    }
  }

  &.blog-featured-main-grid {
    align-items: stretch;
    grid-template-columns: 1fr .78fr;
  }

  &.blog-sidebar-grid,
  &.accordions-grid {
    align-items: flex-start;
  }

  &.team-member-grid,
  &.help-center-post-body-grid {
    align-items: flex-start;
    position: relative;
    z-index: 1;
  }
}

.grid-3-columns {
  grid-column-gap: 28px;
  grid-template-columns: 1fr 1fr 1fr;

  &.gap-16px {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
  }

  &.style---buttons-grid {
    grid-column-gap: 70px;
    grid-template-columns: auto auto 1fr;
  }

  &.logo-strip-v4 {
    align-items: center;
    grid-column-gap: 40px;
    grid-row-gap: 50px;
    grid-template-columns: auto auto auto;
    opacity: .45;
  }

  &.team-grid,
  &.blog,
  &.section-blog,
  &.blog-v2,
  &.blog-v3 {
    grid-row-gap: 30px;
  }

  &.blog-v2,
  &.blog-v3 {
    grid-column-gap: 32px;
    grid-template-columns: 1fr 1fr;
  }
}

.grid-4-columns {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

// Section padding adjustments
.section {
  padding-top: 200px;
  padding-bottom: 200px;

  &.hero {
    overflow: hidden;
    position: relative;

    &.v1 {
      background-image: url('/images/bg-grid-techkit-template.svg');
      background-position: 50% 0;
      background-size: auto;
      background-attachment: fixed;
      padding-top: 218px;
      padding-bottom: 0;
    }

    &.v2 {
      background-color: #171717;
      background-image: url('/images/bg-grid-techkit-template.svg');
      background-position: 50% 0;
      background-size: auto;
      background-attachment: fixed;
      padding-top: 227px;
      padding-bottom: 109px;
    }

    &.v3 {
      padding-top: 218px;
      padding-bottom: 0;
    }

    &.v4 {
      background-color: #fff;
      padding-top: 219px;
      padding-bottom: 266px;
    }

    &.v5 {
      padding-top: 220px;
      padding-bottom: 0;
    }

    &.v6 {
      background-color: #fff;
      padding-top: 219px;
      padding-bottom: 180px;
    }

    &.v7 {
      padding-top: 219px;
      padding-bottom: 152px;
    }

    &.v8 {
      background-image: url('/images/bg-grid-techkit-template.svg');
      background-position: 50% 0;
      background-size: auto;
      background-attachment: fixed;
      padding-top: 200px;
      padding-bottom: 116px;
    }

    &.v9 {
      background-image: url('/images/bg-grid-techkit-template.svg');
      background-position: 50% 0;
      background-size: auto;
      background-attachment: fixed;
      padding-top: 219px;
      padding-bottom: 142px;
    }

    &.v10 {
      padding-top: 260px;
      padding-bottom: 0;
    }

    &.v11 {
      padding-top: 180px;
      padding-bottom: 93px;
    }

    &.v12 {
      padding-top: 217px;
      padding-bottom: 140px;
    }

    &.v13 {
      padding-top: 219px;
      padding-bottom: 240px;
    }

    &.v14 {
      padding-top: 120px;
      padding-bottom: 0;
    }

    &.v15 {
      background-image: url('/images/bg-grid-techkit-template.svg');
      background-position: 50%;
      background-size: auto;
      padding-top: 192px;
      padding-bottom: 248px;
    }

    &.team-page {
      padding-top: 217px;
      padding-bottom: 140px;
      overflow: visible;
    }

    &.template-pages {
      background-color: #171717;
      background-image: url('/images/bg-grid-techkit-template.svg');
      background-position: 50%;
      background-size: auto;
      background-attachment: fixed;
      padding-top: 240px;
      padding-bottom: 200px;
    }
  }

  &.pd-100px {
    padding-top: 83px;
    padding-bottom: 83px;
  }

  &.pd-188px {
    padding-top: 158px;
    padding-bottom: 158px;
  }

  &.pd-0px {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.pd-top-0px {
    padding-top: 0;
  }

  &.pd-bottom-53px {
    padding-bottom: 53px;
  }

  &.pd-bottom-53px.pd-top-0px.section-video {
    position: relative;
  }

  &.light {
    background-color: #f6f9fa;
  }

  &.cta-v3 {
    background-color: #247bfe;
    background-image: url('/images/bg-grid-cta-v3-techkit-template.svg');
    background-position: 0 0;
    background-size: auto;
    padding-top: 115px;
    padding-bottom: 115px;
    position: relative;
  }

  &.pd-290px {
    padding-top: 240px;
    padding-bottom: 240px;
  }

  &.pd-290px.pd-top-0px {
    padding-top: 0;
  }

  &.pd-120px {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &.pd-162px {
    padding-top: 135px;
    padding-bottom: 135px;
  }

  &.pd-bottom-0px {
    padding-bottom: 0;
  }

  &.pd-140px {
    padding-top: 118px;
    padding-bottom: 118px;
  }

  &.pd-140px.pd-top-0px {
    padding-top: 0;
  }

  &.pd-top-180px {
    padding-top: 150px;
  }

  &.pd-top-140px {
    padding-top: 118px;
  }

  &.pd-top-323px {
    padding-top: 270px;
  }

  &.pd-153px {
    padding-top: 128px;
    padding-bottom: 128px;
  }

  &.utility-page {
    background-image: url('/images/bg-grid-techkit-template.svg');
    background-position: 50%;
    background-size: auto;
    background-attachment: fixed;
    flex: 1;
    align-items: flex-end;
    margin-top: -114px;
    padding-top: 170px;
    padding-bottom: 0;
    display: flex;
    position: relative;
  }

  &.sign-up {
    padding-top: 60px;
    padding-bottom: 113px;
    overflow: hidden;
  }

  &.pd-130px {
    padding-top: 108px;
    padding-bottom: 108px;
  }

  &.pd-130px.pd-top-0px {
    padding-top: 0;
  }

  &.not-found {
    background-image: url('/images/bg-grid-techkit-template.svg');
    background-position: 50%;
    background-size: auto;
    background-attachment: fixed;
    border-bottom: 1px solid #656769;
    padding-top: 274px;
    padding-bottom: 202px;
    position: relative;
    overflow: hidden;
  }

  &.protected-page {
    background-image: url('/images/bg-grid-techkit-template.svg');
    background-position: 50%;
    background-size: auto;
    background-attachment: fixed;
    border-bottom: 1px solid #656769;
    padding-top: 288px;
    padding-bottom: 0;
    position: relative;
    overflow: hidden;
  }

  &.coming-soon {
    background-image: url('/images/bg-grid-techkit-template.svg');
    background-position: 50%;
    background-size: auto;
    background-attachment: fixed;
    border-bottom: 1px solid #656769;
    padding-top: 219px;
    padding-bottom: 240px;
    position: relative;
    overflow: hidden;
  }

  &.blue-callout {
    margin: 56px 0;

    .svg-logo-container {
      position: absolute;
      top: 48px;
      right: 10%;
      z-index: 1;

      .svg-logo {
        width: 500px;
        height: auto;
      }
    }
  }
}


// Utility classes
.display-1,
.display-2,
.display-3,
.display-4 {
  color: $neutral-800;
  font-weight: 700;
  margin-left: -2px;

  &.color-neutral-100 {
    color: $neutral-100;
  }
}

.display-1 {
  font-size: 72px;
  line-height: 1.167em;
}

.display-2 {
  font-size: 48px;
  line-height: 1.292em;
}

.display-3 {
  font-size: 28px;
  line-height: 1.357em;
}

.display-4 {
  font-size: 24px;
  line-height: 1.357em;
}

:where(.css-dev-only-do-not-override-1k979oh).ant-row-space-between {
  justify-content: space-around;
}

// Inner container classes
.inner-container {
  &.center {
    margin-left: auto;
    margin-right: auto;
  }

  &.card-integration-strip-image,
  &.card-testimonial-slider-logo,
  &.card-press-slider-logo,
  &.card-open-position-icon-category,
  &.card-open-position-arrow,
  &.blog-categories-sidebar,
  &.integrations-hero-01,
  &.integrations-hero-02,
  &.integrations-hero-03,
  &.integrations-hero-04 {
    display: flex;
  }

  &.card-integration-strip-image,
  &.card-testimonial-slider-logo,
  &.card-press-slider-logo {
    align-items: center;
    max-height: 48px;
    min-height: 48px;
  }

  &.card-open-position-icon-category {
    align-items: flex-start;
  }

  &.blog-categories-sidebar {
    position: -webkit-sticky;
    position: sticky;
    top: 120px;
  }

  &._100px {
    max-width: 100px;
  }

  &._103px {
    max-width: 103px;
  }

  &._112px {
    max-width: 112px;
  }

  &._126px {
    max-width: 126px;
  }

  &._128px {
    max-width: 128px;
  }

  &._138px {
    max-width: 138px;
  }

  &._150px {
    max-width: 150px;
  }

  &._17px {
    max-width: 17px;
  }

  &._19px {
    max-width: 19px;
  }

  &._20px {
    max-width: 20px;
  }

  &._22 {
    max-width: 22%;
  }

  &._28px {
    max-width: 28px;
  }

  &._29 {
    max-width: 29%;
  }

  &._29px {
    max-width: 29px;
  }

  &._39px {
    max-width: 39px;
  }

  &._44px {
    max-width: 44px;
  }

  &._46px {
    max-width: 46px;
  }

  &._50px {
    max-width: 50px;
  }

  &._52 {
    max-width: 52%;
  }

  &._52px {
    max-width: 52px;
  }

  &._53 {
    max-width: 53%;
  }

  &._57 {
    max-width: 57%;
  }

  &._58 {
    max-width: 58%;
  }

  &._61 {
    max-width: 61%;
  }

  &._61px {
    max-width: 61px;
  }

  &._64px {
    max-width: 64px;
  }

  &._68 {
    max-width: 68%;
  }

  &._70px {
    max-width: 70px;
  }

  &._72 {
    max-width: 72%;
  }

  &._74px {
    max-width: 74px;
  }

  &._75px {
    max-width: 75px;
  }

  &._76px {
    max-width: 76px;
  }

  &._77 {
    max-width: 77%;
  }

  &._80 {
    max-width: 80%;
  }

  &._80px {
    max-width: 80px;
  }

  &._85 {
    max-width: 85%;
  }

  &._87 {
    max-width: 87%;
  }

  &._92px {
    max-width: 92px;
  }

  &._100px {
    max-width: 100px;
  }

  &._112px {
    max-width: 112px;
  }

  &._128px {
    max-width: 128px;
  }

  &._138px {
    max-width: 138px;
  }

  &._150px {
    max-width: 150px;
  }

  &._250px {
    max-width: 250px;
  }

  &._249px {
    max-width: 249px;
  }

  &._307px {
    max-width: 307px;
  }

  &._322px {
    max-width: 322px;
  }

  &._351px {
    max-width: 351px;
  }

  &._365px {
    max-width: 365px;
  }

  &._370px {
    max-width: 370px;
  }

  &._390px {
    max-width: 390px;
  }

  &._405px {
    max-width: 405px;
  }

  &._414px {
    max-width: 414px;
  }

  &._415px {
    max-width: 415px;
  }

  &._416px {
    max-width: 416px;
  }

  &._424px {
    max-width: 424px;
  }

  &._436px {
    max-width: 436px;
  }

  &._460px {
    max-width: 460px;
  }

  &._467px {
    max-width: 467px;
  }

  &._469px {
    max-width: 469px;
  }

  &._473px {
    max-width: 473px;
  }

  &._474px {
    max-width: 474px;
  }

  &._484px {
    max-width: 484px;
  }

  &._485px {
    max-width: 485px;
  }

  &._490px {
    max-width: 490px;
  }

  &._500px {
    max-width: 500px;
  }

  &._509px {
    max-width: 509px;
  }

  &._518px {
    max-width: 518px;
  }

  &._519px {
    max-width: 519px;
  }

  &._525px {
    max-width: 525px;
  }

  &._541px {
    max-width: 541px;
  }

  &._548px {
    max-width: 548px;
  }

  &._554px {
    max-width: 554px;
  }

  &._557px {
    max-width: 557px;
  }

  &._564px {
    max-width: 564px;
  }

  &._570px {
    max-width: 570px;
  }

  &._571px {
    max-width: 571px;
  }

  &._576px {
    max-width: 576px;
  }

  &._581px {
    max-width: 581px;
  }

  &._589px {
    max-width: 589px;
  }

  &._598px {
    max-width: 598px;
  }

  &._600px {
    max-width: 600px;
  }

  &._604px {
    max-width: 604px;
  }

  &._605px {
    max-width: 605px;
  }

  &._609px {
    max-width: 609px;
  }

  &._612px {
    max-width: 612px;
  }

  &._622px {
    max-width: 622px;
  }

  &._624px {
    max-width: 624px;
  }

  &._636px {
    max-width: 636px;
  }

  &._640px {
    max-width: 640px;
  }

  &._645px {
    max-width: 645px;
  }

  &._650px {
    max-width: 650px;
  }

  &._662px {
    max-width: 662px;
  }

  &._676px {
    max-width: 676px;
  }

  &._680px {
    max-width: 680px;
  }

  &._691px {
    max-width: 691px;
  }

  &._695px {
    max-width: 695px;
  }

  &._700px {
    max-width: 700px;
  }

  &._710px {
    max-width: 710px;
  }

  &._712px {
    max-width: 712px;
  }

  &._719px {
    max-width: 719px;
  }

  &._740px {
    max-width: 740px;
  }

  &._745px {
    max-width: 745px;
  }

  &._748px {
    max-width: 748px;
  }

  &._765px {
    max-width: 765px;
  }

  &._773px {
    max-width: 773px;
  }

  &._807px {
    max-width: 807px;
  }

  &._813px {
    max-width: 813px;
  }

  &._845px {
    max-width: 845px;
  }

  &._861px {
    max-width: 861px;
  }

  &._877px {
    max-width: 877px;
  }

  &._900px {
    max-width: 900px;
  }

  &._989px {
    max-width: 989px;
  }

  &.w-100 {
    width: 100%;
  }
}

// Additional layout-specific styles
.hero {
  &.v1 {
    padding-top: 0;
  }

  &.v2 {
    padding-top: 72px;
  }
}

.products {
  &.section {
    &.hero {
      &.v9 {
        padding-top: 82px;
        padding-bottom: 82px;
      }
    }
  }
}

.account {
  &.section {
    &.hero {
      &.v9 {
        padding-top: 0;
        padding-bottom: 192px;
      }
    }
  }
}

// Flex utilities
.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-horizontal {
  display: flex;

  &.children-wrap {
    flex-wrap: wrap;
    margin-top: 20px;

    &.order-summary {
      grid-column-gap: 20px;
    }
  }

  &.align-center {
    align-items: center;
  }

  &.product-page-title {
    flex-wrap: wrap;
    grid-column-gap: 18px;
  }
}

// Misc utilities
.shadow-01 {
  box-shadow: 0 2px 6px rgba(20, 20, 43, 0.06);
}

.shadow-02 {
  box-shadow: 0 2px 12px rgba(20, 20, 43, 0.08);
}

.shadow-03 {
  box-shadow: 0 8px 28px rgba(20, 20, 43, 0.1);
}

.shadow-04 {
  box-shadow: 0 14px 42px rgba(20, 20, 43, 0.14);
}

.shadow-05 {
  box-shadow: 0 24px 65px rgba(20, 20, 43, 0.16);
}

.shadow-06 {
  box-shadow: 0 32px 72px rgba(20, 20, 43, 0.24);
}

.button-shadow-color-01 {
  box-shadow: 0 4px 10px rgba(74, 58, 255, 0.06);
}

.button-shadow-color-02 {
  box-shadow: 0 6px 20px rgba(74, 58, 255, 0.08);
}

.button-shadow-color-03 {
  box-shadow: 0 10px 28px rgba(74, 58, 255, 0.08);
}

.button-shadow-white-01 {
  box-shadow: 0 4px 10px rgba(20, 20, 43, 0.04);
}

.button-shadow-white-02 {
  box-shadow: 0 6px 20px rgba(20, 20, 43, 0.06);
}

.button-shadow-white-03 {
  box-shadow: 0 10px 28px rgba(20, 20, 43, 0.1);
}