@import 'variables';

// Mixins
@mixin margin-bottom($size) {
  margin-bottom: $size;
}

@mixin margin-top($size) {
  margin-top: $size;
}

@mixin margin-right($size) {
  margin-right: $size;
}

@mixin text-style($line-height, $font-weight) {
  line-height: $line-height;

  &.medium {
    font-weight: 500;
  }

  &.bold {
    font-weight: 700;
  }
}

// Margins
.mg-bottom-0 {
  @include margin-bottom(0);
}

.mg-bottom-4px {
  @include margin-bottom(4px);
}

.mg-bottom-7px {
  @include margin-bottom(7px);
}

.mg-bottom-8px {
  @include margin-bottom(8px);
}

.mg-bottom-12px {
  @include margin-bottom(12px);
}

.mg-bottom-16px {
  @include margin-bottom(16px);
}

.mg-bottom-20px {
  @include margin-bottom(20px);
}

.mg-bottom-24px {
  @include margin-bottom(24px);
}

.mg-bottom-28px {
  @include margin-bottom(28px);
}

.mg-bottom-32px {
  @include margin-bottom(32px);
}

.mg-bottom-34px {
  @include margin-bottom(34px);
}

.mg-bottom-38px {
  @include margin-bottom(38px);
}

.mg-bottom-40px {
  @include margin-bottom(40px);
}

.mg-bottom-45px {
  @include margin-bottom(45px);
}

.mg-bottom-48px {
  @include margin-bottom(48px);
}

.mg-bottom-56px {
  @include margin-bottom(56px);
}

.mg-bottom-64px {
  @include margin-bottom(64px);
}

.mg-bottom-72px {
  @include margin-bottom(72px);
}

.mg-bottom-78px {
  @include margin-bottom(78px);
}

.mg-bottom-80px {
  @include margin-bottom(80px);
}

.mg-bottom-93px {
  @include margin-bottom(93px);
}

.mg-bottom-100px {
  @include margin-bottom(100px);
}

.mg-bottom-200px {
  @include margin-bottom(200px);
}

.mg-top-5px {
  @include margin-top(5px);
}

.mg-top-8px {
  @include margin-top(8px);
}

.mg-top-10px {
  @include margin-top(10px);
}

.mg-top-16px {
  @include margin-top(16px);
}

.mg-top-24px {
  @include margin-top(24px);
}

.mg-top-32px {
  @include margin-top(32px);
}

.mg-top-40px {
  @include margin-top(40px);
}

.mg-top-48px {
  @include margin-top(48px);
}

.mg-top-64px {
  @include margin-top(64px);
}

.mg-top-80px {
  @include margin-top(80px);
}

.mg-top--40px {
  margin-top: -40px;
}

.mg-top---88px {
  margin-top: -88px;
}

.mg-top---118px {
  margin-top: -118px;
}

.mg-top-auto {
  margin-top: auto;
}

.mg-right-4px {
  @include margin-right(4px);
}

.mg-right-8px {
  @include margin-right(8px);
}

.mg-left-auto {
  margin-left: auto;
}

// Text styles
.text-200 {
  @include text-style(1.111em, 400);
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-no-wrap {
  white-space: nowrap;
}

.text-underline {
  text-decoration: underline;
}

// Border radius
.border-radius-8px {
  border-radius: 8px;
}

.border-radius-12px {
  border-radius: 12px;
}

.border-radius-16px {
  border-radius: 16px;
}

.border-radius-18px {
  border-radius: 18px;
}

.border-radius-18px---top {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
}

.border-radius-24px {
  border-radius: 24px;
  transform: translate(0);
}

.border-radius-40px {
  border-radius: 40px;
}

.border-radius-40px---bottom {
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.border-radius-40px---top {
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

// Backgrounds
.bg-grid-white {
  background-attachment: fixed;
  background-image: url('/images/bg-white-grid-techkit-template.svg');
  background-position: 50% 0;
  background-size: auto;
}

// Positioning
.position-relative {
  position: relative;

  &.bg-grid-white {
    &.section-features {
      margin-bottom: -53px;
      margin-left: -53px;
      margin-top: -53px;
      padding-left: 53px;
    }

    &.small {
      background-size: 86px;

      &.integrations-hero {
        height: 100%;
        padding-bottom: 86px;
        padding-top: 46px;
        padding-left: 46px;
      }
    }
  }

  &.z-index-2 {
    z-index: 2;
  }

  &.hero-v2-images {
    padding-bottom: 103px;
    padding-left: 63px;
  }

  &.sign-up {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-left: 84px;
  }
}

.position-absolute {
  position: absolute;

  &.full {
    bottom: 0%;
    left: 0%;
    right: 0%;
    top: 0%;

    &.header-bg {
      background-color: $neutral-100;
      max-width: 100vw;
      width: 100%;
      z-index: -1;

      &.dark {
        background-color: $secondary-1;
      }
    }
  }

  &.cta-v1-bg {
    background-color: $accent-1;
    bottom: 0%;
    left: 0%;
    right: auto;
    top: 0%;
    width: 50%;
    z-index: 1;
  }

  &.cta-v2-bg {
    background-color: $accent-1;
    bottom: 0%;
    left: auto;
    right: 0%;
    top: 0%;
    width: 50%;
  }

  &.dark-fade---button {
    background-image: linear-gradient(rgba($secondary-1, 0), $secondary-1 50%);
    bottom: 0%;
    height: 50%;
    left: 0%;
    right: 0%;
    top: auto;

    &.pricing-fade {
      height: 70%;
    }

    &.utility-page-fade {
      height: 30%;
    }

    &.not-found {
      background-image: linear-gradient(rgba($secondary-1, 0), $secondary-1);
      height: 10%;
    }

    &.protected-page {
      background-image: linear-gradient(rgba($secondary-1, 0), $secondary-1);
      height: 25%;
    }

    &.coming-soon {
      background-image: linear-gradient(rgba($secondary-1, 0), $secondary-1);
      height: 15%;
    }
  }

  &.white-fade {
    background-image: radial-gradient(circle, rgba($neutral-100, 0), $neutral-100 90%);
    bottom: 0%;
    left: 0%;
    right: 0%;
    top: 0%;
  }

  &.card-bottom-bg---top {
    background-color: $neutral-100;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    bottom: auto;
    left: 0%;
    min-height: 50%;
    right: 0%;
    top: 0%;

    &.hero-v5 {
      min-height: 80%;
    }
  }

  &.section-card-top-bg {
    background-color: $neutral-100;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    bottom: auto;
    left: 0%;
    margin-top: -300px;
    min-height: 305px;
    right: 0%;
    top: 0%;
  }

  &.cta-grid-wrapper {
    background-attachment: scroll;
    // background-image: url('/images/bg-grid-cta-techkit-template.svg');
    background-position: 50%;
    background-repeat: repeat;
    background-size: auto;
    bottom: 0%;
    left: auto;
    right: 0%;
    top: 0%;
    width: 600px;
  }

  &.cta-grid-fade {
    background-image: linear-gradient(to right, $accent-1, rgba($accent-1, 0));
    bottom: 0%;
    left: 0%;
    right: auto;
    top: 0%;
    width: 130px;
  }

  &.cta-v2-grid-wrapper {
    background-attachment: scroll;
    // background-image: url('/images/bg-grid-cta-v2-techkit-template.svg');
    background-position: 50%;
    background-repeat: repeat;
    background-size: auto;
    bottom: 0%;
    left: auto;
    right: 0%;
    top: 0%;
    width: 48%;
    z-index: 1;
  }

  &.cta-v2-grid-fade {
    background-image: linear-gradient(to right, $accent-1, rgba($accent-1, 0));
    bottom: 0%;
    left: 0%;
    right: auto;
    top: 0%;
    width: 148px;
  }

  &.cta-v3-grid-fade---01 {
    background-image: linear-gradient(to right, $accent-1, rgba($accent-1, 0));
    bottom: 0%;
    left: 0%;
    right: auto;
    top: 0%;
    width: 158px;
  }

  &.cta-v3-grid-fade---02 {
    background-image: linear-gradient(270deg, $accent-1, rgba($accent-1, 0));
    bottom: 0%;
    left: auto;
    right: 0%;
    top: 0%;
    width: 158px;
  }

  &.cta-v4-grid-wrapper {
    background-attachment: scroll;
    background-image: url('/images/bg-grid-cta-v4-techkit-template.svg');
    background-position: 50%;
    background-repeat: repeat;
    background-size: auto;
    bottom: 0%;
    left: auto;
    right: 0%;
    top: 0%;
    width: 47%;
  }

  &.cta-v4-grid-fade {
    background-image: linear-gradient(to right, $neutral-800, rgba($neutral-800, 0));
    bottom: 0%;
    left: 0%;
    right: auto;
    top: 0%;
    width: 148px;
  }

  &.cta-v5-grid-wrapper {
    background-attachment: scroll;
    background-image: url('/images/bg-grid-cta-techkit-template.svg');
    background-position: 50%;
    background-repeat: repeat;
    background-size: auto;
    bottom: 0%;
    left: auto;
    right: 0%;
    top: 0%;
    width: 43%;
  }

  &.cta-v6-grid-fade {
    background-image: linear-gradient(to right, $accent-1, rgba($accent-1, 0));
    bottom: 0%;
    left: 0%;
    right: auto;
    top: 0%;
    width: 148px;
  }

  &.cta-v6-grid-wrapper {
    background-attachment: scroll;
    background-image: url('/images/bg-grid-cta-v6-techkit-template.svg');
    background-position: 50%;
    background-repeat: repeat;
    background-size: auto;
    bottom: 0%;
    left: auto;
    right: 0%;
    top: 0%;
    width: 47%;
  }

  &.hero-v1-image-above---01 {
    bottom: 0%;
    left: 0%;
    margin-bottom: 9.5%;
    max-width: 21%;
    right: auto;
    top: auto;
  }

  &.hero-v1-image-above---02 {
    bottom: 0%;
    left: auto;
    margin-bottom: 11.5%;
    max-width: 23%;
    right: 0%;
    top: auto;
  }

  &.hero-v2-image-above---01 {
    bottom: 0%;
    left: 0%;
    margin-bottom: 40%;
    max-width: 54%;
    right: auto;
    top: auto;
  }

  &.hero-v2-image-above---02 {
    bottom: 0%;
    left: auto;
    margin-right: -24%;
    max-width: 52%;
    right: 0%;
    top: auto;
  }

  &.feature-grid-v2-image-above---01 {
    max-width: 40%;
    margin-bottom: 20.5%;
    top: auto;
    bottom: 0%;
    left: 0%;
    right: auto;
  }

  &.feature-grid-v2-image-above---02 {
    max-width: 62%;
    margin-bottom: 13%;
    top: auto;
    bottom: 0%;
    left: auto;
    right: 0%;
  }

  &.half-bg-top {
    min-height: 50%;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
  }

  &.hero-v3-bg {
    background-color: $neutral-100;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    bottom: auto;
    left: 0%;
    min-height: 76%;
    right: 0%;
    top: 0%;
  }

  &.hero-v4-image-above---01 {
    bottom: 0%;
    left: 0%;
    margin-bottom: 3%;
    max-width: 23%;
    right: auto;
    top: auto;
    width: 100%;
  }

  &.hero-v4-image-above---02 {
    bottom: auto;
    left: auto;
    margin-top: 3%;
    max-width: 23%;
    right: 0%;
    top: 0%;
    width: 100%;
  }

  &.hero-v5 {
    min-height: 80%;
  }

  &.hero-v5 {
    min-height: 80%;
  }

  &.hero-v12,
  &.hero-v13,
  &.contact-v2 {
    min-height: 38%;
  }

  &.not-found {
    background-image: linear-gradient(rgba($secondary-1, 0), $secondary-1);
    height: 10%;
  }

  &.pricing-fade {
    height: 70%;
  }

  &.protected-page {
    background-image: linear-gradient(rgba($secondary-1, 0), $secondary-1);
    height: 25%;
  }

  &.sign-up {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-left: 84px;
  }

  &.sign-up-image-above {
    bottom: 0%;
    left: 0%;
    margin-bottom: 38.5%;
    max-width: 46%;
    right: auto;
    top: auto;
    z-index: 1;
  }

  &.card-help-center-bg---bottom {
    background-color: #fff;
    border-radius: 40px;
    bottom: 0%;
    left: 0%;
    min-height: 53%;
    right: 0%;
    top: auto;
  }

  &.help-center-top {
    background-color: #171717;
    bottom: auto;
    left: 0%;
    min-height: 460px;
    right: 0%;
    top: 0%;
  }

  &.team-item-card {
    align-items: center;
    background-color: $accent-1;
    bottom: 0%;
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: center;
    left: 0%;
    overflow: auto;
    padding: 20px;
    right: 0%;
    text-align: center;
    top: 0%;
  }

  &.utility-page-fade {
    height: 30%;
  }

  &.white-fade {
    background-image: radial-gradient(circle, rgba($neutral-100, 0), $neutral-100 90%);
    bottom: 0%;
    left: 0%;
    right: 0%;
    top: 0%;
  }

  &.accent-fade-top {
    background-image: linear-gradient($accent-1, rgba($accent-1, 0));
    bottom: auto;
    left: 0%;
    min-height: 50%;
    right: 0%;
    top: 0%;
  }

  &.back-shadow {
    bottom: 0%;
    box-shadow: 0 53px 70px rgba(20, 20, 43, 0.14);
    border-radius: 18px;
    left: 0%;
    margin-left: auto;
    margin-right: auto;
    max-width: 66%;
    right: 0%;
    top: 0%;
  }

  &.card-feature-featured-image-above {
    bottom: 0%;
    left: 0%;
    max-width: 48%;
    right: auto;
    top: auto;
  }

  &.card-feature-dark-image-above {
    bottom: 0%;
    left: auto;
    margin-bottom: 13%;
    margin-right: 6%;
    max-width: 40%;
    right: 0%;
    top: auto;
    width: 100%;
  }

  &.card-feature-light-image-above {
    bottom: 0%;
    left: auto;
    max-width: 59%;
    right: 0%;
    top: auto;
  }

  &.cta-grid-wrapper {
    background-attachment: scroll;
    // background-image: url('/images/bg-grid-cta-techkit-template.svg');
    background-position: 50%;
    background-repeat: repeat;
    background-size: auto;
    bottom: 0%;
    left: auto;
    right: 0%;
    top: 0%;
    width: 600px;
  }

  &.cta-grid-fade {
    background-image: linear-gradient(to right, $accent-1, rgba($accent-1, 0));
    bottom: 0%;
    left: 0%;
    right: auto;
    top: 0%;
    width: 130px;
  }

  &.card-newsletter-grid-bg {
    background-color: $accent-1;
    bottom: 0%;
    left: auto;
    margin-right: -100vw;
    right: 0%;
    top: 0%;
    width: 101vw;
  }

  &.card-top-bg---bottom {
    background-color: #fff;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    bottom: 0%;
    left: 0%;
    min-height: 50%;
    right: 0%;
    top: auto;

    &.pricing-bg,
    &.hero-v12,
    &.hero-v13,
    &.contact-v2 {
      min-height: 38%;
    }
  }

  &.card-form-v1-bg {
    background-color: $neutral-200;
    bottom: 0%;
    left: auto;
    margin-right: -100vw;
    right: 0%;
    top: 0%;
    width: 100vw;
  }

  &.card-form-v2-bg {
    background-color: $neutral-200;
    bottom: 0%;
    left: 0%;
    margin-left: -100vw;
    right: auto;
    top: 0%;
    width: 100vw;
  }

  &.card-form-v3-bg {
    background-color: $neutral-800;
    bottom: 0%;
    left: 0%;
    margin-left: -100vw;
    right: auto;
    top: 0%;
    width: 100vw;
  }

  &.cta-v3-grid-fade---01 {
    background-image: linear-gradient(to right, $accent-1, rgba($accent-1, 0));
    bottom: 0%;
    left: 0%;
    right: auto;
    top: 0%;
    width: 158px;
  }

  &.cta-v3-grid-fade---02 {
    background-image: linear-gradient(270deg, $accent-1, rgba($accent-1, 0));
    bottom: 0%;
    left: auto;
    right: 0%;
    top: 0%;
    width: 158px;
  }

  &.hero-v3-bg {
    background-color: $neutral-100;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    bottom: auto;
    left: 0%;
    min-height: 76%;
    right: 0%;
    top: 0%;
  }

  &.hero-v4-image-above---01 {
    bottom: 0%;
    left: 0%;
    margin-bottom: 3%;
    max-width: 23%;
    right: auto;
    top: auto;
    width: 100%;
  }

  &.hero-v4-image-above---02 {
    bottom: auto;
    left: auto;
    margin-top: 3%;
    max-width: 23%;
    right: 0%;
    top: 0%;
    width: 100%;
  }

  &.hero-v5 {
    min-height: 80%;
  }

  &.hero-v12,
  &.hero-v13,
  &.contact-v2 {
    min-height: 38%;
  }

  &.not-found {
    background-image: linear-gradient(rgba($secondary-1, 0), $secondary-1);
    height: 10%;
  }

  &.pricing-fade {
    height: 70%;
  }

  &.protected-page {
    background-image: linear-gradient(rgba($secondary-1, 0), $secondary-1);
    height: 25%;
  }

  &.sign-up {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    padding-left: 84px;
  }

  &.sign-up-image-above {
    bottom: 0%;
    left: 0%;
    margin-bottom: 38.5%;
    max-width: 46%;
    right: auto;
    top: auto;
    z-index: 1;
  }

  &.team-item-card {
    align-items: center;
    background-color: $accent-1;
    bottom: 0%;
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: center;
    left: 0%;
    overflow: auto;
    padding: 20px;
    right: 0%;
    text-align: center;
    top: 0%;
  }

  &.utility-page-fade {
    height: 30%;
  }

  &.white-fade {
    background-image: radial-gradient(circle, rgba($neutral-100, 0), $neutral-100 90%);
    bottom: 0%;
    left: 0%;
    right: 0%;
    top: 0%;
  }
}

// Shadows
.shadow-01 {
  box-shadow: 0 2px 6px rgba($secondary-1, 0.06);
}

.shadow-02 {
  box-shadow: 0 2px 12px rgba($secondary-1, 0.08);
}

.shadow-03 {
  box-shadow: 0 8px 28px rgba($secondary-1, 0.1);
}

.shadow-04 {
  box-shadow: 0 14px 42px rgba($secondary-1, 0.14);
}

.shadow-05 {
  box-shadow: 0 24px 65px rgba($secondary-1, 0.16);
}

.shadow-06 {
  box-shadow: 0 32px 72px rgba($secondary-1, 0.24);
}

.button-shadow-color-01 {
  box-shadow: 0 4px 10px rgba($accent-1, 0.06);
}

.button-shadow-color-02 {
  box-shadow: 0 6px 20px rgba($accent-1, 0.08);
}

.button-shadow-color-03 {
  box-shadow: 0 10px 28px rgba($accent-1, 0.08);
}

.button-shadow-white-01 {
  box-shadow: 0 4px 10px rgba($secondary-1, 0.04);
}

.button-shadow-white-02 {
  box-shadow: 0 6px 20px rgba($secondary-1, 0.06);
}

.button-shadow-white-03 {
  box-shadow: 0 10px 28px rgba($secondary-1, 0.1);
}

// Flex
.flex-vertical {
  display: flex;
  flex-direction: column;
}

.flex-horizontal {
  display: flex;

  &.children-wrap {
    flex-wrap: wrap;

    &.order-summary {
      grid-column-gap: 20px;
    }
  }

  &.align-center {
    align-items: center;
  }

  &.product-page-title {
    flex-wrap: wrap;
    grid-column-gap: 18px;
  }
}

// Misc
.cancel {
  cursor: pointer;
}

.top-align {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
}

.bullet-list {
  margin-left: 16px;
}

.equal-height-row {
  display: flex;
  flex-wrap: wrap;
}

.equal-height-col {
  display: flex;
  flex-direction: column;
}

.equal-height-card {
  flex: 1;
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.width-100 {
  width: 100%;
}

.opacity-0 {
  opacity: 0;
}

.opacity-40 {
  opacity: .4;
}

.overflow-hidden {
  overflow: hidden;

  &.white-bg {
    min-height: 100%;
    background-color: #fff;
  }
}