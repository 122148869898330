// _mixins.scss

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin transition($properties: all, $duration: 0.3s, $timing: ease) {
  transition: $properties $duration $timing;
}

@mixin card($background: $color-neutral-100, $border-radius: 18px, $box-shadow: 0 2px 7px rgba(20, 20, 43, 0.06)) {
  background-color: $background;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  overflow: hidden;
}

@mixin input-style($background: $color-neutral-200, $border: 1px solid transparent, $color: $color-neutral-800) {
  background-color: $background;
  border: $border;
  color: $color;
  border-radius: 8px;
  padding: 24px;
  font-size: 18px;
  transition: color 0.3s, border-color 0.3s;

  &:hover {
    border-color: $color-neutral-400;
  }

  &:focus {
    color: $color-neutral-800;
    border-color: $color-neutral-400;
  }

  &::placeholder {
    color: $color-neutral-600;
  }
}