@import 'variables';

.line-rounded-icon {
  font-family: 'Line Rounded Icons', sans-serif;

  &.column-contact-link-icon-left {
    color: $neutral-100;
    display: inline-block;
    font-size: 22px;
    line-height: 1em;
  }

  &.dropdown-arrow {
    margin-left: 8px;
  }

  &.feature-icon {
    align-items: center;
    color: $accent-1;
    display: flex;
    font-size: 16px;
    height: 26px;
    justify-content: center;
    margin-right: 4px;
    margin-top: -5px;
    min-height: 26px;
    min-width: 26px;
    width: 26px;
  }

  &.link-icon-left {
    display: inline-block;
    margin-right: 6px;
  }

  &.link-icon-right {
    display: inline-block;
    margin-left: 6px;
  }

  &.style---dropdown-arrow-sidebar {
    line-height: 18px;
  }

  &.success-message-aside-icon {
    color: $accent-1;
    font-size: 24px;

    &.color-neutral-100 {
      color: $neutral-100;
    }
  }

  &.success-message-check {
    color: $accent-1;
    font-size: 22px;
    margin-bottom: 6px;

    &.large {
      font-size: 80px;
      line-height: 88px;
      margin-bottom: 16px;
    }
  }
}

.line-square-icon {
  font-family: 'Line Square Icons', sans-serif;
}

.filled-icons {
  font-family: 'Filled Icons', sans-serif;
}

.divider {
  background-color: $color-neutral-300;
  height: 1px;
  margin-bottom: 80px;
  margin-top: 80px;

  &._0px {
    background-color: $color-neutral-300;
    margin-bottom: 0;
    margin-top: 0;
  }

  &._24px {
    background-color: $color-neutral-700;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  &._40px {
    margin-bottom: 40px;
    margin-top: 40px;
  }

  &._48px {
    margin-bottom: 48px;
    margin-top: 48px;
  }

  &._64px {
    margin-bottom: 64px;
    margin-top: 64px;

    &.template-pages-start-here {
      margin-bottom: 40px;
    }
  }

  &.bg-neutral-700,
  &.contact-links-v2-divider {
    background-color: $color-neutral-700;
  }

  &.card-product {
    background-color: $color-neutral-400;
    margin-bottom: 32px;
    margin-top: 32px;

    &.bg-neutral-700 {
      background-color: $color-neutral-700;
    }
  }
}

.blue-circle {
  background-color: $accent-1;
  border-radius: 50%;
  color: $neutral-100;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  height: 30px;
  line-height: 32px;
  margin-right: 8px;
  min-height: 30px;
  min-width: 30px;
  text-align: center;
  width: 30px;
}

.circle-image-container {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 50px;
  justify-content: center;
  overflow: hidden;
  width: 50px;
}

.marquee {
  height: 400px;
  overflow-x: hidden;
  position: relative;
  width: 100%;

  .track {
    animation: marquee 15s linear infinite;
    position: absolute;
    white-space: nowrap;
    width: 180%;
    will-change: transform;

    &:hover {
      animation-play-state: paused;
    }
  }

  @keyframes marquee {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-50%);
    }
  }
}

.marquee-text {
  color: $red-400;
  font-size: 29px;
  font-weight: 600;
  margin: 60px 0;
}

span.text-red {
  -webkit-text-stroke: 1px $red-400;
  margin-left: 6px;
}

.divider-details {
  background-color: $neutral-500;
  flex: 1;
  height: 1px;
  max-width: 30px;
  min-width: 15px;
  width: 100%;

  &._20px {
    max-width: 20px;
    min-width: 10px;
  }

  &.bg-neutral-100 {
    background-color: $neutral-100;
  }

  &.bg-neutral-300 {
    background-color: $neutral-300;
  }

  &.bg-neutral-700 {
    background-color: $neutral-700;
  }

  &.bold {
    height: 2px;
  }
}