@import '/styles/variables';
@import '/styles/mixins';

.btn-primary {
  background-color: $color-primary;
  border-radius: 8px;
  border: 1px solid $color-primary;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  justify-content: center;
  line-height: 0;
  padding: 34px 38px;
  text-align: center;
  text-decoration: none;
  transform-style: preserve-3d;
  transition: border-color .3s, background-color .3s, transform .3s, color .3s;

  &:hover {
    color: #fff;
    background-color: $color-primary;
    border-color: $color-primary;
    transform: translate3d(0, -3px, 0.01px);
  }

  &.small {
    padding: 18px 24px;
    font-size: 16px;
    line-height: 1.125em;

    &.header-btn-hidde-on-tablet {
      margin-left: 24px;
    }

    &.discount {
      height: auto;
      margin-left: 0;
    }

    &.pricing-table-btn {
      width: 100%;
    }
  }

  &.large {
    padding: 28px 56px;
    font-size: 20px;
    line-height: 1.1em;
  }

  &.white {
    color: $color-primary;
    background-color: #fff;
    border-color: #fff;

    &:hover {
      color: #fff;
      background-color: $color-neutral-800;
      border-color: $color-neutral-800;
    }

    &.help-center-category-btn {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &.inside-input {
    transform-style: preserve-3d;
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: auto;
    right: 0%;

    &:hover {
      transform: scale3d(0.98, 0.98, 1.01);
    }

    &.default-with-arrow {
      padding: 0 24px 0 0;
      font-size: 16px;
      line-height: 1.125em;
      top: 7px;
      bottom: 7px;
      right: 7px;
    }

    &.default {
      padding: 18px 24px;
      font-size: 16px;
      line-height: 1.125em;
      top: 7px;
      bottom: 7px;
      right: 7px;
    }
  }

  &.download-app {
    display: flex;
  }

  &.form-btn-arrow {
    align-items: center;
    padding: 0;
    display: flex;
  }

  &.connect-integration-btn {
    flex-wrap: wrap;
    align-items: center;
    display: flex;
  }

  &.btn-charcoal {
    background-color: #28292B;
    border-color: #28292B;
  }

  &.btn-charcoal-light {
    background-color: #424242;
    border-color: #424242;
  }

  &:disabled {
    background-color: $color-neutral-400;
    border-color: $color-neutral-400;
    color: $color-neutral-700;
    cursor: not-allowed;
    pointer-events: none;
    transform: none;
    opacity: 0.7;

    &:hover {
      transform: none;
      background-color: $color-neutral-400;
      border-color: $color-neutral-400;
    }
  }
}

.btn-secondary {
  color: $color-primary;
  text-align: center;
  transform-style: preserve-3d;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid $color-primary;
  border-radius: 8px;
  padding: 26px 38px;
  font-size: 18px;
  line-height: 1.111em;
  transition: border-color .3s, transform .3s, background-color .3s, color .3s;

  &:hover {
    color: #fff;
    background-color: $color-primary;
    border-color: $color-primary;
    transform: translate3d(0, -3px, 0.01px);
  }

  &.small {
    padding: 18px 24px;
    font-size: 16px;
    line-height: 1.125em;
  }

  &.large {
    padding: 28px 56px;
    font-size: 20px;
    line-height: 1.1em;
  }

  &.white {
    color: #fff;
    border-color: #fff;

    &:hover {
      color: $color-secondary-1;
      background-color: #fff;
      border-color: #fff;
    }
  }

  &.help-center-category-btn {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 700;
  }
}

.btn-square-primary {
  width: 64px;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  color: #fff;
  transform-style: preserve-3d;
  background-color: $color-primary;
  border-radius: 18px;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-decoration: none;
  transition: transform .3s, background-color .3s, color .3s;
  display: flex;
  overflow: hidden;

  &:hover {
    color: #fff;
    background-color: $color-primary;
    transform: scale3d(0.98, 0.98, 1.01);
  }

  &.small {
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
    border-radius: 8px;
    font-size: 24px;
  }

  &.large {
    width: 80px;
    height: 80px;
    min-height: 80px;
    min-width: 80px;
    font-size: 28px;
  }

  &.white {
    color: $color-primary;
    background-color: #fff;

    &:hover {
      color: #fff;
      background-color: $color-neutral-800;
    }
  }

  &.slider-arrow {
    margin-top: 0;
    margin-bottom: 0;

    &.right---top-right {
      transform-style: preserve-3d;
      top: -160px;
    }

    &.right---bottom-center {
      transform-style: preserve-3d;
      top: auto;
      bottom: 0%;
      left: 0%;
      right: 0%;
      transform: translate(65%);

      &:hover {
        transform: scale3d(0.98, 0.98, 1.01) translate(65%);
      }
    }
  }

  &.pagination-btn {
    border-color: $color-primary;
    margin-top: 80px;
  }
}

.btn-square-secondary {
  width: 64px;
  height: 64px;
  min-height: 64px;
  min-width: 64px;
  box-shadow: none;
  color: $color-primary;
  transform-style: preserve-3d;
  background-color: rgba(0, 0, 0, 0);
  border: 1.5px solid $color-primary;
  border-radius: 18px;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  text-decoration: none;
  transition: border-color .3s, transform .3s, background-color .3s, color .3s;
  display: flex;
  overflow: hidden;

  &:hover {
    color: #fff;
    background-color: $color-primary;
    border-color: $color-primary;
    transform: scale3d(0.98, 0.98, 1.01);
  }

  &.small {
    width: 50px;
    height: 50px;
    min-height: 50px;
    min-width: 50px;
    border-radius: 8px;
    font-size: 20px;

    &.accordion-btn {
      transition-property: none;

      &:hover {
        color: $color-primary;
        background-color: rgba(0, 0, 0, 0);
      }
    }

    &.card-open-position-arrow {
      transition-property: none;

      &:hover {
        color: $color-primary;
        background-color: rgba(0, 0, 0, 0);
        transform: none;
      }
    }
  }

  &.large {
    width: 80px;
    height: 80px;
    min-height: 80px;
    min-width: 80px;
    font-size: 28px;
  }

  &.white {
    box-shadow: none;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
    border-color: #fff;

    &:hover {
      color: $color-secondary-1;
      background-color: #fff;
      border-color: #fff;
    }

    &.pagination-btn {
      margin-top: 80px;
    }
  }

  &.slider-arrow {
    margin-top: 0;
    margin-bottom: 0;

    &.left---bottom-center {
      transform-style: preserve-3d;
      top: auto;
      bottom: 0%;
      left: 0%;
      right: 0%;
      transform: translate(-65%);

      &:hover {
        transform: scale3d(0.98, 0.98, 1.01) translate(-65%);
      }
    }

    &.left---top-right {
      transform-style: preserve-3d;
      top: -160px;
      bottom: auto;
      left: auto;
      right: 104px;
    }
  }

  &.accordion-btn {
    position: relative;
  }
}

.btn-container {
  width: 400px;
  margin: auto;
}

.btn {
  width: 100%;
  max-width: 400px;
  padding: 10px 12px;
  border-radius: 15px;
  border: none;
  font-size: 20px;
  margin-top: 10px;
  margin-top: 40px;
  text-transform: uppercase;
  background-color: $red-400;
  color: #fff;
  cursor: pointer;
  transform: scale(1, 1);
  transition: transform 0.5s ease;

  &:hover {
    transform: scale(1.1, 1.1);
  }
}

.buttons {
  .add-to-cart {
    padding: 10px 20px;
    border: 1px solid $red-400;
    margin-top: 40px;
    font-size: 18px;
    font-weight: 500;
    background-color: white;
    color: $red-400;
    cursor: pointer;
    width: 200px;
    transform: scale(1, 1);
    transition: transform 0.5s ease;

    &:hover {
      transform: scale(1.1, 1.1);
    }
  }

  .buy-now {
    width: 200px;
    padding: 10px 20px;
    background-color: $red-400;
    color: white;
    border: none;
    margin-top: 40px;
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    transform: scale(1, 1);
    transition: transform 0.5s ease;

    &:hover {
      transform: scale(1.1, 1.1);
    }
  }
}

.buttons-row {
  grid-column-gap: 24px;
  grid-row-gap: 20px;
  align-items: center;
  display: flex;

  &.center {
    justify-content: center;
  }
}

.video-btn {
  z-index: 1;
  width: 140px;
  height: 140px;
  min-height: 140px;
  min-width: 140px;
  background-color: $color-primary;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.video-btn-icon {
  width: 25%;
  transform: translate(6%);
}

.processingButton {
  align-items: center;
  background-color: $accent-1;
  color: $neutral-100;
  cursor: pointer;
  font-size: 16px;
  height: 66px;
  justify-content: center;
  padding: 24px;
  position: relative;
  transition: all 0.3s ease;

  &.sm-button {
    height: 48px;
    padding: 16px 24px;
  }

  &:hover {
    background-color: darken($accent-1, 10%);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  .buttonIcon {
    margin-right: 8px;
  }

  .spinner {
    animation: spin 1s linear infinite;
    margin-right: 8px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}